import { select, Selection } from 'd3-selection'
import {
   scaleLinear,
   ScaleLinear,
   scaleOrdinal,
   scaleTime,
   ScaleTime,
} from 'd3-scale'
import {
   area as d3Area,
   curveMonotoneX,
   line as d3Line,
   symbol,
   symbolSquare,
} from 'd3-shape'
import { timeParse } from 'd3-time-format'
import { extent as d3Extent, max as d3Max } from 'd3-array'
import { axisBottom, axisLeft } from 'd3-axis'
import React, { useCallback, useEffect, useState } from 'react'
import './multiline.css'
import { FillLevel, POICategory } from '../../../../../util/types/types'
import { getFillColor, getPlaceColor } from '../../../../../util/Looks/colors'
import { legendColor } from 'd3-svg-legend'
import { useAsync } from 'react-async'

export interface MultiLineDataFormat {
   date: string
   formattedDate: Date
   value: number
   full_kegs: number
   empty_kegs: number
}

export type MultilineData = Promise<MultiLineDataFormat[]>

interface Props {
   width?: number
   height: number
   category: POICategory
   data: MultilineData
   fill?: boolean
   inline?: boolean
   axis?: boolean
}

const MultiFull = ({ width, height, category, data }: Props): JSX.Element => {
   const { data: resolvedData, error, isPending } = useAsync({ promise: data })
   const [rect, setRect] = useState(new DOMRect())
   const [svg, setSvg] = useState<Selection<SVGSVGElement, any, any, any>>()
   const [cwidth, setCwidth] = useState(width)

   const spkRef = useCallback((node) => {
      if (node !== null) {
         const rect = node.getBoundingClientRect()
         setRect(rect)
         setCwidth(rect.width)
         setSvg(select(node).append('svg').attr('class', 'multiline'))
      }
   }, [])

   useEffect(() => {
      const addLegend = (leftMargin: number) => {
         const legendValues = scaleOrdinal()
            .domain(['Total', 'Full', 'Empty'])
            .range([
               getPlaceColor(category),
               getFillColor(FillLevel.Full),
               getFillColor(FillLevel.Empty),
            ])

         if (svg === undefined) {
            return
         }

         svg.append('g')
            .attr('class', 'legend')
            .attr('transform', `translate(${leftMargin}, 20)`)

         const legend = legendColor()
            //@ts-ignore
            .shape('path', symbol().type(symbolSquare).size(150)())
            .shapePadding(5)
            .scale(legendValues)

         svg.select('.legend')
            // @ts-ignore
            .call(legend)
      }

      const addSerie = (
         name: string,
         xScale: ScaleTime<number, number>,
         yScale: ScaleLinear<number, number>,
         yValueGetter: (d: MultiLineDataFormat) => number,
         data: MultiLineDataFormat[],
         margin: { top: number; left: number; bottom: number; right: number },
         color: string,
         isArea: boolean
      ) => {
         if (svg === undefined) {
            return
         }

         const line = d3Line<MultiLineDataFormat>()
            .x((d) => xScale(d.formattedDate))
            .y((d) => yScale(yValueGetter(d)))
            .curve(curveMonotoneX)

         if (isArea) {
            const area = d3Area<MultiLineDataFormat>()
               .x((d) => xScale(d.formattedDate))
               .y0(yScale(0))
               .y1((d) => yScale(yValueGetter(d)))
               .curve(curveMonotoneX)

            svg.append('path')
               .datum(data)
               .attr('stroke', 'none')
               .attr('fill', color)
               .attr('fill-opacity', '0.1')
               .attr('d', area)
         }

         svg.append('path')
            .datum(data)
            .attr('class', 'line')
            .attr('stroke', color)
            .attr('fill', 'none')
            .attr('stroke-width', '2px')
            .attr('d', line)

         // Add the scatterplot
         svg.selectAll('mydotsall')
            .data(data)
            .enter()
            .append('circle')
            .attr('r', 1.2)
            .attr('cx', (d) => xScale(d.formattedDate))
            .attr('cy', (d) => yScale(yValueGetter(d)))
            .attr('stroke', color)
            .attr('opacity', 0.5)
      }

      if (
         svg == null ||
         cwidth === undefined ||
         error !== undefined ||
         isPending ||
         resolvedData === undefined
      ) {
         return
      }
      const margin = { top: 20, right: 100, bottom: 30, left: 25 }

      // Define date parser
      const parseDate = timeParse('%Y-%m-%dT%H:%M:%SZ')

      // Define scales
      const xScale = scaleTime().range([margin.left, cwidth - margin.right])
      const yScale = scaleLinear().range([height - margin.bottom, margin.top])

      svg.style('position', 'relative')
         .style('top', 0)
         .style('left', 0)
         .attr('width', cwidth)
         .attr('height', height)
         .style('pointer-events', 'none')

      let max = d3Max(resolvedData, (d) => +d.value)

      resolvedData.forEach((d) => {
         const p = parseDate(d.date)
         d.formattedDate = p === null ? new Date() : p
      })

      if (max === undefined) {
         max = 0
      }

      const extent = d3Extent(resolvedData, (d) => d.formattedDate)
      if (extent[0] === undefined) {
         return
      }
      xScale.domain(extent)
      yScale.domain([0, max])

      const numTicks = Math.min(5, max)

      svg.append('g')
         .attr('transform', `translate(${margin.left}, 0)`)
         .call(axisLeft(yScale).tickArguments([numTicks, 'd']))

      svg.append('g')
         .attr('transform', `translate(0, ${height - margin.bottom})`)
         .call(axisBottom(xScale))

      addLegend(cwidth - margin.right + 20)

      addSerie(
         'all',
         xScale,
         yScale,
         (d: MultiLineDataFormat) => d.value,
         resolvedData,
         margin,
         getPlaceColor(category),
         true
      )
      addSerie(
         'full',
         xScale,
         yScale,
         (d: MultiLineDataFormat) => d.full_kegs,
         resolvedData,
         margin,
         getFillColor(FillLevel.Full),
         false
      )
      addSerie(
         'empty',
         xScale,
         yScale,
         (d: MultiLineDataFormat) => d.empty_kegs,
         resolvedData,
         margin,
         getFillColor(FillLevel.Empty),
         false
      )

      return () => {
         if (svg !== undefined) {
            svg.selectAll('g').remove()
            svg.selectAll('path').remove()
            svg.selectAll('circle').remove()
         }
      }
   }, [
      rect,
      spkRef,
      svg,
      cwidth,
      height,
      category,
      data,
      error,
      isPending,
      resolvedData,
   ])

   const styles = {
      height: height + 'px',
      width: '100%',
      display: 'block',
   }

   return <div style={styles} ref={spkRef} className="multiline" />
}

export default MultiFull
