import React from 'react'
import { ShortTypeCard } from '../../components/cards'
import { POICategory } from '../../../util/types/types'
import { InventoryService } from '../../../data/service'
import { InventoryTopService } from '../../../data/service/inventory/InventoryService'
import { ShortTypeContent } from '../../components/cards/ShortTypeCard/ShortTypeContent'
import { DateTime } from 'luxon'

interface Props {
   category: POICategory
   endDate: DateTime
   period: number
}

const InventoryShortTypeCard = ({
   category,
   endDate,
   period,
}: Props): JSX.Element => {
   const data = InventoryService({
      category: category,
      endDate: endDate,
      period: period,
   })
   const dataTop = InventoryTopService({
      category: category,
      endDate: endDate,
      topCount: 5,
   })

   return (
      <ShortTypeCard title={category} type={category}>
         <ShortTypeContent
            type={category}
            data={data}
            listData={dataTop}
            endDate={endDate}
            period={period}
            unit={''}
            height={20}
         />
      </ShortTypeCard>
   )
}

export default InventoryShortTypeCard
