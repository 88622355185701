import React, { useEffect } from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import { TablePaginationActions } from '../../components/table/Pagination'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
   FillLevel,
   getFillLevelName,
   getNextOrder,
} from '../../../util/types/types'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { AssetStatusFilterComponent } from './AssetStatusFilter'
import { Set } from 'immutable'
import { AssetReferenceFilterComponent } from './AssetReferenceFilter'
import Grid from '@material-ui/core/Grid'
import { ShortDate } from '../../../components/ShortDate/ShortDate'
import {
   POIAssetFilter,
   POIAssetList,
   POIAssetListItem,
   POIAssetListServiceParams,
   POIAssetOrder,
} from '../../../data/service/pois/POIAssetListService'
import { TimePeriod } from '../../../data/service/util'

const useStyles = makeStyles({
   table: {
      minWidth: 500,
   },
})

interface Props {
   poiId: number
   timePeriod?: TimePeriod
   dataService: (
      p: POIAssetListServiceParams
   ) => Promise<POIAssetList | undefined>
}

export const AssetListComponent = ({
   poiId,
   dataService,
   timePeriod,
}: Props): JSX.Element => {
   const classes = useStyles()
   const [page, setPage] = React.useState(0)
   const [rowsPerPage, setRowsPerPage] = React.useState(10)
   const [rows, setRows] = React.useState([] as POIAssetListItem[])
   const [count, setCount] = React.useState(0)
   const [listOrder, setListOrder] = React.useState<POIAssetOrder>({
      arrivalDate: undefined,
      reference: undefined,
   })
   const [listFilter, setListFilter] = React.useState<POIAssetFilter>({
      reference: undefined,
      period: timePeriod,
      status: Set<FillLevel>(),
   })

   useEffect(() => {
      setListFilter({
         status: listFilter.status,
         reference: listFilter.reference,
         period: timePeriod,
      })
   }, [timePeriod])

   useEffect(() => {
      const params: POIAssetListServiceParams = {
         locationId: poiId,
         filter: listFilter,
         pag: { page, rowsPerPage },
         order: listOrder,
      }
      dataService(params).then((res) => {
         if (res === undefined) return
         setCount(res.count)
         setRows(res.items)
      })
   }, [poiId, dataService, page, rowsPerPage, listFilter, listOrder])

   const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
   ) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   ) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
   }

   const handleOrderByNameClick = () => {
      setListOrder((o) => {
         return {
            arrivalDate: undefined,
            reference: getNextOrder(o.reference),
         }
      })
   }

   const handleOrderByArrivalClick = () => {
      setListOrder((o) => {
         return {
            reference: undefined,
            arrivalDate: getNextOrder(o.arrivalDate),
         }
      })
   }

   const handleFilterStatusChange = (v: Set<FillLevel>) => {
      setPage(0)
      setListFilter((f) => {
         return {
            status: v,
            period: timePeriod,
            reference: f.reference,
         }
      })
   }

   const handleFilterReferenceChange = (v: string) => {
      setPage(0)
      setListFilter((f) => {
         return {
            status: f.status,
            period: timePeriod,
            reference: v,
         }
      })
   }

   return (
      <Grid container spacing={1}>
         <Grid item xs={12}>
            <Grid
               container
               direction="row"
               justify="space-between"
               alignItems="center"
            >
               <Grid item>
                  Filter:{' '}
                  <AssetStatusFilterComponent
                     values={listFilter.status}
                     filterChangeHandler={handleFilterStatusChange}
                  />
               </Grid>
               <Grid item>
                  <AssetReferenceFilterComponent
                     value={listFilter.reference}
                     filterChangeHandler={handleFilterReferenceChange}
                  />
               </Grid>
            </Grid>
         </Grid>
         <Grid item xs={12}>
            <TableContainer component={Paper}>
               <Table
                  className={classes.table}
                  aria-label="custom pagination table"
               >
                  <TableHead>
                     <TableRow>
                        <TableCell
                           sortDirection={
                              listOrder.reference !== undefined
                                 ? listOrder.reference
                                 : false
                           }
                        >
                           <TableSortLabel
                              active={listOrder.reference !== undefined}
                              direction={listOrder.reference}
                              onClick={handleOrderByNameClick}
                           >
                              Name
                           </TableSortLabel>
                        </TableCell>
                        <TableCell
                           align="right"
                           sortDirection={
                              listOrder.arrivalDate !== undefined
                                 ? listOrder.arrivalDate
                                 : false
                           }
                        >
                           <TableSortLabel
                              active={listOrder.arrivalDate !== undefined}
                              direction={listOrder.arrivalDate}
                              onClick={handleOrderByArrivalClick}
                           >
                              Arrival
                           </TableSortLabel>
                        </TableCell>
                        <TableCell
                           align="right"
                           sortDirection={
                              listOrder.arrivalDate !== undefined
                                 ? listOrder.arrivalDate
                                 : false
                           }
                        >
                           <TableSortLabel
                              active={listOrder.arrivalDate !== undefined}
                              direction={listOrder.arrivalDate}
                              onClick={handleOrderByArrivalClick}
                           >
                              Departure
                           </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">Status</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rows.map((row) => (
                        <TableRow key={row.id + row.arrivalDate.toISODate()}>
                           <TableCell component="th" scope="row">
                              <Link
                                 component={RouterLink}
                                 to={'/keg/' + row.id}
                              >
                                 {row.reference}
                              </Link>
                           </TableCell>
                           <TableCell align="right">
                              <ShortDate date={row.arrivalDate} />
                           </TableCell>
                           <TableCell align="right">
                              {row.departureDate !== undefined ? (
                                 <ShortDate date={row.departureDate} />
                              ) : (
                                 ' '
                              )}
                           </TableCell>
                           <TableCell align="right">
                              {getFillLevelName(row.status)}
                           </TableCell>
                        </TableRow>
                     ))}
                     {/*emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )*/}
                  </TableBody>
                  <TableFooter>
                     {
                        <TableRow>
                           <TablePagination
                              rowsPerPageOptions={[10, 50, 100]}
                              colSpan={3}
                              rowsPerPage={rowsPerPage}
                              count={count}
                              page={page}
                              SelectProps={{
                                 inputProps: { 'aria-label': 'rows per page' },
                                 native: true,
                              }}
                              onPageChange={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                           />
                        </TableRow>
                     }
                  </TableFooter>
               </Table>
            </TableContainer>
         </Grid>
      </Grid>
   )
}
