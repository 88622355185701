import { Set } from 'immutable'
import {
   FillLevel,
   getFillLevelFromString,
   getFillLevelName,
   Order,
} from '../../../util/types/types'
import config from 'react-global-configuration'
import { addPaginationToURL, Pagination } from '../../../util/types/serviceUtil'
import { json } from 'd3-fetch'
import { DateTime } from 'luxon'
import { addPeriodToURL, TimePeriod } from '../util'

export interface POIAssetListItem {
   id: number
   reference: string
   arrivalDate: DateTime
   departureDate: DateTime | undefined
   status: FillLevel
}

export interface POIAssetList {
   count: number
   items: POIAssetListItem[]
}

export interface POIAssetFilter {
   reference?: string
   period?: TimePeriod
   status: Set<FillLevel>
}

export interface POIAssetOrder {
   reference: Order
   arrivalDate: Order
}

export interface POIAssetListServiceParams {
   locationId: number
   pag: Pagination
   filter: POIAssetFilter
   order: POIAssetOrder
}

interface POIAssetListReplyItem {
   id: number
   name: string
   arrival_date: string
   departure_date: string | null
   status: string
}

const mapReply = (i: POIAssetListReplyItem): POIAssetListItem => {
   const departure =
      i.departure_date === null ? undefined : DateTime.fromISO(i.departure_date)
   return {
      id: i.id,
      reference: i.name,
      arrivalDate: DateTime.fromISO(i.arrival_date),
      departureDate: departure,
      status: getFillLevelFromString(i.status),
   }
}

export const POIHistoricalAssetListService = ({
   locationId,
   pag,
   filter,
   order,
}: POIAssetListServiceParams): Promise<POIAssetList | undefined> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/poi/' + locationId + '/assets_history/'

   if (filter.reference !== undefined)
      api_url.searchParams.append('reference_filter', filter.reference)
   if (filter.period !== undefined) addPeriodToURL(api_url, filter.period)
   if (filter.status.count() === 1)
      api_url.searchParams.append(
         'status_filter',
         getFillLevelName(filter.status.toArray()[0])
      )

   if (order.reference !== undefined)
      api_url.searchParams.append('sort_reference', order.reference)
   if (order.arrivalDate !== undefined)
      api_url.searchParams.append('sort_arrival_date', order.arrivalDate)

   addPaginationToURL(api_url, pag)

   return json<POIAssetList>(api_url.href, { credentials: 'include' }).then(
      (res: any) => {
         return {
            count: res.count,
            items: res.items.map(mapReply),
         }
      }
   )
}
