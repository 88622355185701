import React, { useContext, useEffect, useState } from 'react'
import { Link as LinkRouter, useRouteMatch } from 'react-router-dom'
import createStyles from '@material-ui/core/styles/createStyles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
   EmptyMapDetailResult,
   KegDetailFilter,
   KegDetailService,
   KegLatestData,
   KegLatestDataService,
   KegList,
   KegListItem,
   KegLocationHistoryFilter,
   KegLocationHistoryItem,
   KegLocationHistoryListService,
   KegMapDetailService,
   KegRotationSummary,
   KegRotationSummaryService,
   KegTemperaturesService,
   KegUpdateService,
   MapDetailResult,
} from '../../data/service/kegs/KegsService'
import {
   LocationLink,
   Page,
   PageContent,
   PageHeader,
   Section,
   SectionContent,
   SectionTitle,
} from '../../components'
import { getFillLevelName } from '../../util/types/types'
import { OnalyticsDynamicMap } from '../components/charts/map/OnalyticsDynamicMap'
import TablePagination from '@material-ui/core/TablePagination'
import { TablePaginationActions } from '../components/table/Pagination'
import TableFooter from '@material-ui/core/TableFooter'
import { Pagination } from '../../util/types/serviceUtil'
import TimeSeriesLine from '../components/charts/line/TimeSeriesLine'
import {
   DateInterval,
   DateIntervalFilter,
} from '../../components/Filter/Date/DateIntervalFilter'
import Alert from '@material-ui/lab/Alert'
import { Theme } from '@material-ui/core/styles/createTheme'
import { LatestDataComponent } from './LatestData/LatestData'
import { useIntl } from 'react-intl'
import {
   getTemperatureZero,
   numConverterFromCelsius,
} from '../../util/i18n/units'
import config from 'react-global-configuration'
import {
   TimeSeriesBandDataPoint,
   TimeSeriesData,
} from '../components/charts/line/TimeSeriesLine/TimeSeriesLine'
import { getPlaceColor } from '../../util/Looks/colors'
import EditIcon from '@material-ui/icons/Edit'
import { KegEdit, KegEditData } from './KegDetail/KegEdit'
import { AuthFuncContext } from '../../App'
import { handleRequestErrors } from '../../util/auth/authHelper'
import { DateTime } from 'luxon'
import { daysAgo } from '../../util/DateTimeUtils/DateTimeUtils'

type TParams = { kegId: string }

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      table: {
         minWidth: 650,
      },
      alert: {
         marginBottom: theme.spacing(3),
      },
   })
)

export const KegDetail = (): JSX.Element => {
   const intl = useIntl()
   const match = useRouteMatch<TParams>()
   const [kegLocationList, setKegLocationList] = useState(
      [] as KegLocationHistoryItem[]
   )
   const [kegDetail, setKegDetail] = useState<KegListItem>()
   const [latestData, setLatestData] = useState<KegLatestData>()
   const [kegMapData, setKegMapData] = useState<Promise<MapDetailResult>>()
   const [kegTemperatureData, setKegTemperatureData] =
      useState<Promise<TimeSeriesData>>()
   const [rotationSummary, setRotationSummary] = useState<
      KegRotationSummary | undefined
   >(undefined)
   const [page, setPage] = React.useState(0)
   const [rowsPerPage, setRowsPerPage] = React.useState(10)
   const [count, setCount] = React.useState(0)
   const [noData, setNoData] = React.useState(false)
   const [editing, setEditing] = useState(false)
   const authContext = useContext(AuthFuncContext)

   const [interval, setInterval] = useState<DateInterval>({
      minDate: DateTime.now().plus({ month: -1 }),
      maxDate: DateTime.now(),
   })

   const [validInterval, setValidInterval] = useState<DateInterval>({
      minDate: DateTime.now().plus({ month: -1 }),
      maxDate: DateTime.now(),
   })

   const classes = useStyles()

   const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
   ) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   ) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
   }

   const editClick = () => {
      setEditing(true)
   }

   const editOkHandler = (newValues: KegEditData) => {
      KegUpdateService(+match.params.kegId, newValues.reference).then((res) => {
         if (res.status !== 200) {
            return
         }
         const newData = Object.assign({}, kegDetail) ?? kegDetail
         newData.reference = newValues.reference
         setKegDetail(newData)
         setEditing(false)
      })
      return true
   }

   const editCancelHandler = () => {
      setEditing(false)
   }

   useEffect(() => {
      KegLatestDataService(+match.params.kegId).then((k) => {
         const converter = numConverterFromCelsius(intl)
         k.temperature = converter(k.temperature)
         setLatestData(k)
      })

      KegRotationSummaryService(+match.params.kegId).then((k) => {
         setRotationSummary(k)
      })
   }, [match.params.kegId, intl])

   useEffect(() => {
      KegDetailService(+match.params.kegId)
         .then((k) => {
            if (k.firstLocationTimestamp !== undefined) {
               setValidInterval({
                  minDate: k.firstLocationTimestamp,
                  maxDate: interval.maxDate,
               })
            }
            setKegDetail(k)
         })
         .catch(handleRequestErrors(authContext))

      let lEndDate = interval.maxDate

      if (lEndDate !== undefined) {
         lEndDate = lEndDate.plus({ day: 1 })
      }
      const kegFilter: KegDetailFilter = {
         kegId: +match.params.kegId,
         startDate: interval.minDate,
         endDate: lEndDate,
      }

      const locationFilter: KegLocationHistoryFilter = {
         kegId: +match.params.kegId,
         startDate: interval.minDate,
         endDate: lEndDate,
      }

      setKegMapData(
         KegMapDetailService(kegFilter).catch(() => {
            handleRequestErrors(authContext)
            return EmptyMapDetailResult
         })
      )
      const tempPromise = KegTemperaturesService(kegFilter).then((res) => {
         setNoData(res.temperatures.length === 0)
         const converter = numConverterFromCelsius(intl)
         return res.temperatures.map((v) => {
            return { timestamp: v.timestamp, value: converter(v.value) }
         })
      })

      const pag: Pagination = {
         rowsPerPage: rowsPerPage,
         page: page,
      }

      const locationHistory = KegLocationHistoryListService(
         locationFilter,
         pag
      ).then((d: KegList) => {
         const kll = d.kegList.filter(
            (k: KegListItem) =>
               k.arrivalDate !== undefined &&
               k.locationCoordinates !== undefined
         )

         setCount(d.count)
         // @ts-ignore  the filter solves the problem
         setKegLocationList(kll)
         return kll
      })

      const p = Promise.all([tempPromise, locationHistory])
         .then((v) => {
            const bands = v[1].map((b) => {
               const band: TimeSeriesBandDataPoint = {
                  startTime: b.arrivalDate ?? DateTime.now(),
                  endTime: b.departureDate,
                  color: getPlaceColor(b.locationCategory),
                  label: b.locationName,
               }

               return band
            })

            return { series: v[0], bands: bands }
         })
         .catch(handleRequestErrors(authContext))
         .then((v) => {
            if (v === undefined) {
               return { series: [], bands: [] }
            }
            return v
         })

      setKegTemperatureData(p)
   }, [match.params.kegId, interval, page, rowsPerPage, intl, authContext])

   return (
      <Page>
         <PageHeader>
            <Grid
               container
               justify="flex-start"
               spacing={1}
               alignItems="center"
            >
               <Grid item>
                  <IconButton component={LinkRouter} to="/kegs">
                     {' '}
                     <ArrowBackIcon />
                  </IconButton>
               </Grid>
               <Grid item>
                  <Typography variant="h2">{kegDetail?.reference}</Typography>
               </Grid>
               <Grid item>
                  <IconButton onClick={editClick}>
                     <EditIcon />
                  </IconButton>
               </Grid>
               {kegDetail && (
                  <KegEdit
                     open={editing}
                     kegData={{ reference: kegDetail.reference }}
                     okHandler={editOkHandler}
                     cancelHandler={editCancelHandler}
                  />
               )}
            </Grid>
         </PageHeader>
         <PageContent>
            <Section paperless>
               <SectionTitle>Latest status</SectionTitle>
               {kegTemperatureData && latestData && (
                  <LatestDataComponent
                     ld={latestData}
                     rotationData={rotationSummary}
                  />
               )}
            </Section>
            <Section paperless>
               <SectionContent>
                  {noData && (
                     <Alert severity="warning" className={classes.alert}>
                        There is no data in the defined interval, please select
                        another interval
                     </Alert>
                  )}
                  <DateIntervalFilter
                     interval={interval}
                     validInterval={validInterval}
                     intervalChangeHandler={setInterval}
                     preText={'Locations on the period from'}
                     middleText={'to'}
                  />
                  <div>
                     {kegMapData !== undefined && (
                        <OnalyticsDynamicMap data={kegMapData} height={600} />
                     )}
                  </div>
               </SectionContent>
            </Section>
            <Section>
               <SectionTitle>Temperature evolution</SectionTitle>
               <SectionContent>
                  {kegTemperatureData !== undefined && (
                     <TimeSeriesLine
                        data={kegTemperatureData}
                        height={200}
                        yCrossValue={getTemperatureZero(intl)}
                        lowHighValues={config.get('temperature_limits')}
                     />
                  )}
               </SectionContent>
            </Section>
            <Section paperless>
               <SectionTitle>Location History</SectionTitle>
               <SectionContent>
                  <Table className={classes.table} aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Date Entered</TableCell>
                           <TableCell>Date Exited</TableCell>
                           <TableCell align="right">Location</TableCell>
                           <TableCell align="right">Status</TableCell>
                           <TableCell align="right">Duration</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {kegLocationList.map((row: KegLocationHistoryItem) => (
                           <TableRow key={row.arrivalDate.toISO()}>
                              <TableCell component="th" scope="row">
                                 {row.arrivalDate.toLocaleString(
                                    DateTime.DATETIME_SHORT
                                 )}
                              </TableCell>
                              <TableCell>
                                 {row.departureDate?.toLocaleString(
                                    DateTime.DATETIME_SHORT
                                 )}
                              </TableCell>
                              <TableCell align="right">
                                 {row.locationId !== undefined &&
                                    row.locationName !== undefined &&
                                    row.locationCategory !== undefined && (
                                       <LocationLink
                                          id={row.locationId}
                                          name={row.locationName}
                                          category={row.locationCategory}
                                       />
                                    )}
                              </TableCell>
                              <TableCell align="right">
                                 {row.fillLevel &&
                                    getFillLevelName(row.fillLevel)}
                              </TableCell>
                              <TableCell align="right">
                                 {row.departureDate !== undefined
                                    ? Math.floor(
                                         row.departureDate
                                            .diff(row.arrivalDate, 'days')
                                            .as('days')
                                      )
                                    : daysAgo(row.arrivalDate)}{' '}
                                 days
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                     <TableFooter>
                        <TableRow>
                           <TablePagination
                              rowsPerPageOptions={[10, 50, 100]}
                              colSpan={3}
                              rowsPerPage={rowsPerPage}
                              count={count}
                              page={page}
                              SelectProps={{
                                 inputProps: { 'aria-label': 'rows per page' },
                                 native: true,
                              }}
                              onPageChange={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                           />
                        </TableRow>
                     </TableFooter>
                  </Table>
               </SectionContent>
            </Section>
         </PageContent>
      </Page>
   )
}
