import { ComponentType, useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AuthContext } from '../../App'
import { Main as MainLayout } from '../../layouts'

interface PrivateProps {
   layout?: ComponentType<any>
   path: string
   children?: any
   exact?: boolean
}

const PrivateRouteWithLayout = (props: PrivateProps): JSX.Element => {
   const { layout: Layout = MainLayout, exact, path, children, ...rest } = props

   const authContext = useContext(AuthContext)

   return (
      <Route path={path} {...rest}>
         {authContext.unknownAuth ? (
            <div />
         ) : authContext.isAuthenticated ? (
            <Layout>{children}</Layout>
         ) : (
            <Redirect to={`/login`} />
         )}
      </Route>
   )
}

export const RouteWithLayout = (props: {
   [x: string]: any
   layout: any
   component: any
}): JSX.Element => {
   const { layout: Layout, component: Component, ...rest } = props

   const authContext = useContext(AuthContext)

   return (
      <Route
         {...rest}
         render={(matchProps) =>
            !authContext.isAuthenticated ? (
               <Layout>
                  <Component {...matchProps} />
               </Layout>
            ) : (
               <Redirect to={`/`} />
            )
         }
      />
   )
}

RouteWithLayout.propTypes = {
   component: PropTypes.any.isRequired,
   layout: PropTypes.any.isRequired,
   path: PropTypes.string,
}

export default PrivateRouteWithLayout
