import { ReactNode } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import makeStyles from '@material-ui/core/styles/makeStyles'

type Props = {
   children: ReactNode
}

const useStyles = makeStyles((theme) => ({
   topSpacer: {
      minHeight: theme.spacing(3),
   },
}))

export const PageContent = ({ children }: Props): JSX.Element => {
   const classes = useStyles()
   return (
      <Container>
         <div className={classes.topSpacer} />
         <Grid container spacing={3}>
            {children}
         </Grid>
      </Container>
   )
}
