import React, { useCallback } from 'react'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl, { LngLatLike } from '!mapbox-gl'
import './onalyticsmapd3.css'

interface Props {
   location: LngLatLike
   height: number
   width: number
   description?: string
}

export const SmallMap = ({
   location,
   width,
   height,
   description,
}: Props): JSX.Element => {
   const mapRef = useCallback(
      (node) => {
         if (node !== null) {
            mapboxgl.accessToken =
               'pk.eyJ1IjoibnVub2xvcGVzb25hbHl0aWNzIiwiYSI6ImNrMWtsa2N1ODBjamUzZHBidHl3bGdtZmgifQ._1pol1biXvHg07uyyiFVOA'
            const m = new mapboxgl.Map({
               container: node,
               style: 'mapbox://styles/mapbox/light-v10',
               zoom: 7,
               minZoom: 3,
               maxZoom: 18,
               center: location,
               attributionControl: false,
            })

            m.addControl(new mapboxgl.NavigationControl())

            new mapboxgl.Marker().setLngLat(location).addTo(m)
         }
      },
      [location]
   )

   const styles = { height: height, width: width }
   return (
      <div>
         <div style={styles} ref={mapRef} />
      </div>
   )
}
