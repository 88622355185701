import Typography from '@material-ui/core/Typography'
import { ReactNode } from 'react'

export const SectionTitle = ({
   children,
}: {
   children: ReactNode
}): JSX.Element => {
   return (
      <Typography variant="h3" gutterBottom>
         {children}
      </Typography>
   )
}
