import React, { useContext, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import theme from '../../theme'
import TableHead from '@material-ui/core/TableHead'
import {
   POIListField,
   POIListItem,
   POIListService,
} from '../../data/service/pois/POIListService'
import { POICategory } from '../../util/types/types'
import { POIInventorySparkLine } from './POIInventorySparkLine/POIInventorySparkLine'
import Grid from '@material-ui/core/Grid'

import { Set } from 'immutable'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import { TablePaginationActions } from '../components/table/Pagination'
import {
   Page,
   PageContent,
   Section,
   SectionContent,
   SectionTitle,
} from '../../components'
import {
   MultiChoice,
   MultiChoiceOption,
} from '../../components/Filter/MultiChoice'
import { TextSearch } from '../../components/Filter/TextSearch'
import { InfoTooltip } from '../components/micro/InfoTooltip'
import { LabelOrder, SortLabel } from '../components/table/SortLabel'
import { AuthFuncContext } from '../../App'
import { handleRequestErrors } from '../../util/auth/authHelper'
import { ShortDate } from '../../components/ShortDate/ShortDate'

const useStyles2 = makeStyles({
   root: {
      padding: theme.spacing(4),
      //background: '#F4F6F8',
   },
   table: {
      minWidth: 500,
   },
   filterText: {
      display: 'inline',
      marginRight: theme.spacing(1),
   },
})

const getSettingsPOIListCats = () => {
   const json_cats = localStorage.getItem('poi_list_categories')
   if (json_cats === null) {
      return Set<POICategory>()
   }

   return Set<POICategory>(JSON.parse(json_cats))
}

const setSettingsPOIListCats = (cats: Set<POICategory>) => {
   localStorage.setItem('poi_list_categories', JSON.stringify(cats.toArray()))
}

const poiCategories: MultiChoiceOption<POICategory>[] = [
   {
      label: 'Brewery',
      value: POICategory.Brewery,
   },
   {
      label: 'Distribution Center',
      value: POICategory.DistributionCenter,
   },
   {
      label: 'Wholesaler',
      value: POICategory.Wholesaler,
   },
   {
      label: 'Point of Consumption',
      value: POICategory.PointOfConsumption,
   },
   {
      label: 'Candidate',
      value: POICategory.Candidate,
   },
]

export const POIList = (): JSX.Element => {
   const classes = useStyles2()
   const [page, setPage] = React.useState(0)
   const [rowsPerPage, setRowsPerPage] = React.useState(10)
   const [rows, setRows] = React.useState([] as POIListItem[])
   const [count, setCount] = React.useState(0)
   const [categories, setCategories] = React.useState(getSettingsPOIListCats())
   const [searchText, setSearchText] = React.useState('')
   const [listOrder, setListOrder] = React.useState<LabelOrder<POIListField>>({
      field: 'name',
      direction: 'asc',
   })
   const authContext = useContext(AuthFuncContext)

   useEffect(() => {
      let cat
      if (categories.size === 0) {
         cat = [
            POICategory.Brewery,
            POICategory.DistributionCenter,
            POICategory.Wholesaler,
            POICategory.PointOfConsumption,
            POICategory.Candidate,
         ]
      } else {
         cat = categories.toArray()
      }
      const poiServiceParams = {
         pag: { page, rowsPerPage },
         categories: cat,
         nameFilter: searchText,
         order: listOrder,
      }
      POIListService(poiServiceParams)
         .then((res) => {
            if (res === undefined) return
            setCount(res.count)
            setRows(res.list)
         })
         .catch(handleRequestErrors(authContext))
   }, [rowsPerPage, page, categories, searchText, listOrder, authContext])

   const changeCategories = (new_cats: Set<POICategory>) => {
      setPage(0)
      setCategories(new_cats)
      setSettingsPOIListCats(new_cats)
   }

   const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
   ) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   ) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
   }

   return (
      <Page>
         <PageContent>
            <Section paperless>
               <SectionTitle>Points of Interest</SectionTitle>
               <SectionContent>
                  <Grid
                     container
                     alignItems="center"
                     spacing={2}
                     justify={'space-between'}
                  >
                     <Grid item>
                        <Typography
                           variant="subtitle1"
                           className={classes.filterText}
                        >
                           Filter:
                        </Typography>
                        <MultiChoice<POICategory>
                           options={poiCategories}
                           selected={categories}
                           onChange={changeCategories}
                        />
                     </Grid>
                     <Grid item>
                        <TextSearch
                           placeholder="Search a POI"
                           text={searchText}
                           changeHandler={setSearchText}
                        />
                     </Grid>
                  </Grid>
               </SectionContent>
            </Section>
            <Section paperless>
               <SectionContent>
                  <TableContainer component={Paper}>
                     <Table
                        className={classes.table}
                        aria-label="custom pagination table"
                     >
                        <TableHead>
                           <TableRow>
                              <TableCell>
                                 <SortLabel<POIListField>
                                    field={'name'}
                                    order={listOrder}
                                    handleChange={setListOrder}
                                    label={'Name'}
                                 />
                              </TableCell>
                              <TableCell align="right">Type</TableCell>
                              <TableCell align="right">
                                 <SortLabel<POIListField>
                                    field={'inventory'}
                                    order={listOrder}
                                    handleChange={setListOrder}
                                    label={'Inventory'}
                                 />
                                 <InfoTooltip tooltip="latest inventory and evolution for last 30 days" />
                              </TableCell>
                              <TableCell align="right">
                                 <SortLabel<POIListField>
                                    field={'full_kegs'}
                                    order={listOrder}
                                    handleChange={setListOrder}
                                    label={'Full Kegs'}
                                 />
                              </TableCell>
                              <TableCell align="right">
                                 <SortLabel<POIListField>
                                    field={'empty_kegs'}
                                    order={listOrder}
                                    handleChange={setListOrder}
                                    label={'Empty Kegs'}
                                 />
                              </TableCell>
                              <TableCell align="right">
                                 <SortLabel<POIListField>
                                    field={'min_arrival'}
                                    order={listOrder}
                                    handleChange={setListOrder}
                                    label={'Oldest Keg'}
                                 />
                              </TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rows &&
                              rows.map((row) => (
                                 <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                       <Link
                                          component={RouterLink}
                                          to={'/poi/' + row.id}
                                       >
                                          {row.name}
                                       </Link>
                                    </TableCell>
                                    <TableCell align="right">
                                       {row.category}
                                    </TableCell>
                                    <TableCell align="right">
                                       <POIInventorySparkLine
                                          key={row.id}
                                          category={row.category}
                                          id={row.id}
                                       />
                                    </TableCell>
                                    <TableCell align="right">
                                       {row.full}
                                    </TableCell>
                                    <TableCell align="right">
                                       {row.empty}
                                    </TableCell>
                                    <TableCell align="right">
                                       {row.min_arrival !== undefined ? (
                                          <ShortDate
                                             date={row.min_arrival}
                                             suffix={false}
                                          />
                                       ) : (
                                          ''
                                       )}
                                    </TableCell>
                                 </TableRow>
                              ))}
                           {/*emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )*/}
                        </TableBody>
                        <TableFooter>
                           <TableRow>
                              <TablePagination
                                 rowsPerPageOptions={[10, 50, 100]}
                                 colSpan={3}
                                 rowsPerPage={rowsPerPage}
                                 count={count}
                                 page={page}
                                 SelectProps={{
                                    inputProps: {
                                       'aria-label': 'rows per page',
                                    },
                                    native: true,
                                 }}
                                 onPageChange={handleChangePage}
                                 onChangeRowsPerPage={handleChangeRowsPerPage}
                                 ActionsComponent={TablePaginationActions}
                              />
                           </TableRow>
                        </TableFooter>
                     </Table>
                  </TableContainer>
               </SectionContent>
            </Section>
         </PageContent>
      </Page>
   )
}
