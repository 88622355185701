import { POICategory } from '../../../util/types/types'
import Sparkline from '../../components/charts/line/SparkLine/Sparkline'
import { InventoryPOIService } from '../../../data/service/inventory/InventoryService'
import React from 'react'
import { DateTime } from 'luxon'

interface Props {
   category: POICategory
   id: number
}

export const POIInventorySparkLine = ({ category, id }: Props): JSX.Element => {
   const data = InventoryPOIService({
      locationId: id,
      interval: {
         minDate: DateTime.now().plus({ day: -30 }),
         maxDate: DateTime.now(),
      },
   })

   return (
      <Sparkline
         key={id}
         height={20}
         width={150}
         fill={true}
         category={category}
         data={data}
      />
   )
}
