import React, { useEffect, useState } from 'react'
import { Link as LinkRouter, useRouteMatch } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
   KegDetailFilter,
   KegDetailService,
   KegListItem,
   KegMapDetailService,
} from '../../data/service/kegs/KegsService'
import { OnalyticsDynamicMap } from '../components/charts/map/OnalyticsDynamicMap'

type TParams = { kegId: string }

export const KegMovement = (): JSX.Element => {
   const match = useRouteMatch<TParams>()
   const [kegDetail, setKegDetail] = useState<KegListItem>()
   const [kegMapData, setKegMapData] = useState<Promise<any>>()

   useEffect(() => {
      KegDetailService(+match.params.kegId).then((k) => setKegDetail(k))
      const mapFilter: KegDetailFilter = {
         kegId: +match.params.kegId,
      }

      setKegMapData(KegMapDetailService(mapFilter))
   }, [match.params.kegId])

   return (
      <>
         <Box p={1}>
            <Grid
               container
               justify="flex-start"
               spacing={1}
               alignItems="center"
            >
               <Grid item>
                  <IconButton component={LinkRouter} to="/kegs">
                     {' '}
                     <ArrowBackIcon />
                  </IconButton>
               </Grid>
               <Grid item>
                  <Typography variant="h2">{kegDetail?.reference}</Typography>
               </Grid>
            </Grid>
         </Box>
         <Divider />
         <Box p={2}>
            <Typography gutterBottom variant="h4" component="h4">
               Recent locations:
            </Typography>
            <div style={{ height: '600px' }}>
               {kegMapData !== undefined && (
                  <OnalyticsDynamicMap data={kegMapData} height={600} />
               )}
            </div>
            <div>&nbsp;</div>
            <Typography gutterBottom variant="h4" component="h4">
               Location History
            </Typography>
         </Box>
      </>
   )
}
