import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ReactNode } from 'react'

const useStyles = makeStyles((theme) => ({
   paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
   },
}))

type Props = {
   paperless?: boolean
   children: ReactNode
}

export const Section = ({
   paperless = false,
   children,
}: Props): JSX.Element => {
   const classes = useStyles()

   return (
      <Grid item xs={12}>
         {!paperless && <Paper className={classes.paper}>{children}</Paper>}
         {paperless && children}
      </Grid>
   )
}
