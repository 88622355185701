import { Order } from '../../../util/types/types'
import TableSortLabel from '@material-ui/core/TableSortLabel'

export interface LabelOrder<T> {
   field: T
   direction: NonNullable<Order>
}

type Props<T> = {
   field: T
   order: LabelOrder<T>
   handleChange: (order: LabelOrder<T>) => void
   label: string
}

export const SortLabel = <T extends unknown>({
   field,
   order,
   handleChange,
   label,
}: Props<T>): JSX.Element => {
   const handleClick = () => {
      if (field !== order?.field) {
         handleChange({
            field: field,
            direction: 'asc',
         })
      } else {
         const dir = order?.direction === 'asc' ? 'desc' : 'asc'
         handleChange({
            field: field,
            direction: dir,
         })
      }
   }

   return (
      <TableSortLabel
         active={order?.field === field}
         direction={order?.direction}
         onClick={handleClick}
      >
         {label}
      </TableSortLabel>
   )
}
