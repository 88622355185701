import { select, Selection } from 'd3-selection'
import { geoPath, geoTransform } from 'd3-geo'
import { scaleSqrt } from 'd3-scale'
import { color as d3Color } from 'd3-color'
import React, { useCallback, useEffect, useState } from 'react'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import './onalyticsmapd3.css'
import Supercluster from 'supercluster'
import { getPlaceColor } from '../../../../util/Looks/colors'
import { shortSpaceFormat } from '../../../../util/i18n/formatters'
import { calcBBox } from '../../../../util/geo/geo'
import { useAsync } from 'react-async'
// @ts-ignore
import { tip as d3tip } from 'd3-v6-tip'
import './d3-tip.css'
import 'd3-transition'

interface Props {
   data: Promise<any>
   height?: number
}

export const OnalyticsMapD3 = ({ data, height }: Props): JSX.Element => {
   const { data: resolvedData, error, isPending } = useAsync({ promise: data })
   const [rect, setRect] = useState(new DOMRect())
   const [svg, setSvg] = useState<Selection<SVGSVGElement, any, any, any>>()
   const [map, setMap] = useState<mapboxgl.Map>()

   const mapRef = useCallback((node) => {
      if (node !== null) {
         const rect = node.getBoundingClientRect()
         setRect(rect)

         mapboxgl.accessToken =
            'pk.eyJ1IjoibnVub2xvcGVzb25hbHl0aWNzIiwiYSI6ImNrMWtsa2N1ODBjamUzZHBidHl3bGdtZmgifQ._1pol1biXvHg07uyyiFVOA'
         const m = new mapboxgl.Map({
            container: node,
            style: 'mapbox://styles/mapbox/light-v10',
            zoom: 6,
            minZoom: 3,
            maxZoom: 18,
            center: [-8.2, 39.4],
            attributionControl: false,

            //style: 'mapbox://styles/mapbox/streets-v9',
         }).addControl(new mapboxgl.NavigationControl())

         setMap(m)
         const cont = m.getCanvasContainer()

         setSvg(select(cont).append('svg'))
      }
   }, [])

   useEffect(() => {
      if (
         svg == null ||
         error !== undefined ||
         isPending ||
         resolvedData === undefined
      ) {
         return
      }

      const tip = d3tip()
      tip.direction('s')

      tip.attr('class', 'd3-tip').html((EVENT: any, d: any) => {
         if (d.properties.cluster) {
            let res = ''
            let count = 0
            for (const k of d.properties.kegs) {
               res +=
                  '<a href="' +
                  process.env.PUBLIC_URL +
                  '/keg/' +
                  k.id +
                  '/">' +
                  k.name +
                  '</a><br/>'
               if (++count >= 10) {
                  res += '...'
                  break
               }
            }
            return res
         }
         return (
            '<a href="' +
            process.env.PUBLIC_URL +
            '/keg/' +
            d.properties.id +
            '/"">' +
            d.properties.asset +
            '</a><br/>'
         )
      })

      svg.style('position', 'absolute')
         .style('top', 0)
         .style('left', 0)
         .attr('width', rect.width)
         .attr('height', rect.height)
         .style('pointer-events', 'none')

      svg.call(tip)

      tip.visible = false
      tip.objId = ''

      //Project any point to map's current state
      function projectPoint(lon: number, lat: number) {
         if (map === undefined) {
            return
         }
         const point = map.project(new mapboxgl.LngLat(lon, lat))
         // @ts-ignore
         this.stream.point(point.x, point.y)
      }

      const index = new Supercluster({
         radius: 60,
         map: (props) => ({
            sum: props.value,
            cat: props.category,
            kegs: [{ name: props.asset, id: props.id }],
         }),
         reduce: (accumulated, props) => {
            accumulated.sum += props.sum
            accumulated.kegs = accumulated.kegs.concat(props.kegs)
            if (accumulated.category !== props.cat) {
               accumulated.category = 'MULTI'
            } else {
               accumulated.category = props.cat
            }
            return accumulated
         },
      })

      const transform = geoTransform({ point: projectPoint })

      const path = geoPath().projection(transform)

      function getClusters(map: mapboxgl.Map, index: Supercluster) {
         const bounds = map.getBounds()
         const bbox: [number, number, number, number] = [
            bounds.getWest(),
            bounds.getSouth(),
            bounds.getEast(),
            bounds.getNorth(),
         ]
         const zoom = map.getZoom()

         return index.getClusters(bbox, Math.round(zoom))
      }

      const supplyChainPoint: { features: any } = resolvedData
      if (supplyChainPoint.features == null) {
         return
      }

      const total = supplyChainPoint.features.reduce(
         (acc: number, val: any) => acc + val.properties.value,
         0
      )

      // calculate bounding box
      // @ts-ignore
      const bbox = calcBBox(supplyChainPoint)

      const radius = scaleSqrt().domain([0, total]).range([8, 18])

      index.load(supplyChainPoint.features)
      const update = () => {
         if (map === undefined) {
            return
         }
         const clusters = getClusters(map, index)

         const circles = svg
            .attr('class', 'bubble')
            .selectAll('circle')
            .data(clusters)

         tip.hide()
         tip.visible = false

         const c = circles
            .enter()
            .append('circle')
            // @ts-ignore
            .merge(circles)
            .attr('transform', (d: any) => {
               return 'translate(' + path.centroid(d) + ')'
            })
            //@ts-ignore
            .attr('r', (d: any) => {
               return radius(d.properties.sum || d.properties.value)
            })
            .attr('fill', (d: any) => {
               if (d.properties.category === 'MULTI') {
                  return getPlaceColor()
               }
               return getPlaceColor(d.properties.category)
            })
            .style('pointer-events', (d: any) => {
               if (d.properties.category === 'MULTIS') {
                  return 'none'
               }
               return 'fill'
            })
            .on('click', (event, d) => {
               let objId = ''
               if (d.properties.cluster) {
                  objId = 'C' + d.properties.cluster_id
               } else {
                  objId = 'O' + d.properties.id
               }
               if (tip.visible && objId === tip.objId) {
                  tip.hide(event, d)
                  tip.visible = false
               } else {
                  tip.objId = objId
                  tip.show(event, d)
                  tip.visible = true
               }
            })

         c.style('cursor', (d: any) => {
            if (d.properties.category === 'MULTIS') {
               return 'auto'
            }
            return 'pointer'
         })

         circles.exit().transition().attr('r', 0).remove()

         const labels = svg
            .attr('class', 'bubble')
            .selectAll('text')
            .data(clusters)

         labels
            .enter()
            .append('text')
            .attr('class', 'map-label')
            // @ts-ignore
            .merge(labels)
            .attr('transform', (d: any) => {
               return 'translate(' + path.centroid(d) + ')'
            })
            .attr('fill', (d: any) => {
               if (d.properties.category === 'MULTI') {
                  const color = d3Color(getPlaceColor())
                  return color === null ? '#f00' : color.darker(1.1).toString()
               }
               const color = d3Color(getPlaceColor(d.properties.category))
               return color === null ? '#f00' : color.darker(1.1).toString()
            })
            .text((d: any) => {
               const val = d.properties.sum || d.properties.value || 0
               return shortSpaceFormat(val)
            })

         labels.exit().remove()
      }

      if (map === undefined) {
         return
      }
      // Every time the map changes, update the dots
      map.on('viewreset', update)
      map.on('move', update)
      map.on('moveend', update)

      if (bbox !== null) {
         map.fitBounds(bbox, {
            padding: { top: 15, bottom: 20, left: 15, right: 15 },
         })
      }

      update()
   }, [data, svg, map, rect.height, rect.width, error, isPending, resolvedData])

   const px_height = height === undefined ? '600px' : `${height}px`

   const styles = { height: px_height, width: '100%' }
   return (
      <div>
         <div style={styles} ref={mapRef} />
      </div>
   )
}
