import { color as d3Color } from 'd3-color'
import { schemeTableau10 } from 'd3-scale-chromatic'
import { FillLevel, POICategory } from '../types/types'

function getPlaceColor(cat?: POICategory, opacity = 1, darker = 0): string {
   if (cat === undefined) {
      //@ts-ignore - wait for a new version of types
      //return d3.schemeTableau10[8];
      return '#666'
   }

   const catIndex = new Map([
      [POICategory.Brewery, 0],
      [POICategory.DistributionCenter, 1],
      [POICategory.Wholesaler, 3],
      [POICategory.PointOfConsumption, 6],
      [POICategory.Candidate, 5],
      [POICategory.RejectedCandidate, 2],
   ])

   const schemeColor = schemeTableau10[catIndex.get(cat) ?? 0]

   let color = d3Color(schemeColor)

   if (color === null) {
      return schemeColor
   }

   color = color.darker(darker)

   color.opacity = opacity
   return color.toString()
}

function getFillColor(fl: FillLevel): string {
   let index = 0
   switch (fl) {
      case FillLevel.All:
         index = 2
         break
      case FillLevel.Empty:
         index = 9
         break
      case FillLevel.Full:
         index = 8
         break
   }
   return schemeTableau10[index]
}

export enum MsgType {
   OK,
   WARN,
   ERROR,
   OTHER,
}

function getMsgColor(msgColorType: MsgType, opacity = 1): string {
   // default to gray
   let index = 9
   switch (msgColorType) {
      case MsgType.OK:
         index = 4
         break
      case MsgType.WARN:
         index = 5
         break
      case MsgType.ERROR:
         index = 2
         break
   }
   const schemeColor = schemeTableau10[index]

   const color = d3Color(schemeColor)

   if (color === null) {
      return schemeColor
   }

   color.opacity = opacity
   return color.toString()
}

export { getPlaceColor, getFillColor, getMsgColor }
