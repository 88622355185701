import React from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { FillLevel } from '../../../util/types/types'
import { Set } from 'immutable'

interface Props {
   values: Set<FillLevel>
   filterChangeHandler: (val: Set<FillLevel>) => void
}

export const AssetStatusFilterComponent = ({
   values,
   filterChangeHandler,
}: Props): JSX.Element => {
   const handleClick = (clicked: FillLevel) => {
      return () => {
         let v: Set<FillLevel>
         if (values.has(clicked)) {
            v = values.delete(clicked)
         } else {
            v = values.add(clicked)
         }
         filterChangeHandler(v)
      }
   }

   return (
      <>
         <ButtonGroup
            orientation="horizontal"
            color="primary"
            aria-label="vertical contained primary button group"
            variant="contained"
            disableElevation
         >
            <Button
               variant={values.has(FillLevel.Empty) ? 'contained' : 'outlined'}
               onClick={handleClick(FillLevel.Empty)}
            >
               Empty
            </Button>
            <Button
               variant={values.has(FillLevel.Full) ? 'contained' : 'outlined'}
               onClick={handleClick(FillLevel.Full)}
            >
               Full
            </Button>
         </ButtonGroup>
      </>
   )
}
