import React, { useEffect, useState } from 'react'
import { format as d3Format } from 'd3-format'
import Tooltip from '@material-ui/core/Tooltip'
import { getMsgColor, MsgType } from '../../../util/Looks/colors'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { BATTERY_HIGH, BATTERY_MIN } from '../../../util/types/types'

interface BatteryLevelProps {
   voltage?: number
}

const useStyles = makeStyles({
   batLevelIndicator: {
      '& span': {
         padding: '0.2em 1em 0.2em 1em',
         minWidth: '4em',
         display: 'inline-block',
         textAlign: 'center',
      },
   },
   LOW: {
      backgroundColor: getMsgColor(MsgType.ERROR, 0.6),
      color: '#333',
   },
   HIGH: {
      backgroundColor: getMsgColor(MsgType.OK, 0.6),
      color: '#333',
   },
   MEDIUM: {
      backgroundColor: getMsgColor(MsgType.WARN, 0.6),
      color: '#333',
   },
   OTHER: {
      backgroundColor: getMsgColor(MsgType.OTHER, 0.6),
      color: '#333',
   },
})

export const BatteryLevel = ({ voltage }: BatteryLevelProps): JSX.Element => {
   const [type, setType] = useState<MsgType>(MsgType.OTHER)

   const classes = useStyles()

   useEffect(() => {
      setType(getMsgType(voltage))
   }, [voltage])

   const getMsgType = (voltage?: number): MsgType => {
      if (voltage === undefined) {
         return MsgType.OTHER
      }

      if (voltage < BATTERY_MIN) {
         return MsgType.ERROR
      } else if (voltage < BATTERY_HIGH) {
         return MsgType.WARN
      } else {
         return MsgType.OK
      }
   }

   const getRightStyle = (msgType: MsgType) => {
      switch (msgType) {
         case MsgType.OK:
            return classes.HIGH
         case MsgType.WARN:
            return classes.MEDIUM
         case MsgType.ERROR:
            return classes.LOW
         case MsgType.OTHER:
            return classes.OTHER
      }
   }

   const getDescription = (msgType: MsgType) => {
      switch (msgType) {
         case MsgType.ERROR:
            return 'Low'
         case MsgType.WARN:
            return 'Medium'
         case MsgType.OK:
            return 'High'
         case MsgType.OTHER:
            return 'No info'
      }
   }

   return (
      <div className={classes.batLevelIndicator}>
         <Tooltip
            title={
               voltage === undefined ? '-' : d3Format('.1f')(voltage) + ' V'
            }
         >
            <span className={getRightStyle(type)}>{getDescription(type)}</span>
         </Tooltip>
      </div>
   )
}
