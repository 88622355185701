import React from 'react'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
   root: {
      padding: theme.spacing(4),
      background: '#F4F6F8',
   },
}))

const Overview = (): JSX.Element => {
   const classes = useStyles()

   return (
      <div className={classes.root}>
         <Grid container></Grid>
      </div>
   )
}

export default Overview
