import { FeatureCollection, Point, Position } from 'geojson'

export type BBox2D = [number, number, number, number]

export const calcBBox = (f: FeatureCollection<Point>): BBox2D => {
   if (f.features.length === 0) {
      return [0, 0, 0, 0]
   }

   return f.features.reduce<BBox2D>(
      (bbox: BBox2D, p, i) => {
         const coord = p.geometry.coordinates
         if (i === 0) {
            bbox[0] = coord[0]
            bbox[1] = coord[1]
            bbox[2] = coord[0]
            bbox[3] = coord[1]
            return bbox
         }

         if (coord[0] < bbox[0]) {
            bbox[0] = coord[0]
         }
         if (coord[1] < bbox[1]) {
            bbox[1] = coord[1]
         }
         if (coord[0] > bbox[2]) {
            bbox[2] = coord[0]
         }
         if (coord[1] > bbox[3]) {
            bbox[3] = coord[1]
         }
         return bbox
      },
      [0, 0, 0, 0]
   )
}

// calculate distance based on the haverstine's formula
// http://www.movable-type.co.uk/scripts/latlong.html
export const distance = (c1: Position, c2: Position): number => {
   const lon1 = c1[0]
   const lat1 = c1[1]
   const lon2 = c2[0]
   const lat2 = c2[1]

   const R = 6371e3 // metres
   const φ1 = (lat1 * Math.PI) / 180 // φ, λ in radians
   const φ2 = (lat2 * Math.PI) / 180
   const Δφ = ((lat2 - lat1) * Math.PI) / 180
   const Δλ = ((lon2 - lon1) * Math.PI) / 180

   const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

   const d = R * c // in metres
   return d
}
