import React, {
   ChangeEvent,
   MouseEvent,
   useContext,
   useEffect,
   useState,
} from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { KegListMap } from './KegListMap'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import {
   KegListField,
   KegListFilters,
   KegListItem,
   KegListService,
} from '../../data/service/kegs/KegsService'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import { TablePaginationActions } from '../components/table/Pagination'
import { Pagination } from '../../util/types/serviceUtil'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import { BatteryFilter } from '../components/micro/BatteryFilter'
import { BatteryLevel } from '../components/micro/BatteryLevel'
import {
   LocationLink,
   Page,
   PageContent,
   Section,
   SectionContent,
   SectionTitle,
} from '../../components'
import {
   TemperatureFilter,
   temperatureFilterValues,
} from '../components/micro/TemperatureFilter'
import { TextSearch } from '../../components/Filter/TextSearch'
import theme from '../../theme'
import { LabelOrder, SortLabel } from '../components/table/SortLabel'
import { useIntl } from 'react-intl'
import { temperatureFormatterCelsius } from '../../util/i18n/units'
import { handleRequestErrors } from '../../util/auth/authHelper'
import { AuthFuncContext } from '../../App'
import { ShortDate } from '../../components/ShortDate/ShortDate'
import { getFillLevelName } from '../../util/types/types'

const useStyles = makeStyles({
   table: {
      minWidth: 650,
   },
   header: {
      textAlign: 'center',
   },
   filterText: {
      display: 'inline',
      marginRight: theme.spacing(1),
   },
})

const KegList = (): JSX.Element => {
   const classes = useStyles()
   const intl = useIntl()
   const [page, setPage] = useState(0)
   const [rowsPerPage, setRowsPerPage] = useState(10)
   const [rows, setRows] = useState([] as KegListItem[])
   const [count, setCount] = useState(0)
   const [tempFilterValue, setTempFilterValue] =
      useState<temperatureFilterValues>({
         minTemperature: undefined,
         maxTemperature: undefined,
      })
   const [batFilterValue, setBatFilterValue] = useState<number[]>([
      -Infinity,
      +Infinity,
   ])
   const [searchKeg, setSearchKeg] = useState('')
   const [order, setOrder] = useState<LabelOrder<KegListField>>({
      field: 'name',
      direction: 'asc',
   })
   const authContext = useContext(AuthFuncContext)

   useEffect(() => {
      const pag: Pagination = {
         rowsPerPage: rowsPerPage,
         page: page,
      }

      const listFilter: KegListFilters = {}
      listFilter.minTemperature = tempFilterValue.minTemperature
      listFilter.maxTemperature = tempFilterValue.maxTemperature

      if (batFilterValue[0] !== -Infinity)
         listFilter.minBattery = batFilterValue[0]
      if (batFilterValue[1] !== +Infinity)
         listFilter.maxBattery = batFilterValue[1]

      if (searchKeg !== '') listFilter.kegName = searchKeg

      KegListService(listFilter, pag, order)
         .then((res) => {
            setCount(res.count)
            setRows(res.kegList)
         })
         .catch(handleRequestErrors(authContext))
   }, [
      rowsPerPage,
      page,
      tempFilterValue,
      batFilterValue,
      searchKeg,
      order,
      authContext,
   ])

   const handleChangePage = (
      event: MouseEvent<HTMLButtonElement> | null,
      newPage: number
   ) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   ) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
   }

   const handleFilterChange = <T extends unknown>(f: (v: T) => void) => {
      return (v: T) => {
         setPage(0)
         f(v)
      }
   }

   return (
      <Page>
         <PageContent>
            <Section paperless>
               <SectionTitle>Keg Location</SectionTitle>
               <SectionContent>
                  <KegListMap />
               </SectionContent>
            </Section>
            <Section paperless>
               <SectionTitle>Keg List</SectionTitle>
               <SectionContent>
                  <Grid
                     container
                     alignItems="center"
                     spacing={2}
                     justify={'space-between'}
                  >
                     <Grid
                        item
                        xs={7}
                        container
                        spacing={3}
                        justify={'flex-start'}
                        alignItems="center"
                     >
                        <Grid item>
                           <Typography
                              variant="subtitle1"
                              className={classes.filterText}
                           >
                              Filter:
                           </Typography>
                        </Grid>
                        <Grid item>
                           <TemperatureFilter
                              filter={tempFilterValue}
                              onChange={handleFilterChange<temperatureFilterValues>(
                                 setTempFilterValue
                              )}
                           />
                        </Grid>
                        <Grid item>
                           <BatteryFilter
                              onChange={handleFilterChange<number[]>(
                                 setBatFilterValue
                              )}
                           />
                        </Grid>
                     </Grid>
                     <Grid item>
                        <TextSearch
                           placeholder="Search a Keg"
                           text={searchKeg}
                           changeHandler={handleFilterChange<string>(
                              setSearchKeg
                           )}
                        />
                     </Grid>
                  </Grid>
               </SectionContent>
            </Section>
            <Section paperless>
               <SectionContent>
                  <Table className={classes.table} aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>
                              <SortLabel<KegListField>
                                 field={'name'}
                                 order={order}
                                 handleChange={setOrder}
                                 label="Keg"
                              />
                           </TableCell>
                           <TableCell align="right">Location</TableCell>
                           <TableCell align="right">Date</TableCell>
                           <TableCell align="right">
                              <SortLabel<KegListField>
                                 field={'temperature'}
                                 order={order}
                                 handleChange={setOrder}
                                 label="Temperature"
                              />
                           </TableCell>
                           <TableCell align="right">
                              <SortLabel<KegListField>
                                 field={'battery'}
                                 order={order}
                                 handleChange={setOrder}
                                 label="Battery"
                              />
                           </TableCell>
                           <TableCell align="right">Status</TableCell>
                           <TableCell align="right">
                              <SortLabel<KegListField>
                                 field={'last_communication'}
                                 order={order}
                                 handleChange={setOrder}
                                 label="Last Communication"
                              />
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rows.map((row) => (
                           <TableRow key={row.assetId}>
                              <TableCell component="th" scope="row">
                                 <Link
                                    component={RouterLink}
                                    to={'/keg/' + row.assetId}
                                 >
                                    {row.reference}
                                 </Link>
                              </TableCell>
                              <TableCell align="right">
                                 {row.departureDate !== undefined
                                    ? 'In transit from '
                                    : ''}
                                 {row.locationId !== undefined &&
                                    row.locationName !== undefined &&
                                    row.locationCategory !== undefined && (
                                       <LocationLink
                                          id={row.locationId}
                                          name={row.locationName}
                                          category={row.locationCategory}
                                       />
                                    )}
                              </TableCell>
                              <TableCell align="right">
                                 {row.departureDate === undefined ? (
                                    row.arrivalDate === undefined ? (
                                       '-'
                                    ) : (
                                       <ShortDate date={row.arrivalDate} />
                                    )
                                 ) : (
                                    <ShortDate date={row.departureDate} />
                                 )}
                              </TableCell>
                              <TableCell align="right">
                                 {row.temperature === undefined
                                    ? '-'
                                    : temperatureFormatterCelsius(intl)(
                                         row.temperature
                                      )}
                              </TableCell>
                              <TableCell align="right">
                                 <BatteryLevel voltage={row.battery} />
                              </TableCell>
                              <TableCell align="right">
                                 {row.fillLevel !== undefined &&
                                    getFillLevelName(row.fillLevel)}
                              </TableCell>
                              <TableCell align="right">
                                 {row.lastCommunication === undefined ? (
                                    '-'
                                 ) : (
                                    <ShortDate date={row.lastCommunication} />
                                 )}
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                     <TableFooter>
                        <TableRow>
                           <TablePagination
                              rowsPerPageOptions={[10, 50, 100]}
                              colSpan={3}
                              rowsPerPage={rowsPerPage}
                              count={count}
                              page={page}
                              SelectProps={{
                                 inputProps: { 'aria-label': 'rows per page' },
                                 native: true,
                              }}
                              onPageChange={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                           />
                        </TableRow>
                     </TableFooter>
                  </Table>
               </SectionContent>
            </Section>
         </PageContent>
      </Page>
   )
}

export default KegList
