import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

export interface KegEditData {
   reference: string
}

interface Props {
   open: boolean
   kegData: KegEditData
   okHandler: (newValues: KegEditData) => boolean
   cancelHandler: () => void
}

export const KegEdit = ({
   open,
   kegData,
   okHandler,
   cancelHandler,
}: Props): JSX.Element => {
   const [curKeg, setCurKeg] = useState<KegEditData>(kegData)

   const handleNameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
      setCurKeg({ reference: e.target.value as string })
   }

   const handleOk = () => {
      okHandler(curKeg)
   }

   return (
      <Dialog open={open}>
         <DialogTitle>Edit POI: {curKeg.reference}</DialogTitle>
         <DialogContent>
            <Grid
               container
               justify="space-around"
               direction="column"
               spacing={2}
            >
               <Grid item>
                  <FormControl variant="outlined">
                     <TextField
                        label="name"
                        variant="outlined"
                        fullWidth
                        value={curKeg.reference}
                        onChange={handleNameChange}
                     />
                  </FormControl>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={cancelHandler} color="primary">
               Cancel
            </Button>
            <Button onClick={handleOk} color="primary">
               Ok
            </Button>
         </DialogActions>
      </Dialog>
   )
}
