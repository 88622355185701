import React from 'react'
import { POICategory } from '../../../util/types/types'
import { MovementMapService } from '../../../data/service/movements/MovementService'
import { DateTime } from 'luxon'

interface Props {
   category?: POICategory
   endDate: DateTime
   period: number
}

const MovementMap = ({ category, endDate, period }: Props): JSX.Element => {
   const data = MovementMapService({ endDate: endDate, period: period })

   /*const dialogGenerator = (poi: PoiSummary, handleClose: () => void) => {
      return (
         <POIMovementDialog
            poi={poi}
            onClose={handleClose}
            endDate={endDate}
            period={period}
         />
      )
   }*/

   return <>{data}</>
}

export default MovementMap
