import { getPOICategoryName, POICategory } from '../../util/types/types'
import Chip from '@material-ui/core/Chip'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { getPlaceColor } from '../../util/Looks/colors'
import Tooltip from '@material-ui/core/Tooltip'
import { Theme } from '@material-ui/core/styles/createTheme'

export interface LocationChipProps {
   category?: POICategory
}

interface styleProps {
   color: string
}

const useStyles = makeStyles<Theme, styleProps>({
   chip: (props) => ({
      backgroundColor: props.color,
   }),
})

export const LocationChip = (props: LocationChipProps): JSX.Element => {
   const color = { color: getPlaceColor(props.category, 0.5) }
   const classes = useStyles(color)

   return (
      <Tooltip title={getPOICategoryName(props.category)}>
         <Chip className={classes.chip} size="small" label={props.category} />
      </Tooltip>
   )
}
