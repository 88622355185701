import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { Link as LinkRouter, useHistory, useRouteMatch } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import {
   UserData,
   UserDeleteService,
   UserDetailService,
   UserLoggedinService,
} from '../../data/service/user/UserService'
import { UserEdit } from './UserEdit'

interface DialogProps {
   open: boolean
   userId: number
   name: string
   email: string
   handleClose: () => void
   handleDelete: () => void
}

const DeleteDialog = ({
   open,
   userId,
   name,
   email,
   handleClose,
   handleDelete,
}: DialogProps) => {
   return (
      <div>
         <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">
               {'Delete user' + name + '?'}
            </DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete {name} ({email})
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose} color="primary">
                  Cancel
               </Button>
               <Button onClick={handleDelete} color="primary" autoFocus>
                  Delete
               </Button>
            </DialogActions>
         </Dialog>
      </div>
   )
}

type TParams = { userId: string }

export const UserDetail = (): JSX.Element => {
   const match = useRouteMatch<TParams>()
   const [userData, setUserData] = useState<UserData>()
   const [loggedInUser, setLoggedInUser] = useState<UserData>()
   const [dialogOpen, setDialogOpen] = useState<boolean>(false)
   const [editMode, setEditMode] = useState<boolean>(false)
   const history = useHistory()

   useEffect(() => {
      UserDetailService(+match.params.userId).then((d) => setUserData(d))
      UserLoggedinService().then((d) => setLoggedInUser(d))
   }, [match.params.userId])

   return (
      <>
         {!editMode && (
            <>
               <Box p={1}>
                  <Grid
                     container
                     justify="flex-start"
                     spacing={1}
                     alignItems="center"
                  >
                     <Grid item>
                        <IconButton component={LinkRouter} to="/users">
                           {' '}
                           <ArrowBackIcon />
                        </IconButton>
                     </Grid>
                     <Grid item>
                        <Typography variant="h2">{userData?.name}</Typography>
                     </Grid>
                  </Grid>
               </Box>
               <Divider />
               <Box p={2}>
                  <Grid
                     container
                     justify="space-around"
                     direction="column"
                     spacing={3}
                  >
                     <Grid item>
                        <Typography variant="h5" display="inline">
                           Email:{' '}
                        </Typography>
                        {userData?.email}
                     </Grid>
                     <Grid item>
                        <Typography variant="h5" display="inline">
                           Name:{' '}
                        </Typography>
                        {userData?.name}
                     </Grid>
                     <Grid item>
                        <Grid container spacing={3}>
                           <Grid item>
                              <Button
                                 variant="contained"
                                 color="primary"
                                 onClick={() => setEditMode(true)}
                              >
                                 Edit
                              </Button>
                           </Grid>
                           {userData !== undefined &&
                              loggedInUser !== undefined &&
                              userData.id !== loggedInUser.id && (
                                 <Grid item>
                                    <Button
                                       color="secondary"
                                       onClick={() => setDialogOpen(true)}
                                    >
                                       Delete
                                    </Button>
                                 </Grid>
                              )}
                        </Grid>
                     </Grid>
                  </Grid>
               </Box>
               {userData !== undefined && (
                  <DeleteDialog
                     open={dialogOpen}
                     userId={userData.id}
                     name={userData.name}
                     email={userData.email}
                     handleClose={() => setDialogOpen(false)}
                     handleDelete={() => {
                        UserDeleteService(userData.id)
                           .then(() => {
                              setDialogOpen(false)
                              history.push('/users')
                           })
                           .catch((reason) => {
                              // TODO: bug #74 show error
                              setDialogOpen(false)
                           })
                     }}
                  />
               )}
            </>
         )}
         {editMode && userData && (
            <UserEdit
               userId={userData.id}
               backFunc={() => setEditMode(false)}
            />
         )}
      </>
   )
}
