import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import { ChangeEvent, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { BATTERY_HIGH, BATTERY_LOW } from '../../../util/types/types'

const batFilterMarks = [
   {
      value: 0,
      label: 'Min',
   },
   {
      value: 1,
      label: 'Low',
   },
   {
      value: 2,
      label: 'High',
   },
   {
      value: 3,
      label: 'Max',
   },
]

const mapMarksToLevel = (mark: number): number => {
   switch (mark) {
      case 0:
         return -Infinity
      case 1:
         return BATTERY_LOW
      case 2:
         return BATTERY_HIGH
      case 3:
         return +Infinity
   }
   return BATTERY_HIGH
}

const useStyles = makeStyles({
   slider: {
      width: 200,
   },
})

interface BatteryFilterProps {
   onChange: (values: number[]) => void
}

// TODO: review logic: should we store state in this component?
export const BatteryFilter = ({
   onChange,
}: BatteryFilterProps): JSX.Element => {
   const [batFilterValue, setBatFilterValue] = useState<number[]>([0, 3])

   const classes = useStyles()

   const handleChange = (
      event: ChangeEvent<unknown>,
      newValue: number | number[]
   ) => {
      const v = newValue as number[]
      if (v.length < 2) {
         return
      }
      if (v[1] > v[0]) {
         setBatFilterValue(v)
      }
   }

   const handleChangeCommitted = (
      _event: ChangeEvent<unknown>,
      newValue: number | number[]
   ) => {
      const v = newValue as number[]

      const res = new Array<number>(2)
      res[0] = mapMarksToLevel(v[0])
      res[1] = mapMarksToLevel(v[1])

      onChange(res)
   }

   return (
      <div className={classes.slider}>
         <Typography id="discrete-slider-always">Battery Level</Typography>
         <Slider
            min={0}
            max={3}
            step={null}
            marks={batFilterMarks}
            value={batFilterValue}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            valueLabelDisplay="off"
            aria-labelledby="range-slider"
         />
      </div>
   )
}
