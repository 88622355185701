import { POICategory } from '../../../../util/types/types'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { largeNumberFormat } from '../../../../util/i18n/formatters'
import { POIInventoryDialog } from '../../dialog/POIDialog/POIInventoryDialog'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Sparkline } from '../../charts/line'
import { DateTime } from 'luxon'

const useStyles = makeStyles({
   title: {
      fontWeight: 700,
   },
   value: {
      position: 'absolute',
      zIndex: 10,
      marginLeft: '6rem',
      marginTop: '1rem',
      fontSize: '2rem',
      color: '#444',
   },
})

interface Props {
   type: POICategory
   data: any
   listData?: any
   unit?: string
   height?: number
   endDate: DateTime
   period: number
   hasDetail?: boolean
}

interface CatValues {
   value: string
   full: string
   empty: string
}

const cursorHand = { cursor: 'pointer' }
export const ShortTypeContent: FunctionComponent<Props> = ({
   type,
   data,
   listData,
   unit,
   height,
   endDate,
   period,
   hasDetail,
}) => {
   const [realValue, setRealValue] = useState<CatValues>({
      value: '',
      empty: '',
      full: '',
   })
   const [poiList, setPoiList] = useState<JSX.Element[]>([])
   const [dialog, setDialog] = useState<JSX.Element>()

   const [isLoadingData, setIsLoadingData] = useState(true)
   const [isLoadingList, setIsLoadingList] = useState(true)

   const classes = useStyles()

   const displayUnit = unit ? unit : ''
   const compHeight = height ? height : 40
   const showDetail = hasDetail === undefined ? true : hasDetail

   useEffect(() => {
      setIsLoadingData(true)
      setIsLoadingList(true)
      // TODO: use promise.all

      data.then((d: any) => {
         const value =
            d.length === 0 ? 'N/A' : largeNumberFormat(d[d.length - 1].value)
         const full =
            d.length === 0
               ? 'N/A'
               : largeNumberFormat(d[d.length - 1].full_kegs)
         const empty =
            d.length === 0
               ? 'N/A'
               : largeNumberFormat(d[d.length - 1].empty_kegs)
         setRealValue({ value: value, full: full, empty: empty })
         setIsLoadingData(false)
      })

      const showDialog = (
         id: number,
         name: string,
         endDate: DateTime,
         period: number
      ) => {
         setDialog(
            <POIInventoryDialog
               poi={{ id: id, name: name, cat: type }}
               endDate={endDate}
               period={period}
               onClose={() => setDialog(undefined)}
            />
         )
      }

      if (listData === undefined) {
         setIsLoadingList(false)
         return
      }

      listData.then((d: any) => {
         const rows = []
         let line = 0
         for (const item of d) {
            rows.push(
               <React.Fragment key={line + type.toString()}>
                  <Grid item xs={9}>
                     <Typography
                        variant="body1"
                        color="textPrimary"
                        noWrap
                        onClick={() => {
                           showDialog(item.id, item.name, endDate, period)
                        }}
                        style={cursorHand}
                     >
                        {item.name}
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography variant="body1" color="textPrimary">
                        {largeNumberFormat(item.value)}
                     </Typography>
                  </Grid>
               </React.Fragment>
            )
            line++
         }
         setPoiList(rows)
         setIsLoadingList(false)
      })
   }, [data, listData, endDate, period, type])

   let detail = <></>
   if (showDetail) {
      detail = (
         <Typography
            variant="body1"
            color="textPrimary"
            gutterBottom
            align={'center'}
         >
            ({realValue.full} Full/{realValue.empty} Empty)
         </Typography>
      )
   }

   return (
      <>
         <Typography
            variant="body1"
            color="textPrimary"
            className={classes.title}
            align={'center'}
         >
            {realValue.value} {displayUnit}
         </Typography>
         {detail}
         {((isLoadingData || isLoadingList) && <CircularProgress />) || (
            <>
               <Sparkline
                  category={type}
                  height={compHeight}
                  fill={true}
                  inline={false}
                  data={data}
               />
               <Typography variant="body1">&nbsp;</Typography>
               <Grid
                  container
                  direction="row"
                  justify="space-evenly"
                  alignItems="flex-start"
               >
                  {poiList}
               </Grid>
            </>
         )}
         {dialog}
      </>
   )
}
