import React from 'react'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import Paper from '@material-ui/core/Paper'
import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles/createTheme'

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         padding: '1px 2px',
         display: 'flex',
         alignItems: 'center',
         width: 400,
      },
      input: {
         marginLeft: theme.spacing(1),
         flex: 1,
      },
      iconButton: {
         padding: 10,
      },
   })
)

interface Props {
   value: string | undefined
   filterChangeHandler: (val: string) => void
}

export const AssetReferenceFilterComponent = ({
   value,
   filterChangeHandler,
}: Props): JSX.Element => {
   const classes = useStyles()
   const placeholder = 'Search a Keg'

   const changeHandler = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   ) => filterChangeHandler(e.target.value)
   const submitHandler = () => false

   return (
      <>
         <Paper
            component={'form'}
            className={classes.root}
            onSubmit={(e) => {
               e.preventDefault()
            }}
         >
            <InputBase
               placeholder={placeholder}
               className={classes.input}
               value={value}
               onChange={changeHandler}
            />
            <IconButton
               type={'submit'}
               className={classes.iconButton}
               onSubmit={submitHandler}
            >
               <SearchIcon />
            </IconButton>
         </Paper>
      </>
   )
}
