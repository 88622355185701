import config from 'react-global-configuration'
import { json } from 'd3-fetch'
import { DateTime } from 'luxon'
import { POICategory } from '../../../util/types/types'

export interface POIStock {
   full: number
   empty: number
}

export interface POIData {
   id: number
   name: string
   category: POICategory
   coordinates: [number, number]
   stock: POIStock
   minDate: DateTime
}

export interface LatLng {
   lat: number
   lng: number
}

export interface POIDetailAPIReply {
   id: number
   name: string
   category: POICategory
   coordinates: [number, number]
   position: undefined | LatLng
   stock: POIStock
   min_date: string
}

export const POIDetailService = (
   locationId: number
): Promise<POIData | undefined> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/poi/' + locationId

   return json<POIDetailAPIReply>(api_url.href, {
      credentials: 'include',
   }).then((detail) => {
      if (detail === undefined) return undefined
      //small protocol update
      if (detail.position !== undefined && detail.position !== null) {
         detail.coordinates = [detail.position.lat, detail.position.lng]
      }
      return {
         id: detail.id,
         name: detail.name,
         category: detail.category,
         coordinates: detail.coordinates,
         stock: detail.stock,
         minDate: DateTime.fromISO(detail.min_date),
      }
   })
}
