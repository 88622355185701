import React, { useContext } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/styles/makeStyles'
import Drawer from '@material-ui/core/Drawer'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'
import HomeOutlined from '@material-ui/icons/HomeOutlined'
import AssignmentIcon from '@material-ui/icons/Assignment'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'

import PlaceIcon from '@material-ui/icons/Place'

import { SidebarNav } from './components'

import config from 'react-global-configuration'
import { AuthContext } from '../../../../App'

const useStyles = makeStyles((theme) => ({
   drawer: {
      width: 200,
      //@ts-ignore
      [theme.breakpoints.up('lg')]: {
         marginTop: 64,
         height: 'calc(100% - 64px)',
      },
   },
   root: {
      //@ts-ignore
      backgroundColor: theme.palette.white,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      //@ts-ignore
      padding: theme.spacing(1),
   },
   nav: {
      //@ts-ignore
      marginBottom: theme.spacing(2),
   },
}))

const Sidebar = (props: {
   [x: string]: any
   open: any
   variant: any
   onClose: any
}): JSX.Element => {
   const { open, variant, onClose, className, ...rest } = props

   const classes = useStyles()

   const allPages = [
      {
         title: 'Overview',
         href: '/overview',
         icon: <HomeOutlined />,
         reqAdmin: false,
         prod: false,
      },
      {
         title: 'Logistics',
         href: '/logistics',
         icon: <LocalShippingIcon />,
         reqAdmin: false,
         prod: false,
      },
      {
         title: 'Inventory',
         href: '/inventory',
         icon: <AssignmentIcon />,
         reqAdmin: false,
         prod: false,
      },
      {
         title: 'Kegs',
         href: '/kegs',
         icon: <DashboardIcon />,
         reqAdmin: false,
         prod: true,
      },
      {
         title: 'POI',
         href: '/pois',
         icon: <PlaceIcon />,
         reqAdmin: false,
         prod: true,
      },
      {
         title: 'Reports',
         href: '/reports',
         icon: <DashboardIcon />,
         reqAdmin: false,
         prod: true,
      },
      {
         title: 'Users',
         href: '/users',
         icon: <PeopleIcon />,
         reqAdmin: true,
         prod: true,
      },
      {
         title: 'Settings',
         href: '/settings',
         icon: <SettingsIcon />,
         reqAdmin: false,
         prod: true,
      },
   ]

   const authContext = useContext(AuthContext)

   const pages = allPages.filter(
      (p) =>
         (!p.reqAdmin || authContext.isAdmin) && (p.prod || config.get('dev'))
   )

   return (
      <Drawer
         anchor="left"
         classes={{ paper: classes.drawer }}
         onClose={onClose}
         open={open}
         variant={variant}
      >
         <div {...rest} className={clsx(classes.root, className)}>
            <SidebarNav className={classes.nav} pages={pages} />
         </div>
      </Drawer>
   )
}

Sidebar.propTypes = {
   className: PropTypes.string,
   onClose: PropTypes.func,
   open: PropTypes.bool.isRequired,
   variant: PropTypes.string.isRequired,
}

export default Sidebar
