import { select, Selection } from 'd3-selection'
import { interpolateString as d3InterpolateString } from 'd3-interpolate'
import { geoPath, geoTransform } from 'd3-geo'
import { curveLinear, line as d3Line } from 'd3-shape'
import React, { useCallback, useEffect, useState } from 'react'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import './onalyticsmapd3.css'
import { calcBBox } from '../../../../util/geo/geo'
import { useAsync } from 'react-async'
import { FeatureCollection, Point } from 'geojson'
import {
   KegDetailProps,
   MapDetailResult,
} from '../../../../data/service/kegs/KegsService'
import { TimeSlider } from './TimeSlider'
import { DateInterval } from '../../../../components/Filter/Date/DateIntervalFilter'
import { getPlaceColor } from '../../../../util/Looks/colors'
// @ts-ignore
import { tip as d3tip } from 'd3-v6-tip'
import './d3-tip.css'

const convertTimeToDistance = (
   featureCollection: FeatureCollection<Point, KegDetailProps>,
   percentage: number
): number => {
   const features = featureCollection.features
   if (features.length === 0) {
      return 0
   }

   // search for current feature
   let i = 0
   while (
      i < features.length - 1 &&
      percentage > features[i].properties.percTimeEnd
   ) {
      i++
   }

   if (i === features.length - 1) {
      return 1
   }

   const props = features[i].properties
   if (percentage >= props.percTimeStart) {
      // we are in that location
      return props.percDistance
   } else if (i === 0) {
      // it's the starting point
      return 0
   } else {
      // we are between locations
      const previousProps = features[i - 1].properties
      const timeRemaining =
         (percentage - previousProps.percTimeEnd) /
         (props.percTimeStart - previousProps.percTimeEnd)
      const endDist = props.percDistance
      return (
         previousProps.percDistance +
         (endDist - previousProps.percDistance) * timeRemaining
      )
   }
}

interface Props {
   data: Promise<MapDetailResult>
   height?: number
}

export const OnalyticsDynamicMap = ({ data, height }: Props): JSX.Element => {
   const { data: newData, error, isPending } = useAsync({ promise: data })
   const [rect, setRect] = useState(new DOMRect())
   const [svg, setSvg] = useState<Selection<SVGSVGElement, any, any, any>>()
   const [map, setMap] = useState<mapboxgl.Map>()

   const [dateInterval, setDateInterval] = useState<DateInterval>()
   const [timePercentage, setTimePercentage] = useState<number>(0)

   const [hasData, setHasData] = useState<boolean>(false)

   // initialize the map
   const mapRef = useCallback((node) => {
      if (node === null) {
         return
      }
      const rect = node.getBoundingClientRect()
      setRect(rect)

      mapboxgl.accessToken =
         'pk.eyJ1IjoibnVub2xvcGVzb25hbHl0aWNzIiwiYSI6ImNrMWtsa2N1ODBjamUzZHBidHl3bGdtZmgifQ._1pol1biXvHg07uyyiFVOA'
      const m = new mapboxgl.Map({
         container: node,
         style: 'mapbox://styles/mapbox/light-v10',
         zoom: 6,
         minZoom: 3,
         maxZoom: 18,
         center: [-8.2, 39.4],
         attributionControl: false,
      }).addControl(new mapboxgl.NavigationControl())

      setMap(m)
      const cont = m.getCanvasContainer()

      setSvg(select(cont).append('svg'))
   }, [])

   // redraw lines and point when needed
   useEffect(() => {
      if (svg === undefined || newData === undefined || error !== undefined) {
         return
      }
      const linePath = svg.selectAll('.lineConnect')
      if (linePath === null) {
         return
      }
      const node = linePath.node() as SVGPathElement
      if (node === null) {
         return
      }

      const distPerc = convertTimeToDistance(newData.course, timePercentage)

      const l = node.getTotalLength()
      const p = node.getPointAtLength(distPerc * l)

      const marker = select('#marker')
      //Move the marker to that point
      marker.attr('transform', 'translate(' + p.x + ',' + p.y + ')') //move marker

      const interpolate = d3InterpolateString('0,' + l, l + ',' + l)

      linePath.attr('stroke-dasharray', interpolate(distPerc))
   }, [timePercentage, svg, newData, error])

   // reset everything when data changes
   useEffect(() => {
      if (svg !== undefined) {
         svg.selectAll('.lineConnect').remove()
         svg.selectAll('circle').remove()
         svg.selectAll('#marker').remove()
      }
   }, [newData, svg])

   useEffect(() => {
      if (svg === undefined || isPending) {
         return
      }

      const tip = d3tip()
      tip.direction('s')

      tip.attr('class', 'd3-tip').html((EVENT: any, d: any) => {
         return (
            '<a href="' +
            process.env.PUBLIC_URL +
            '/poi/' +
            d.properties.id +
            '/"">' +
            d.properties.name +
            '</a><br/>'
         )
      })

      svg.style('position', 'absolute')
         .style('top', 0)
         .style('left', 0)
         .attr('width', rect.width)
         .attr('height', rect.height)
         .style('pointer-events', 'none')

      svg.call(tip)

      tip.visible = false
      tip.objId = ''

      //Project any point to map's current state
      function projectPoint(lon: number, lat: number) {
         if (map === undefined) {
            return
         }
         const point = map.project(new mapboxgl.LngLat(lon, lat))
         // @ts-ignore
         this.stream.point(point.x, point.y)
      }

      const transform = geoTransform({ point: projectPoint })
      const path = geoPath().projection(transform)

      if (
         newData === undefined ||
         newData.course.features == null ||
         newData.course.features.length <= 0
      ) {
         return
      }

      const bbox = calcBBox(newData.course)
      const featuresData = newData.course.features
      setHasData(featuresData.length > 1)

      const dInterval = {
         minDate: featuresData[0].properties.arrival,
         maxDate: featuresData[featuresData.length - 1].properties.departure,
      }
      setDateInterval(dInterval)

      const toLine = d3Line()
         .curve(curveLinear)
         .x(function (d) {
            return applyLatLngToLayer(d)[0]
         })
         .y(function (d) {
            return applyLatLngToLayer(d)[1]
         })

      function applyLatLngToLayer(d: any) {
         if (map === undefined) {
            return [100, 100]
         }
         return path.centroid(d)
      }

      // here is the line between points
      const linePath = svg
         .selectAll('.lineConnect')
         .data([featuresData])
         .enter()
         .append('path')
         .attr('stroke', '#edc13f')
         .attr('fill', 'none')
         .attr('class', 'lineConnect')

      if (map === undefined) {
         return
      }

      const reset = () => {
         // @ts-ignore
         linePath.attr('d', toLine)
         const poisB = svg.selectAll('.waypoint').data(newData.pois.features)
         poisB
            .enter()
            .append('circle')
            //@ts-ignore
            .merge(poisB)
            .attr('transform', (d) => {
               return 'translate(' + path.centroid(d) + ')'
            })
            .attr('r', 5)
            .attr('class', function (d) {
               return 'waypoint c' + d.properties.id
            })
            .attr('fill', (d) => {
               return getPlaceColor(d.properties.category, 0.8)
            })
            .style('pointer-events', 'fill')
            .style('cursor', 'pointer')
            .on('click', (event, d) => {
               let objId = ''
               if (d.properties.id) {
                  objId = 'C' + d.properties.id
               }
               if (tip.visible && objId === tip.objId) {
                  tip.hide(event, d)
                  tip.visible = false
               } else {
                  tip.objId = objId
                  tip.show(event, d)
                  tip.visible = true
               }
               event.stopPropagation()
            })

         tip.hide()
         tip.visible = false

         const distPerc = convertTimeToDistance(newData.course, timePercentage)
         const node = linePath.node() as SVGPathElement

         const l = node.getTotalLength()
         const p = node.getPointAtLength(distPerc * l)

         // This will be our traveling circle
         const marker = svg.selectAll('#marker').data([p])
         marker
            .enter()
            .append('circle')
            //@ts-ignore
            .merge(marker)
            .attr('r', 3)
            .attr('fill', '#31324e')
            .attr('id', 'marker')
            .attr('class', 'travelMarker')
            //Move the marker to that point
            .attr('transform', (p) => {
               return 'translate(' + p.x + ',' + p.y + ')'
            }) //move marker
      }

      map.on('viewreset', reset)
      map.on('move', reset)
      map.on('moveend', reset)
      map.on('click', () => {
         tip.hide()
         tip.visible = false
      })

      if (bbox !== null) {
         map.fitBounds(bbox, {
            padding: { top: 15, bottom: 20, left: 15, right: 15 },
         })
      }

      // this puts stuff on the map!
      reset()

      return () => {
         tip.hide()
         tip.visible = false
         // TODO: cleanup
      }
   }, [newData, svg, map, rect.height, rect.width, isPending, timePercentage])

   const px_height = height === undefined ? '600px' : `${height}px`

   const styles = { height: px_height, width: '100%' }
   return (
      <div>
         <TimeSlider
            disabled={!hasData}
            dateInterval={dateInterval}
            handleProgressChange={setTimePercentage}
         />
         <div style={styles} ref={mapRef} />
      </div>
   )
}
