import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { PrivateRouteWithLayout, RouteWithLayout } from './components'
import { Bare as BareLayout } from './layouts'

import {
   InventoryShort as InventoryShortView,
   KegDetail as KegDetailView,
   KegMovement as KegMovementView,
   Kegs as KegsView,
   Login as LoginView,
   Logistics as LogisticsView,
   LogisticsShort as LogisticsShortView,
   Overview as OverviewView,
   POIDetail as POIDetailView,
   POIList as POIListView,
   UserCreate as UserCreateView,
   UserDetail as UserDetailView,
   UserEdit as UserEditView,
   UserList as UserListView,
} from './views'
import { Report } from './views/Reports/Report'
import { DevComponent } from './dev/dev'

const Routes = (): JSX.Element => {
   return (
      <Switch>
         <Redirect exact from="/" to="/kegs" />
         <RouteWithLayout
            component={LoginView}
            exact
            layout={BareLayout}
            path="/login"
         />
         <PrivateRouteWithLayout exact path="/overview">
            <OverviewView />
         </PrivateRouteWithLayout>
         <PrivateRouteWithLayout exact path="/kegs">
            <KegsView />
         </PrivateRouteWithLayout>
         <PrivateRouteWithLayout exact path="/logistics_repeat">
            <LogisticsView />
         </PrivateRouteWithLayout>
         <PrivateRouteWithLayout exact path="/logistics">
            <LogisticsShortView />
         </PrivateRouteWithLayout>
         <PrivateRouteWithLayout exact path="/inventory">
            <InventoryShortView />
         </PrivateRouteWithLayout>
         <PrivateRouteWithLayout exact path="/keg/:kegId">
            <KegDetailView />
         </PrivateRouteWithLayout>
         <PrivateRouteWithLayout exact path="/keg_mov/:kegId">
            <KegMovementView />
         </PrivateRouteWithLayout>
         <PrivateRouteWithLayout exact path="/pois">
            <POIListView />
         </PrivateRouteWithLayout>
         <PrivateRouteWithLayout exact path="/poi/:poiId">
            <POIDetailView />
         </PrivateRouteWithLayout>
         <PrivateRouteWithLayout exact path="/users/">
            <UserListView />
         </PrivateRouteWithLayout>
         <PrivateRouteWithLayout exact path="/user/new">
            <UserCreateView />
         </PrivateRouteWithLayout>
         <PrivateRouteWithLayout exact path="/user/:userId">
            <UserDetailView />
         </PrivateRouteWithLayout>
         <PrivateRouteWithLayout exact path="/settings">
            <UserEditView />
         </PrivateRouteWithLayout>
         <PrivateRouteWithLayout exact path="/reports">
            <Report />
         </PrivateRouteWithLayout>

         <PrivateRouteWithLayout exact path="/dev">
            <DevComponent />
         </PrivateRouteWithLayout>
      </Switch>
   )
}

export default Routes
