import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { Sparkline } from '../../charts/line'
import { POICategory } from '../../../../util/types/types'
import { format } from 'd3-format'

const useStyles = makeStyles({
   root: {
      height: '100%',
   },
   title: {
      fontWeight: 700,
   },
   value: {
      position: 'absolute',
      zIndex: 10,
      marginLeft: '6rem',
      marginTop: '1rem',
      fontSize: '2rem',
      color: '#444',
   },
})

interface Props {
   title: string
   type: POICategory
   data: any
   unit?: string
   height?: number
}

const TypeCard = ({ title, type, data, unit, height }: Props): JSX.Element => {
   const [realValue, setRealValue] = useState<string>()
   const [isLoading, setIsLoading] = useState(true)

   const classes = useStyles()

   const displayUnit = unit ? unit : ''
   const compHeight = height ? height : 80

   useEffect(() => {
      setIsLoading(true)
      data.then((d: any) => {
         setIsLoading(false)
         const value =
            d.length === 0
               ? 'N/A'
               : format('.2s')(d.reduce((a: any, v: any) => a + v.value, 0))
         setRealValue(value)
      })
   }, [data])

   return (
      <Paper className={classes.root}>
         <CardContent>
            <Typography
               className={classes.title}
               color="textSecondary"
               gutterBottom
               variant="body1"
            >
               {title}
            </Typography>
            <Typography variant="h3" className={classes.value}>
               {realValue} {displayUnit}
            </Typography>
            {(isLoading && <CircularProgress />) || (
               <Sparkline
                  category={type}
                  height={compHeight}
                  fill={true}
                  inline={false}
                  data={data}
               />
            )}
         </CardContent>
      </Paper>
   )
}

export default TypeCard
