import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/styles/makeStyles'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
   root: {
      //@ts-ignore
      padding: theme.spacing(4),
   },
}))

const Footer = (props: { [x: string]: any; className: any }): JSX.Element => {
   const { className, ...rest } = props

   const classes = useStyles()

   return (
      <div {...rest} className={clsx(classes.root, className)}>
         <Typography variant="body1">
            &copy;{' '}2022{' '}
            <Link component="a" href="https://www.katchassets.com/" target="_blank">
               Katch Assets.
            </Link>
         </Typography>
      </div>
   )
}

Footer.propTypes = {
   className: PropTypes.string,
}

export default Footer
