import { IntlConfig, IntlShape } from 'react-intl'

export const intlConfigFromPrefs = (
   prefs?: Record<string, string>
): IntlConfig => {
   if (prefs === undefined) {
      return {
         locale: navigator.language,
         messages: {
            measurement_system: measurementSystemFromLocale(navigator.language),
         },
      }
   }

   const locale = prefs.locale ?? navigator.language

   //const locale = 'en-US'

   const result: IntlConfig = {
      locale: locale,
      messages: { measurement_system: measurementSystemFromLocale(locale) },
   }

   if (prefs.timeZone === undefined) {
      result.timeZone = prefs.timeZone
   }

   return result
}

export const measurementSystemFromLocale = (loc: string): string => {
   const split = loc.split('-', 3)

   if (split.length >= 2 && split[1] === 'US') {
      return 'imperial'
   }
   return 'metric'
}

const isImperial = (intl: IntlShape): boolean => {
   return false
   // TODO: make it work with imperial again
   //return intl.formatMessage({ id: 'measurement_system' }) === 'imperial'
}

type funcNumber = (n: number) => number

export const numConverterFromCelsius = (intl: IntlShape): funcNumber => {
   if (isImperial(intl)) {
      return (n: number) => n * (9 / 5) + 32
   }
   return (n: number) => n
}

type funcNumberString = (n: number) => string

export const temperatureFormatterCelsius = (
   intl: IntlShape
): funcNumberString => {
   if (isImperial(intl)) {
      return (n: number) =>
         intl.formatNumber(n * (9 / 5) + 32, {
            style: 'unit',
            unit: 'fahrenheit',
         })
   }
   return (n: number) =>
      intl.formatNumber(n, { style: 'unit', unit: 'celsius' })
}

export const temperatureFormatter = (intl: IntlShape): funcNumberString => {
   if (isImperial(intl)) {
      return (n: number) =>
         intl.formatNumber(n, { style: 'unit', unit: 'fahrenheit' })
   }
   return (n: number) =>
      intl.formatNumber(n, { style: 'unit', unit: 'celsius' })
}

export const getTemperatureZero = (intl: IntlShape): number => {
   return isImperial(intl) ? 32 : 0
}
