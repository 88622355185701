import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import createStyles from '@material-ui/core/styles/createStyles'
import Typography from '@material-ui/core/Typography'
import { SmallMap } from '../../components/charts/map/SmallMap'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles/createTheme'
import { Sparkline } from '../../components/charts/line'
import { POICategory } from '../../../util/types/types'
import { SparkData } from '../../components/charts/line/SparkLine/Sparkline'
import { temperatureFormatter } from '../../../util/i18n/units'
import { useIntl } from 'react-intl'
import {POIData} from "../../../data/service/pois/POIDetailService"

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      temp: {
         fontSize: theme.typography.fontSize * 3,
      },
      warnIcon: {
         color: theme.palette.warning.main,
      },
      fullHeight: {
         height: '100%',
      },
   })
)

interface Props {
   poiData?: POIData
   temperatureData?: SparkData
}

export const LatestData = ({
   poiData,
   temperatureData,
}: Props): JSX.Element => {
   const classes = useStyles()
   const intl = useIntl()

   const [curTemperature, setCurTemperature] = useState<number | undefined>()

   useEffect(() => {
      if (temperatureData === undefined) {
         return
      }
      temperatureData.then((v) => {
         if (v.length > 0) {
            const lastValue = v[v.length - 1].value
            setCurTemperature(Math.round(lastValue * 10) / 10)
         }
      })
   })

   return (
      <Grid container spacing={2}>
         <Grid item xs={4}>
            <Card className={classes.fullHeight}>
               <CardHeader title="Current inventory" />
               <CardContent>
                  <Grid container spacing={1}>
                     <Grid item xs={4}>
                        Full:
                     </Grid>
                     <Grid item xs={8}>
                        {poiData?.stock.full} kegs
                     </Grid>
                     <Grid item xs={4}>
                        Empty:
                     </Grid>
                     <Grid item xs={8}>
                        {poiData?.stock.empty} kegs
                     </Grid>
                  </Grid>
               </CardContent>
            </Card>
         </Grid>
         <Grid item xs={4}>
            <Card className={classes.fullHeight}>
               <CardHeader title="Temperatures" />
               <CardContent>
                  <Typography className={classes.temp}>
                     {curTemperature !== undefined
                        ? temperatureFormatter(intl)(curTemperature)
                        : ''}
                     {temperatureData !== undefined ? (
                        <Sparkline
                           category={POICategory.Candidate}
                           height={50}
                           fill={true}
                           inline={false}
                           data={temperatureData}
                        />
                     ) : null}
                  </Typography>
               </CardContent>
            </Card>
         </Grid>
         <Grid item xs={4}>
            {poiData && poiData.coordinates && (
               <SmallMap
                  location={poiData.coordinates}
                  height={200}
                  width={350}
                  description={poiData.name}
               />
            )}
         </Grid>
      </Grid>
   )
}
