import { POICategory } from '../../../util/types/types'
import axios, { AxiosResponse } from 'axios'
import config from 'react-global-configuration'

export interface POIUpdateServiceParams {
   locationId: number
   name: string
   category: POICategory
}

export const POIUpdateService = ({
   locationId,
   name,
   category,
}: POIUpdateServiceParams): Promise<AxiosResponse<boolean>> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/poi/' + locationId

   return axios.post<boolean>(
      api_url.href,
      { name: name, category: category },
      { headers: { 'Content-Type': 'application/json' }, withCredentials: true }
   )
}
