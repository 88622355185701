import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import * as colors from '@material-ui/core/colors'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
   verticalfull: {
      height: '100%',
      backgroundColor: colors.grey[50],
   },
   greyed: {
      color: colors.grey[400],
      fontWeight: 'bold',
      fontSize: 25,
   },
})

export const NoData = (): JSX.Element => {
   const classes = useStyles()
   return (
      <Grid
         container
         className={classes.verticalfull}
         direction="row"
         justify="center"
         alignItems="center"
      >
         <Grid item>
            <Typography className={classes.greyed}>
               No data to display
            </Typography>
         </Grid>
      </Grid>
   )
}
