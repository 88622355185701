import React from 'react'
import { TypeCard } from '../../components/cards'
import { MovementDirection, POICategory } from '../../../util/types/types'
import { MovementService } from '../../../data/service'
import { DateTime } from 'luxon'

interface Props {
   category: POICategory
   direction: MovementDirection
   endDate?: DateTime
   period?: number
}

const MovementTypeCard = ({
   category,
   direction,
   endDate,
   period,
}: Props): JSX.Element => {
   let title = ''
   switch (direction) {
      case MovementDirection.In:
         title = 'Inbound'
         break
      case MovementDirection.Out:
         title = 'Outbound'
         break
   }

   const data = MovementService({
      category: category,
      direction: direction,
      endDate: endDate,
      period: period,
   })

   return (
      <TypeCard
         title={title}
         type={category}
         data={data}
         unit={''}
         height={60}
      />
   )
}

export default MovementTypeCard
