import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'

interface Props {
   tooltip: string
}

export const InfoTooltip = ({ tooltip }: Props): JSX.Element => {
   return (
      <Tooltip title={tooltip}>
         <InfoIcon fontSize="small" color="action" />
      </Tooltip>
   )
}
