import { format } from 'd3-format'

const formatterLow = format(',d')
const formatterHigh = format(',.3s')

export const largeNumberFormat = (n: number): string => {
   if (n < 10000) {
      return formatterLow(n)
   }
   return formatterHigh(n)
}

const formatterUnits = format('.1s')
const formatterTens = format('.2s')

export const shortSpaceFormat = (n: number): string => {
   if (n < 10) {
      return formatterUnits(n)
   } else if (n < 100) {
      return formatterTens(n)
   }

   return formatterHigh(n)
}
