import { Set } from 'immutable'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles/createTheme'

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      containedPrimary: {
         backgroundColor: theme.palette.primary.light,
      },
   })
)

export type MultiChoiceOption<T> = {
   label: string
   value: T
}

type Props<T> = {
   options: MultiChoiceOption<T>[]
   selected: Set<T>
   onChange: (value: Set<T>) => void
}

export const MultiChoice = <T extends string>({
   options,
   selected,
   onChange,
}: Props<T>): JSX.Element => {
   const classes = useStyles()

   const handleClick = (clicked: T) => {
      return () => {
         let v: Set<T>
         if (selected.has(clicked)) {
            v = selected.delete(clicked)
         } else {
            v = selected.add(clicked)
         }
         onChange(v)
      }
   }

   return (
      <>
         <ButtonGroup
            orientation="horizontal"
            color="primary"
            size="small"
            aria-label="vertical contained primary button group"
            variant="contained"
            disableElevation
         >
            {options.map((o) => {
               return (
                  <Button
                     classes={{
                        containedPrimary: classes.containedPrimary,
                     }}
                     variant={selected.has(o.value) ? 'contained' : 'outlined'}
                     onClick={handleClick(o.value)}
                     key={o.value}
                  >
                     {o.label}
                  </Button>
               )
            })}
         </ButtonGroup>
      </>
   )
}
