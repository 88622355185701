import config from 'react-global-configuration'
import { addPaginationToURL, Pagination } from '../../../util/types/serviceUtil'
import { json } from 'd3-fetch'
import { DateTime } from 'luxon'
import { Order, POICategory } from '../../../util/types/types'
import { CountedReply } from '../util'

export interface POIOrder {
   field: POIListField
   direction: NonNullable<Order>
}

export interface POIListParams {
   pag: Pagination
   categories: POICategory[]
   nameFilter: string
   order: POIOrder | undefined
}

export interface POIListItem {
   id: number
   name: string
   category: POICategory
   full: number
   empty: number
   min_arrival?: DateTime
}

export type POIList = CountedReply<POIListItem>
export type POIListField =
   | 'name'
   | 'full_kegs'
   | 'empty_kegs'
   | 'inventory'
   | 'min_arrival'
export const POIListService = ({
   pag,
   categories,
   nameFilter,
   order,
}: POIListParams): Promise<POIList | undefined> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/poi/'

   addPaginationToURL(api_url, pag)

   if (categories.length > 0)
      api_url.searchParams.append('categories', categories.join(','))
   if (nameFilter !== undefined)
      api_url.searchParams.append('name_filter', nameFilter)

   if (order !== undefined) {
      switch (order.field) {
         case 'name':
            api_url.searchParams.append('sort_name', order.direction.toString())
            break
         case 'empty_kegs':
            api_url.searchParams.append(
               'sort_empty_kegs',
               order.direction.toString()
            )
            break
         case 'full_kegs':
            api_url.searchParams.append(
               'sort_full_kegs',
               order.direction.toString()
            )
            break
         case 'inventory':
            api_url.searchParams.append(
               'sort_inventory',
               order.direction.toString()
            )
            break
         case 'min_arrival':
            api_url.searchParams.append(
               'sort_min_arrival',
               order.direction.toString()
            )
      }
   }

   return json<POIList>(api_url.href, { credentials: 'include' }).then((r) => {
      if (r === undefined) return undefined
      return {
         count: r.count,
         //@ts-ignore
         list: r.pois.map((v) => {
            return {
               id: v.id,
               name: v.name,
               category: v.category,
               full: v.full,
               empty: v.empty,
               min_arrival:
                  v.min_arrival === null || v.min_arrival === undefined
                     ? undefined
                     : DateTime.fromISO(v.min_arrival),
            }
         }),
      }
   })
}
