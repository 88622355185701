import React, { useState } from 'react'
import { Page, Section, SectionContent, SectionTitle } from '../../components'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { DatePicker } from '@material-ui/pickers/DatePicker'
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider'
import config from 'react-global-configuration'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import Button from '@material-ui/core/Button'
import LuxonUtils from '@date-io/luxon'
import { DateTime } from 'luxon'

const half_rotation_url = (date: DateTime) => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/report/half_rotation/'
   api_url.searchParams.append('since', date.toFormat('yyyy-MM-dd'))
   return api_url.href
}

export const Report = (): JSX.Element => {
   const [date, setDate] = useState(DateTime.now().minus({ month: 3 }))

   const handleDateChange = (date: MaterialUiPickersDate) => {
      if (date === null) return
      setDate(date)
   }

   return (
      <Page>
         <Section>
            <SectionTitle>Reports</SectionTitle>
            <SectionContent>
               <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  justify={'space-between'}
               >
                  <Grid item>
                     <Typography variant="subtitle1">
                        Half rotation report since&nbsp;
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                           <DatePicker
                              value={date}
                              onChange={handleDateChange}
                              format={'D'}
                              allowKeyboardControl={false}
                              minDate={DateTime.now().minus({ year: 1 })}
                              maxDate={DateTime.now()}
                           />
                           &nbsp;
                           <Button
                              color="primary"
                              variant="contained"
                              href={half_rotation_url(date)}
                              target="_blank"
                           >
                              Download
                           </Button>
                        </MuiPickersUtilsProvider>
                     </Typography>
                  </Grid>
               </Grid>
            </SectionContent>
         </Section>
      </Page>
   )
}
