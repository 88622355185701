/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { forwardRef } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/styles/makeStyles'
import Button from '@material-ui/core/Button'
import * as colors from '@material-ui/core/colors'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

const useStyles = makeStyles((theme) => ({
   root: {},
   item: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
   },
   button: {
      color: colors.blueGrey[800],
      padding: '10px 8px',
      justifyContent: 'flex-start',
      textTransform: 'none',
      letterSpacing: 0,
      width: '100%',
      //@ts-ignore
      fontWeight: theme.typography.fontWeightMedium,
   },
   icon: {
      //@ts-ignore
      color: theme.palette.icon,
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      //@ts-ignore
      marginRight: theme.spacing(1),
   },
   active: {
      //@ts-ignore
      color: theme.palette.primary.main,
      //@ts-ignore
      fontWeight: theme.typography.fontWeightMedium,
      '& $icon': {
         //@ts-ignore
         color: theme.palette.primary.main,
      },
   },
}))

// @ts-ignore
const CustomRouterLink = forwardRef((props, ref) => (
   <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
   </div>
))

//@ts-ignore
const SidebarNav: Element = (props) => {
   const { pages, className, ...rest } = props

   const classes = useStyles()

   return (
      <List {...rest} className={clsx(classes.root, className)}>
         {pages.map((page) => (
            <ListItem className={classes.item} disableGutters key={page.title}>
               <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                  to={page.href}
               >
                  <div className={classes.icon}>{page.icon}</div>
                  {page.title}
               </Button>
            </ListItem>
         ))}
      </List>
   )
}

SidebarNav.propTypes = {
   className: PropTypes.string,
   pages: PropTypes.array.isRequired,
}

export default SidebarNav
