import React, { useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { Link as LinkRouter, useRouteMatch } from 'react-router-dom'
import createStyles from '@material-ui/core/styles/createStyles'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { MovementSankeyService } from '../../data/service/movements/MovementService'
import { OnalyticsSankeyD3 } from '../components/charts/sankey/OnalyticsSankeyD3'
import { InventoryPOIService } from '../../data/service/inventory/InventoryService'
import MultiFull from '../components/charts/line/MultiFull'
import {
   POIData,
   POIDetailService,
} from '../../data/service/pois/POIDetailService'
import { POITemperatureService } from '../../data/service/pois/POITemperatureService'
import { POIHistoricalAssetListService } from '../../data/service/pois/POIAssetListService'
import { POIUpdateService } from '../../data/service/pois/POIUpdateService'
import { AssetListComponent } from './POIDetail/AssetList'
import TimeSeriesLine from '../components/charts/line/TimeSeriesLine'
import { TemperatureListItem } from '../../data/service/kegs/KegsService'
import { LocationChip } from '../../components/Location/LocationChip'
import EditIcon from '@material-ui/icons/Edit'
import { POIEdit, POIEditData } from './POIDetail/POIEdit'
import {
   Page,
   PageContent,
   PageHeader,
   Section,
   SectionContent,
   SectionTitle,
} from '../../components'
import { LatestData } from './POIDetail/LatestData'
import Alert from '@material-ui/lab/Alert'
import {
   DateInterval,
   DateIntervalFilter,
} from '../../components/Filter/Date/DateIntervalFilter'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles/createTheme'
import { useIntl } from 'react-intl'
import {
   getTemperatureZero,
   numConverterFromCelsius,
} from '../../util/i18n/units'
import config from 'react-global-configuration'
import { AuthFuncContext } from '../../App'
import { handleRequestErrors } from '../../util/auth/authHelper'
import { DateTime } from 'luxon'

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      alert: {
         marginBottom: theme.spacing(3),
      },
   })
)

type TParams = { poiId: string }

export const POIDetail = (): JSX.Element => {
   const intl = useIntl()
   const match = useRouteMatch<TParams>()
   const [sankeyData, setSankeyData] = useState<Promise<any>>()
   const [inventoryData, setInventoryData] = useState<Promise<any>>()
   const [poiData, setPoiData] = useState<POIData>()
   const [poiTemperature, setPoiTemperature] =
      useState<Promise<TemperatureListItem[]>>()
   const [editing, setEditing] = useState(false)
   const [noData, setNoData] = React.useState(false)
   const [interval, setInterval] = useState<DateInterval>({
      minDate: DateTime.now().plus({ month: -1 }),
      maxDate: DateTime.now(),
   })
   const [validInterval, setValidInterval] = useState<DateInterval>({
      minDate: DateTime.now().plus({ month: -1 }),
      maxDate: DateTime.now(),
   })
   const authContext = useContext(AuthFuncContext)

   const classes = useStyles()

   useEffect(() => {
      POIDetailService(+match.params.poiId)
         .then((d) => {
            if (d === undefined) return
            setPoiData(d)
            if (d.minDate === null) {
               setNoData(true)
            }
            setNoData(false)
            setValidInterval({
               minDate: d.minDate,
               maxDate: DateTime.now(),
            })
         })
         .catch(handleRequestErrors(authContext))
   }, [match.params.poiId, authContext])

   useEffect(() => {
      const filter = {
         locationId: +match.params.poiId,
         interval: interval,
      }
      setSankeyData(
         MovementSankeyService(filter).catch(handleRequestErrors(authContext))
      )
      setInventoryData(
         InventoryPOIService(filter).catch(handleRequestErrors(authContext))
      )
      setPoiTemperature(
         POITemperatureService(filter)
            .then((d) => {
               const converter = numConverterFromCelsius(intl)
               return d.map((v) => {
                  return { timestamp: v.timestamp, value: converter(v.value) }
               })
            })
            .catch(handleRequestErrors(authContext))
            .then((v) => {
               if (v === undefined) {
                  return []
               }
               return v
            })
      )
   }, [match.params.poiId, interval, intl, authContext])

   const editClick = () => {
      setEditing(true)
   }

   const editOkHandler = (newValues: POIEditData) => {
      POIUpdateService({
         locationId: poiData?.id ?? -1,
         name: newValues.name,
         category: newValues.category,
      }).then((res) => {
         if (res.status !== 200) {
            return
         }
         const newData = Object.assign({}, poiData) ?? poiData
         newData.name = newValues.name
         newData.category = newValues.category
         setPoiData(newData)
         setEditing(false)
      })
      return true
   }

   const editCancelHandler = () => {
      setEditing(false)
   }

   return (
      <Page>
         <PageHeader>
            <Grid
               container
               justify="flex-start"
               spacing={1}
               alignItems="center"
            >
               <Grid item>
                  <IconButton component={LinkRouter} to="/pois">
                     {' '}
                     <ArrowBackIcon />
                  </IconButton>
               </Grid>
               <Grid item>
                  <Typography variant="h2">{poiData?.name}</Typography>
               </Grid>
               <Grid item>
                  <LocationChip category={poiData?.category} />
               </Grid>
               <Grid item>
                  <IconButton onClick={editClick}>
                     <EditIcon />
                  </IconButton>
               </Grid>
               {poiData && (
                  <POIEdit
                     open={editing}
                     poiData={{
                        name: poiData.name,
                        category: poiData.category,
                     }}
                     okHandler={editOkHandler}
                     cancelHandler={editCancelHandler}
                  />
               )}
            </Grid>
         </PageHeader>
         <PageContent>
            <Section paperless>
               <SectionTitle>Latest data</SectionTitle>
               <SectionContent>
                  <LatestData
                     poiData={poiData}
                     temperatureData={poiTemperature}
                  />
               </SectionContent>
            </Section>
            <Section paperless>
               <SectionContent>
                  {noData && (
                     <Alert severity="warning" className={classes.alert}>
                        There is no data in the defined interval, please select
                        another interval
                     </Alert>
                  )}
                  <DateIntervalFilter
                     interval={interval}
                     validInterval={validInterval}
                     intervalChangeHandler={setInterval}
                     preText={'Show data on the period from'}
                     middleText={'to'}
                  />
               </SectionContent>
            </Section>
            <Section>
               <SectionTitle>Movements</SectionTitle>
               <SectionContent>
                  {sankeyData && <OnalyticsSankeyD3 data={sankeyData} />}
               </SectionContent>
            </Section>
            <Section>
               <SectionTitle>Temperatures</SectionTitle>
               <SectionContent>
                  {poiTemperature !== undefined && (
                     <TimeSeriesLine
                        data={poiTemperature.then((v) => {
                           return { series: v, bands: undefined }
                        })}
                        height={200}
                        yCrossValue={getTemperatureZero(intl)}
                        lowHighValues={config.get('temperature_limits')}
                     />
                  )}
               </SectionContent>
            </Section>
            <Section>
               <SectionTitle>Inventory</SectionTitle>
               <SectionContent>
                  {poiData && inventoryData && (
                     <MultiFull
                        height={300}
                        category={poiData.category}
                        data={inventoryData}
                     />
                  )}
               </SectionContent>
            </Section>
            <Section paperless>
               <SectionTitle>Kegs currently in {poiData?.name}</SectionTitle>
               <SectionContent>
                  <AssetListComponent
                     dataService={POIHistoricalAssetListService}
                     poiId={+match.params.poiId}
                     timePeriod={{
                        start: interval.minDate,
                        end: interval.maxDate,
                     }}
                  />
               </SectionContent>
            </Section>
         </PageContent>
      </Page>
   )
}
