import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import createStyles from '@material-ui/core/styles/createStyles'
import Grid from '@material-ui/core/Grid'
import {
   KegLatestData,
   KegRotationSummary,
} from '../../../data/service/kegs/KegsService'
import { BatteryLevel } from '../../components/micro/BatteryLevel'
import { NoData } from '../../../components'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles/createTheme'
import ReportProblemRounded from '@material-ui/icons/ReportProblemRounded'
import { LatestLocation } from './LatestLocation'
import { InfoTooltip } from '../../components/micro/InfoTooltip'
import { shortSpaceFormat } from '../../../util/i18n/formatters'
import { daysAgo } from '../../../util/DateTimeUtils/DateTimeUtils'

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      temp: {
         fontSize: theme.typography.fontSize * 3,
      },
      warnIcon: {
         color: theme.palette.warning.main,
      },
      fullHeight: {
         height: '100%',
      },
   })
)

interface Props {
   ld?: KegLatestData
   rotationData?: KegRotationSummary
}

export const LatestDataComponent = ({
   ld,
   rotationData,
}: Props): JSX.Element => {
   const classes = useStyles()

   return (
      <Grid container spacing={2}>
         <Grid item xs={4}>
            <Card className={classes.fullHeight}>
               <CardHeader title="Location" />
               <CardContent>
                  {ld === undefined ? (
                     <NoData />
                  ) : (
                     <LatestLocation
                        location={ld.location}
                        lastLocationTime={ld.lastLocationTime}
                        historicalLocation={ld.historicalLocation}
                     />
                  )}
               </CardContent>
            </Card>
         </Grid>
         <Grid item xs={4}>
            <Card className={classes.fullHeight}>
               <CardHeader title="Rotation" />
               <CardContent>
                  {rotationData !== undefined && (
                     <Grid container spacing={1}>
                        <Grid item xs={6}>
                           Rotations
                           <InfoTooltip tooltip="last 12 months" />:
                        </Grid>
                        <Grid item xs={6}>
                           {rotationData.count}
                        </Grid>
                        <Grid item xs={6}>
                           Avg Rotation Time:
                        </Grid>
                        <Grid item xs={6}>
                           {shortSpaceFormat(rotationData.durationHours / 24)}{' '}
                           days
                        </Grid>
                        <Grid item xs={6}>
                           Current Rotation:
                        </Grid>
                        <Grid item xs={6}>
                           {rotationData.latestRotationStart !== undefined
                              ? daysAgo(rotationData.latestRotationStart)
                              : '-'}{' '}
                           days
                        </Grid>
                     </Grid>
                  )}
               </CardContent>
            </Card>
         </Grid>
         <Grid item xs={4}>
            <Card className={classes.fullHeight}>
               <CardHeader title="General" />
               <CardContent>
                  <Grid container spacing={1}>
                     <Grid item xs={6}>
                        Battery:
                     </Grid>
                     <Grid item xs={6}>
                        {ld !== undefined && (
                           <BatteryLevel voltage={ld.battery} />
                        )}
                     </Grid>
                     <Grid item xs={6}>
                        Last report:
                     </Grid>
                     <Grid item xs={6}>
                        {ld !== undefined ? (
                           <div>
                              {ld.lastCommunication.toRelative({
                                 unit: 'days',
                              })}
                              {daysAgo(ld.lastCommunication) > 30 ? (
                                 <ReportProblemRounded
                                    className={classes.warnIcon}
                                 />
                              ) : null}
                           </div>
                        ) : null}
                     </Grid>
                     <Grid item xs={6}>
                        Status:
                     </Grid>
                     <Grid item xs={6}>
                        {ld?.status ? ld.status : ''}
                     </Grid>
                  </Grid>
               </CardContent>
            </Card>
         </Grid>
      </Grid>
   )
}
