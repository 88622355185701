export enum POICategory {
   Brewery = 'BRW',
   DistributionCenter = 'DC',
   Wholesaler = 'WLS',
   PointOfConsumption = 'POC',
   Candidate = 'CAND',
   RejectedCandidate = 'RCAND',
   Unknown = 'UNK',
}

export const getPOICategoryName = (p?: POICategory): string => {
   switch (p) {
      case POICategory.Brewery:
         return 'Brewery'
      case POICategory.DistributionCenter:
         return 'Distribution C.'
      case POICategory.Wholesaler:
         return 'Wholesaler'
      case POICategory.PointOfConsumption:
         return 'Point Of Cons.'
      case POICategory.Candidate:
         return 'Candidate'
      case POICategory.RejectedCandidate:
         return 'Rejected Candidate'
      default:
         return ''
   }
}

export const getCategoryFromString = (s: string): POICategory => {
   switch (s) {
      case 'BRW':
         return POICategory.Brewery
      case 'DC':
         return POICategory.DistributionCenter
      case 'WLS':
         return POICategory.Wholesaler
      case 'POC':
         return POICategory.PointOfConsumption
      case 'CAND':
         return POICategory.Candidate
      case 'RCAND':
         return POICategory.RejectedCandidate
   }

   return POICategory.Unknown
}

export enum MovementDirection {
   In = 'IN',
   Out = 'OUT',
}

export enum FillLevel {
   All,
   Full,
   Empty,
   Unknown,
}

export const getFillLevelFromString = (level: string): FillLevel => {
   switch (level) {
      case 'FULL':
         return FillLevel.Full
      case 'EMPTY':
         return FillLevel.Empty
      case 'UNK':
         return FillLevel.Unknown
   }
   return FillLevel.Unknown
}

export const getFillLevelName = (l: FillLevel): string => {
   switch (l) {
      case FillLevel.All:
         return 'ALL'
      case FillLevel.Full:
         return 'FULL'
      case FillLevel.Empty:
         return 'EMPTY'
      case FillLevel.Unknown:
         return 'UNK'
   }
}

// battery consts
export const BATTERY_MIN = 3.2
export const BATTERY_LOW = 3.8
export const BATTERY_HIGH = 4.0
export const BATTERY_MAX = 5.5

// query order const
export type Order = 'asc' | 'desc' | undefined

export const getNextOrder = (current: Order): Order => {
   switch (current) {
      case 'asc':
         return 'desc'
      case 'desc':
         return 'asc'
      default:
         return 'asc'
   }
}
