import TimeSeriesLine from '../views/components/charts/line/TimeSeriesLine'
import {
   TimeSeriesData,
   TimeSeriesDataPoint,
   TimeSeriesDataPromise,
} from '../views/components/charts/line/TimeSeriesLine/TimeSeriesLine'
import { DateTime } from 'luxon'
import { Page, PageContent } from '../components'

const generateData = (): TimeSeriesDataPoint[] => {
   const values = [10, 40, -10, NaN, NaN, 15, 20, NaN, 10, NaN]

   let curTs = DateTime.now()
   const res: TimeSeriesDataPoint[] = []

   for (let i = 0; i < values.length; i++) {
      res.push({ timestamp: curTs, value: values[i] })
      curTs = curTs.plus({ days: 1 })
   }

   return res
}
const data: TimeSeriesData = {
   series: generateData(),
}

const timeseries: TimeSeriesDataPromise = new Promise<TimeSeriesData>(
   (resolve, reject) => {
      resolve(data)
   }
)

export const DevComponent = (): JSX.Element => {
   console.log('testing')
   return (
      <Page>
         <PageContent>
            <TimeSeriesLine height={200} width={800} data={timeseries} />
         </PageContent>
      </Page>
   )
}
