import { POICategory } from '../../../util/types/types'
import config from 'react-global-configuration'
import { csv, json } from 'd3-fetch'
import { DateInterval } from '../../../components/Filter/Date/DateIntervalFilter'
import {
   fillPeriodURL,
   periodFilterFromDateInterval,
} from '../../../util/DateTimeUtils/DateTimeUtils'
import { DateTime } from 'luxon'

export interface InventoryBaseParams {
   endDate?: DateTime
   period?: number // number of days
}

const getDefault = ({ endDate, period }: InventoryBaseParams) => {
   const myPeriod = period === undefined ? 100 : period
   const myEndDate = endDate === undefined ? DateTime.now() : endDate

   return { myEndDate, myPeriod }
}

export interface InventoryParams {
   endDate?: DateTime
   period?: number // number of days
   category: POICategory
}

export interface InventoryDataPoint {
   value: number
   full_kegs: number
   empty_kegs: number
}

const InventoryService = ({
   category,
   endDate,
   period,
}: InventoryParams): Promise<InventoryDataPoint[]> => {
   const { myEndDate, myPeriod } = getDefault({ endDate, period })

   const api_url = new URL(config.get('api_url'))

   api_url.pathname += '/v1/inventory/' + category.toString() + '/'
   api_url.searchParams.append('endDate', myEndDate.toFormat('yyyy-MM-dd'))
   api_url.searchParams.append('duration', myPeriod.toString())

   return csv(api_url.href, { credentials: 'include' }).then((data) => {
      return data.map((d) => {
         return {
            value: d.value ? +d.value : 0,
            full_kegs: d.full_kegs ? +d.full_kegs : 0,
            empty_kegs: d.empty_kegs ? +d.empty_kegs : 0,
         }
      })
   })
}

// TODO: create interface for type
export const InventoryMapService = ({
   endDate,
   period,
}: InventoryBaseParams): Promise<any> => {
   const { myEndDate, myPeriod } = getDefault({ endDate, period })

   const api_url = new URL(config.get('api_url'))

   api_url.pathname += '/v1/map/inventory/'
   api_url.searchParams.append('endDate', myEndDate.toFormat('yyyy-MM-dd'))
   api_url.searchParams.append('duration', myPeriod.toString())

   return json(api_url.href, { credentials: 'include' })
}

export interface InventoryPOIParams {
   interval: DateInterval
   locationId: number
}

export interface InventoryPOIDataPoint {
   date: string
   value: number
   full_kegs: number
   empty_kegs: number
}

export const InventoryPOIService = ({
   locationId,
   interval,
}: InventoryPOIParams): Promise<InventoryPOIDataPoint[]> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/inventory/poi/' + locationId + '/'

   if (interval !== undefined) {
      fillPeriodURL(api_url, periodFilterFromDateInterval(interval))
   }

   return csv(api_url.href, { credentials: 'include' }).then((data) => {
      return data.map((d) => {
         return {
            date: d.date ?? '',
            value: d.value ? +d.value : 0,
            full_kegs: d.full_kegs ? +d.full_kegs : 0,
            empty_kegs: d.empty_kegs ? +d.empty_kegs : 0,
         }
      })
   })
}

export interface InventoryTopParams {
   endDate?: DateTime
   topCount?: number
   category: POICategory
}

export interface InventoryTopDataPoint {
   id: number
   name: string
   value: number
}

export const InventoryTopService = ({
   category,
   endDate,
   topCount,
}: InventoryTopParams): Promise<InventoryTopDataPoint[]> => {
   const myEndDate = endDate === undefined ? DateTime.now() : endDate
   const myTopCount = topCount === undefined ? 4 : topCount

   const api_url = new URL(config.get('api_url'))

   api_url.pathname += '/v1/inventory/' + category.toString() + '/top/'
   api_url.searchParams.append('endDate', myEndDate.toFormat('yyyy-MM-dd'))
   api_url.searchParams.append('top_count', myTopCount.toString())

   return csv(api_url.href, { credentials: 'include' }).then((data) => {
      return data.map((d) => {
         return {
            id: d.id ? +d.id : 0,
            name: d.name ? d.name : '',
            value: d.value ? +d.value : 0,
         }
      })
   })
}

export default InventoryService
