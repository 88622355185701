import { axisBottom, axisLeft } from 'd3-axis'
import { max as d3Max, min as d3Min } from 'd3-array'
import {
   area as d3Area,
   curveBumpX,
   curveMonotoneX,
   line as d3Line,
} from 'd3-shape'
import { scaleLinear, scaleTime } from 'd3-scale'
import { schemeTableau10 } from 'd3-scale-chromatic'
import { select as d3Select, Selection } from 'd3-selection'
import React, { useCallback, useEffect, useState } from 'react'
import './timeseries.css'
import { NoData } from '../../../../../components'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles/createTheme'
import { useAsync } from 'react-async'
import { DateTime } from 'luxon'

export interface TimeSeriesDataPoint {
   timestamp: DateTime
   value: number
}

export interface TimeSeriesBandDataPoint {
   startTime: DateTime
   endTime?: DateTime
   label?: string
   color: string
}

export interface TimeSeriesData {
   series: TimeSeriesDataPoint[]
   bands?: TimeSeriesBandDataPoint[]
}

export type TimeSeriesDataPromise = Promise<TimeSeriesData>

interface Props {
   width?: number
   height: number
   data: TimeSeriesDataPromise
   fill?: boolean
   yCrossValue?: number
   lowHighValues?: [number, number] | null
}

interface styleProps {
   height: number
}

const useStyles = makeStyles<Theme, styleProps>({
   hide: () => ({
      display: 'none',
   }),
   show: (props) => ({
      display: 'block',
      height: props.height,
      width: '100%',
   }),
})

const TimeSeriesLine = ({
   width,
   height,
   data,
   fill = false,
   yCrossValue = 0,
   lowHighValues,
}: Props): JSX.Element => {
   const { data: resolvedData, error, isPending } = useAsync({ promise: data })
   const [rect, setRect] = useState(new DOMRect())
   const [svg, setSvg] = useState<Selection<SVGSVGElement, any, any, any>>()
   const [cwidth, setCwidth] = useState(width)

   const [hasdata, setHasdata] = useState(true)

   const classes = useStyles({ height })

   const spkRef = useCallback((node) => {
      if (node !== null) {
         const rect = node.getBoundingClientRect()
         setRect(rect)
         setCwidth(rect.width)
         setSvg(d3Select(node).append('svg').attr('class', 'temperature'))
      }
   }, [])

   useEffect(() => {
      if (
         svg == null ||
         cwidth === undefined ||
         error !== undefined ||
         isPending ||
         resolvedData === undefined
      ) {
         return
      }

      const paddingX = 25
      const paddingY = 20

      svg.style('position', 'relative')
         .style('top', 0)
         .style('left', 0)
         .attr('width', cwidth)
         .attr('height', height)
         .style('pointer-events', 'none')

      const series = resolvedData.series

      if (series.length === 0) {
         setHasdata(false)
         return
      }
      setHasdata(true)

      let yMax = d3Max(series, (d) => +d.value)
      if (yMax === undefined) {
         yMax = yCrossValue
      }
      let yMin = d3Min(series, (d) => +d.value)
      if (yMin === undefined) {
         yMin = yCrossValue
      }
      yMin = Math.min(yCrossValue, yMin)

      const xMax = d3Max(series, (d) => d.timestamp)
      if (xMax === undefined) {
         return
      }
      const xMin = d3Min(series, (d) => d.timestamp)
      if (xMin === undefined) {
         return
      }

      const xScale = scaleTime()
         .domain([xMin.toJSDate(), xMax.toJSDate()])
         .range([paddingX, cwidth])

      const yScale = scaleLinear()
         .domain([yMin, yMax * 1.1])
         .range([height - paddingY, paddingY])

      const line = d3Line<TimeSeriesDataPoint>()
         .defined((d) => !isNaN(d.value))
         .x((d) => xScale(d.timestamp.toJSDate()))
         .y((d) => yScale(d.value))
         .curve(curveBumpX)

      if (fill) {
         const area = d3Area<TimeSeriesDataPoint>()
            .x((d) => xScale(d.timestamp.toJSDate()))
            .y0(yScale(yCrossValue))
            .y1((d) => yScale(d.value))
            .curve(curveBumpX)

         svg.append('path')
            .datum(resolvedData.series)
            //.attr("class", "area")
            .attr('stroke', 'none')
            .attr('fill-opacity', '0.4')
            .attr('d', area)
      }

      svg.append('g')
         .attr('class', 'yaxis')
         .attr('transform', `translate(${paddingX},0)`)
         .call(
            axisLeft(yScale)
               .tickSize(-1 * cwidth)
               .tickSizeOuter(0)
               .tickArguments([5, 'd'])
         )

      svg.append('g')
         .attr('transform', `translate(0, ${height - paddingY})`)
         .call(axisBottom(xScale))

      svg.append('path')
         .datum(resolvedData.series.filter(line.defined()))
         .attr('class', 'line')
         .attr('fill', 'none')
         .attr('stroke', '#666')
         .attr('stroke-dasharray', 2)
         .attr('opacity', '0.6')
         .attr('d', line)

      svg.append('path')
         .datum(resolvedData.series)
         .attr('class', 'line')
         .attr('fill', 'none')
         .attr('stroke', 'black')
         .attr('stroke-width', '2px')
         .attr('opacity', '0.6')
         .attr('d', line)

      // draw top and low lines
      if (lowHighValues !== undefined && lowHighValues !== null) {
         if (yMin <= lowHighValues[0]) {
            svg.append('line')
               .attr('x1', paddingX)
               .attr('x2', cwidth)
               .attr('y1', yScale(lowHighValues[0]))
               .attr('y2', yScale(lowHighValues[0]))
               .attr('stroke', schemeTableau10[0])
               .attr('opacity', '0.8')
               .attr('stroke-width', '1px')
               .attr('stroke-dasharray', '2,2')
         }

         if (yMax >= lowHighValues[1]) {
            svg.append('line')
               .attr('x1', paddingX)
               .attr('x2', cwidth)
               .attr('y1', yScale(lowHighValues[1]))
               .attr('y2', yScale(lowHighValues[1]))
               .attr('stroke', schemeTableau10[2])
               .attr('opacity', '0.8')
               .attr('stroke-width', '1px')
               .attr('stroke-dasharray', '2,2')
         }
      }

      // draw vertical bands
      if (resolvedData.bands !== undefined) {
         resolvedData.bands.forEach((e, i) => {
            const e2 = { ...e }

            e2.startTime = e2.endTime ?? xMax

            if (e.startTime < xMin) {
               e.startTime = xMin
            }

            const ps = [e, e2]
            // draw areas
            const area = d3Area<TimeSeriesBandDataPoint>()
               .x((d) => xScale(d.startTime.toJSDate()))
               .y0(yScale.range()[0])
               .y1((d) => yScale.range()[1])
               .curve(curveMonotoneX)

            svg.append('path')
               .datum(ps)
               .attr('id', 'band-' + i)
               .attr('stroke', 'none')
               .attr('fill', e.color)
               .attr('fill-opacity', '0.25')
               .attr('d', area)
               .style('pointer-events', 'fill')
               .on('mouseover', function (d) {
                  d3Select(this)
                     .attr('stroke', e.color)
                     .attr('stroke-opacity', 0.5)
                  d3Select('#band-text-' + i).attr('fill', e.color)
               })
               .on('mouseout', function (d) {
                  d3Select(this).attr('stroke', 'none')
                  d3Select('#band-text-' + i).attr('fill', 'none')
               })
            svg.append('text')
               .attr('id', 'band-text-' + i)
               .text(() => e.label ?? '')
               .attr('x', xScale(e.startTime) + 4)
               .attr('y', yScale.range()[1] + 14)
               .attr('font-size', '10px')
               .attr('fill', 'none')
         })
      }

      return () => {
         if (svg !== undefined) {
            svg.selectAll('g').remove()
            svg.selectAll('path').remove()
            svg.selectAll('text').remove()
            svg.selectAll('line').remove()
         }
      }
   }, [
      rect,
      spkRef,
      svg,
      cwidth,
      height,
      data,
      fill,
      yCrossValue,
      lowHighValues,
      error,
      isPending,
      resolvedData,
   ])

   return (
      <>
         <div className={hasdata ? classes.show : classes.hide} ref={spkRef} />
         <div className={hasdata ? classes.hide : classes.show}>
            <NoData />
         </div>
      </>
   )
}

export default TimeSeriesLine
