import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { ReactNode } from 'react'

type Props = {
   children: ReactNode
}

export const PageHeader = ({ children }: Props): JSX.Element => {
   return (
      <>
         <Box p={1}>{children}</Box>
         <Divider />
      </>
   )
}
