import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles/createTheme'
import { ChangeEvent } from 'react'

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         padding: '1px 4px',
         display: 'flex',
         alignItems: 'center',
         width: 400,
      },
      input: {
         marginLeft: theme.spacing(1),
         flex: 1,
      },
      iconButton: {
         padding: 10,
      },
   })
)

interface Props {
   placeholder: string
   text: string
   changeHandler: (text: string) => void
}

export const TextSearch = ({
   placeholder,
   text,
   changeHandler,
}: Props): JSX.Element => {
   const classes = useStyles()

   return (
      <Grid container justify="flex-start" spacing={1} alignItems="center">
         <Grid item xs={4}>
            <Paper
               component={'form'}
               className={classes.root}
               onSubmit={(e) => {
                  e.preventDefault()
               }}
            >
               <InputBase
                  placeholder={placeholder}
                  className={classes.input}
                  value={text}
                  onChange={(
                     e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => changeHandler(e.target.value)}
               />
               <IconButton
                  type={'submit'}
                  className={classes.iconButton}
                  onSubmit={() => {
                     return false
                  }}
               >
                  <SearchIcon />
               </IconButton>
            </Paper>
         </Grid>
      </Grid>
   )
}
