import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import Routes from './Routes'
import theme from './theme'
import config from 'react-global-configuration'
import { UserLoggedinService } from './data/service/user/UserService'
import {
   createIntl,
   createIntlCache,
   IntlShape,
   RawIntlProvider,
} from 'react-intl'
import { intlConfigFromPrefs } from './util/i18n/units'

export interface authFuncContext {
   loginFunc: (isAdmin: boolean, prefs: Record<string, string>) => void
   logoutFunc: () => void
   prefChangeFunc: (prefs: Record<string, string>) => void
}

export const AuthFuncContext = React.createContext<authFuncContext>({
   loginFunc: () => {
      return
   },
   logoutFunc: () => {
      return
   },
   prefChangeFunc: () => {
      return
   },
})

export interface authContext {
   isAdmin: boolean
   isAuthenticated: boolean
   unknownAuth: boolean
}

const auth: authContext = {
   isAdmin: false,
   isAuthenticated: false,
   unknownAuth: true,
}

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
   config.set({ dev: true }, { freeze: false, assign: false })
} else {
   config.set({ dev: false }, { freeze: false, assign: false })
}

export const AuthContext = React.createContext<authContext>(auth)

const cache = createIntlCache()

const defaultIntl = createIntl(intlConfigFromPrefs(), cache)

const App: React.FC = () => {
   const [isAuthenticated, setIsAuthenticated] = useState<boolean>()
   const [, setIsAdmin] = useState<boolean>(false)
   const [configLoadingState, setConfigLoadingState] = useState<
      'loading' | 'ready' | 'error'
   >('loading')
   const [intl, setIntl] = useState<IntlShape>(defaultIntl)
   const [auth, setAuth] = useState<authContext>({
      isAdmin: false,
      isAuthenticated: false,
      unknownAuth: true,
   })
   const [basePath, setBasePath] = useState<string>('/')

   const authContextValues: authFuncContext = {
      loginFunc: (isAdmin: boolean, prefs: Record<string, string>) => {
         setIsAuthenticated(true)
         setAuth({
            isAdmin: isAdmin,
            isAuthenticated: true,
            unknownAuth: false,
         })
         setIntl(createIntl(intlConfigFromPrefs(prefs), cache))
      },
      logoutFunc: () => {
         setIsAuthenticated(false)
         setIsAdmin(false)
         setAuth({
            isAdmin: false,
            isAuthenticated: false,
            unknownAuth: false,
         })
      },
      prefChangeFunc: (prefs: Record<string, string>) => {
         setIntl(createIntl(intlConfigFromPrefs(prefs), cache))
      },
   }

   // load configuration file
   useEffect(() => {
      fetch(process.env.PUBLIC_URL + '/config/config.json')
         .then((response) => response.json())
         .then((response) => {
            config.set(
               { api_url: response.api_url },
               { freeze: false, assign: true }
            )
            config.set(
               { temperature_limits: response.temperature_limits },
               { freeze: false, assign: true }
            )
            config.set(
               { base_path: response.base_path },
               { freeze: false, assign: true }
            )
            setBasePath(response.base_path)
            setConfigLoadingState('ready')
         })
         .catch((e) => {
            setConfigLoadingState('error')
         })
   }, [])

   useEffect(() => {
      if (configLoadingState !== 'ready') {
         return
      }
      UserLoggedinService()
         .then((res) => {
            if (res !== undefined) {
               setIsAuthenticated(true)
               setIsAdmin(res.admin)
               setAuth({
                  isAdmin: res.admin,
                  isAuthenticated: true,
                  unknownAuth: false,
               })
               if (res.prefs !== undefined) {
                  setIntl(createIntl(intlConfigFromPrefs(res.prefs), cache))
               }
            } else {
               setIsAuthenticated(false)
               setIsAdmin(false)
               setAuth({
                  isAdmin: false,
                  isAuthenticated: false,
                  unknownAuth: false,
               })
            }
         })
         .catch(() => {
            setIsAuthenticated(false)
            setAuth({
               isAdmin: false,
               isAuthenticated: false,
               unknownAuth: false,
            })
         })
   }, [configLoadingState])

   return (
      <RawIntlProvider value={intl}>
         <AuthContext.Provider value={auth}>
            <AuthFuncContext.Provider value={authContextValues}>
               <ThemeProvider theme={theme}>
                  {isAuthenticated !== undefined &&
                  configLoadingState === 'ready' ? (
                     <BrowserRouter basename={basePath}>
                        <Routes />
                     </BrowserRouter>
                  ) : (
                     <>
                        <div>{/* TODO: put a waiting circle here */}</div>
                     </>
                  )}
               </ThemeProvider>
            </AuthFuncContext.Provider>
         </AuthContext.Provider>
      </RawIntlProvider>
   )
}

export default App
