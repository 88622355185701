import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useEffect, useState } from 'react'

const MIN_TEMPERATURE = -40
const MAX_TEMPERATURE = 85
const marks = [
   {
      value: MIN_TEMPERATURE,
      label: 'Min',
   },
   {
      value: -10,
      label: 'Low',
   },
   {
      value: 20,
      label: 'High',
   },
   {
      value: MAX_TEMPERATURE,
      label: 'Max',
   },
]

const useStyles = makeStyles({
   slider: {
      width: 200,
   },
})

export type temperatureFilterValues = {
   minTemperature: number | undefined
   maxTemperature: number | undefined
}

interface Props {
   filter: temperatureFilterValues
   onChange: (value: temperatureFilterValues) => void
}

export const TemperatureFilter = ({ filter, onChange }: Props): JSX.Element => {
   const classes = useStyles()
   const [value, setValue] = useState<number[]>([
      MIN_TEMPERATURE,
      MAX_TEMPERATURE,
   ])

   useEffect(() => {
      const min = filter.minTemperature ?? MIN_TEMPERATURE
      const max = filter.maxTemperature ?? MAX_TEMPERATURE

      setValue([min, max])
   }, [filter.minTemperature, filter.maxTemperature])

   const handleTempFilterChange = (event: any, newValue: number | number[]) => {
      const v = newValue as number[]
      if (v.length < 2) {
         return
      }
      if (v[1] > v[0]) {
         onChange({
            minTemperature: v[0],
            maxTemperature: v[1],
         })
      }
   }

   return (
      <div className={classes.slider}>
         <Typography id="discrete-slider-always">Temperature</Typography>
         <Slider
            min={MIN_TEMPERATURE}
            max={MAX_TEMPERATURE}
            step={null}
            marks={marks}
            value={value}
            onChange={handleTempFilterChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
         />
      </div>
   )
}
