import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { POICategory } from '../../util/types/types'
import { MovementTypeCard } from './MovementShortTypeCard'
import { MovementMap } from './MovementMap'
import {
   Page,
   PageContent,
   Section,
   SectionContent,
   SectionTitle,
} from '../../components'
import {
   DateInterval,
   DateIntervalFilter,
   DatePeriod,
   getPeriodFromInterval,
} from '../../components/Filter/Date/DateIntervalFilter'
import { DateTime } from 'luxon'

const LogisticsShort = (): JSX.Element => {
   const [interval, setInterval] = useState<DateInterval>({
      minDate: DateTime.now().plus({ month: -1 }),
      maxDate: DateTime.now(),
   })
   const [validInterval] = useState<DateInterval>({
      minDate: DateTime.now().plus({ year: -1 }),
      maxDate: DateTime.now(),
   })
   const [period, setPeriod] = useState<DatePeriod>({
      endDate: DateTime.now(),
      duration: 30,
   })

   useEffect(() => {
      setPeriod(getPeriodFromInterval(interval))
   }, [interval])

   const rows = []

   for (const pcat in POICategory) {
      //@ts-ignore
      const catName = POICategory[pcat]

      if (catName === POICategory.Unknown) {
         continue
      }

      rows.push(
         <Grid item xs={4} key={catName}>
            <MovementTypeCard
               category={catName}
               endDate={period.endDate}
               period={period.duration}
            />
         </Grid>
      )
   }

   return (
      <Page>
         <PageContent>
            <Section paperless>
               <SectionContent>
                  <DateIntervalFilter
                     interval={interval}
                     validInterval={validInterval}
                     intervalChangeHandler={setInterval}
                     preText={'Show movements from'}
                     middleText={'to'}
                  />
               </SectionContent>
            </Section>
            <Section paperless>
               <SectionContent>
                  <Grid container spacing={2} justify="space-between">
                     {rows}
                  </Grid>
               </SectionContent>
            </Section>
            <Section paperless>
               <SectionTitle>Movements by location</SectionTitle>
               <SectionContent>
                  <MovementMap
                     period={period.duration}
                     endDate={period.endDate}
                  />
               </SectionContent>
            </Section>
         </PageContent>
      </Page>
   )
}

export default LogisticsShort
