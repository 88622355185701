import React, { useEffect, useState } from 'react'
import validate from 'validate.js'
import makeStyles from '@material-ui/styles/makeStyles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { UserLoginService } from '../../data/service/user/UserService'
import { AuthFuncContext } from '../../App'
import { RouteComponentProps } from 'react-router-dom'

// TODO: use yup instead of validate.js
const schema = {
   email: {
      presence: { allowEmpty: false, message: 'is required' },
      email: true,
      length: {
         maximum: 64,
      },
   },
   password: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
         maximum: 128,
      },
   },
}

const useStyles = makeStyles((theme) => ({
   root: {
      // @ts-ignore
      backgroundColor: theme.palette.background.default,
      height: '100%',
   },
   grid: {
      height: '100%',
   },
   quoteContainer: {
      // @ts-ignore
      [theme.breakpoints.down('md')]: {
         display: 'none',
      },
   },
   quote: {
      // @ts-ignore
      backgroundColor: theme.palette.neutral,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage:
         'url(' + process.env.PUBLIC_URL + '/assets/images/loginbg.jpg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
   },
   quoteInner: {
      textAlign: 'center',
      flexBasis: '600px',
   },
   quoteText: {
      // @ts-ignore
      color: theme.palette.primary,
      fontWeight: 400,
   },
   name: {
      // @ts-ignore
      marginTop: theme.spacing(3),
      // @ts-ignore
      color: theme.palette.white,
   },
   bio: {
      // @ts-ignore
      color: theme.palette.white,
   },
   contentContainer: {},
   content: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
   },
   contentHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      // @ts-ignore
      paddingTop: theme.spacing(5),
      // @ts-ignore
      paddingBototm: theme.spacing(2),
      // @ts-ignore
      paddingLeft: theme.spacing(2),
      // @ts-ignore
      paddingRight: theme.spacing(2),
   },
   logoImage: {
      // @ts-ignore
      marginLeft: theme.spacing(4),
   },
   contentBody: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      // @ts-ignore
      [theme.breakpoints.down('md')]: {
         justifyContent: 'center',
      },
   },
   form: {
      paddingLeft: 100,
      paddingRight: 100,
      paddingBottom: 125,
      flexBasis: 700,
      // @ts-ignore
      [theme.breakpoints.down('sm')]: {
         // @ts-ignore
         paddingLeft: theme.spacing(2),
         // @ts-ignore
         paddingRight: theme.spacing(2),
      },
   },
   title: {
      // @ts-ignore
      marginTop: theme.spacing(3),
   },
   socialButtons: {
      // @ts-ignore
      marginTop: theme.spacing(3),
   },
   socialIcon: {
      // @ts-ignore
      marginRight: theme.spacing(1),
   },
   sugestion: {
      // @ts-ignore
      marginTop: theme.spacing(2),
   },
   textField: {
      // @ts-ignore
      marginTop: theme.spacing(2),
   },
   signInButton: {
      // @ts-ignore
      margin: theme.spacing(2, 0),
   },
   errorMessage: {
      // @ts-ignore
      marginTop: theme.spacing(2),
      color: '#aa0000',
   },
}))

const Login = (props: RouteComponentProps): JSX.Element => {
   const { history } = props

   const classes = useStyles()

   const [formState, setFormState] = useState({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
   })

   const [serverError, setServerError] = useState<boolean>(false)

   const { Consumer } = AuthFuncContext

   useEffect(() => {
      const errors = validate(formState.values, schema)

      setFormState((formState) => ({
         ...formState,
         isValid: errors ? false : true,
         errors: errors || {},
      }))
   }, [formState.values])

   const handleChange = (event: any) => {
      event.persist()

      setFormState((formState) => ({
         ...formState,
         values: {
            ...formState.values,
            [event.target.name]:
               event.target.type === 'checkbox'
                  ? event.target.checked
                  : event.target.value,
         },
         touched: {
            ...formState.touched,
            [event.target.name]: true,
         },
      }))
   }

   const handleSignIn = (
      loginFunc: (isAdmin: boolean, prefs: Record<string, string>) => void
   ) => {
      return (event: any) => {
         event.preventDefault()

         //@ts-ignore
         UserLoginService(formState.values.email, formState.values.password)
            .catch(() => {
               setServerError(true)
            })
            .then((res) => {
               if (res !== undefined && res.status === 200) {
                  loginFunc(res.data.is_admin, res.data.prefs)
                  history.push('/')
               } else {
                  setServerError(true)
               }
            })
      }
   }

   const hasError = (field: any) =>
      //@ts-ignore
      formState.touched[field] && formState.errors[field] ? true : false

   return (
      <div className={classes.root}>
         <Grid className={classes.grid} container>
            <Grid className={classes.quoteContainer} item lg={5}>
               <div className={classes.quote}>
                  <div className={classes.quoteInner}>
                     <Typography className={classes.quoteText} variant="h1">
                        {/* BREWING DATA, DELIVERING WISDOM */}
                     </Typography>
                  </div>
               </div>
            </Grid>
            <Grid className={classes.content} item lg={7} xs={12}>
               <div className={classes.content}>
                  <div className={classes.contentHeader}>
                     <img
                        src={
                           process.env.PUBLIC_URL +
                           '/assets/logo/katch_logo.png'
                        }
                        height="200"
                        alt=""
                     />
                  </div>
                  <div className={classes.contentBody}>
                     <Consumer>
                        {(value) => (
                           <form
                              className={classes.form}
                              onSubmit={handleSignIn(value.loginFunc)}
                           >
                              <Typography
                                 className={classes.title}
                                 variant="h2"
                              >
                                 Sign in
                              </Typography>
                              <TextField
                                 className={classes.textField}
                                 error={hasError('email')}
                                 fullWidth
                                 helperText={
                                    hasError('email')
                                       ? //@ts-ignore
                                         formState.errors.email[0]
                                       : null
                                 }
                                 label="Email address"
                                 name="email"
                                 onChange={handleChange}
                                 type="text"
                                 //@ts-ignore
                                 value={formState.values.email || ''}
                                 variant="outlined"
                              />
                              <TextField
                                 className={classes.textField}
                                 error={hasError('password')}
                                 fullWidth
                                 helperText={
                                    hasError('password')
                                       ? //@ts-ignore
                                         formState.errors.password[0]
                                       : null
                                 }
                                 label="Password"
                                 name="password"
                                 onChange={handleChange}
                                 type="password"
                                 //@ts-ignore
                                 value={formState.values.password || ''}
                                 variant="outlined"
                              />
                              {serverError && (
                                 <div className={classes.errorMessage}>
                                    Invalid email or password
                                 </div>
                              )}

                              <Button
                                 className={classes.signInButton}
                                 color="primary"
                                 disabled={!formState.isValid}
                                 fullWidth
                                 size="large"
                                 type="submit"
                                 variant="contained"
                              >
                                 Sign in
                              </Button>
                           </form>
                        )}
                     </Consumer>
                  </div>
               </div>
            </Grid>
         </Grid>
      </div>
   )
}

export default Login
