import React, { useCallback, useEffect, useState } from 'react'
import './sparkline.css'
import { POICategory } from '../../../../../util/types/types'
import { getPlaceColor } from '../../../../../util/Looks/colors'
import { select, Selection } from 'd3-selection'
import { max as d3Max } from 'd3-array'
import { area as d3Area, curveBasis, line as d3Line } from 'd3-shape'
import { axisLeft } from 'd3-axis'
import { scaleLinear } from 'd3-scale'
import { useAsync } from 'react-async'

export interface SparkDataPoint {
   value: number
}

export type SparkData = Promise<SparkDataPoint[]>

interface Props {
   width?: number
   height: number
   category: POICategory
   data: SparkData
   fill?: boolean
   inline?: boolean
   axis?: boolean
}

const Sparkline = ({
   width,
   height,
   category,
   data,
   fill = false,
   inline = true,
   axis = false,
}: Props): JSX.Element => {
   const { data: resolvedData, error, isPending } = useAsync({ promise: data })
   const [rect, setRect] = useState(new DOMRect())
   const [svg, setSvg] = useState<Selection<SVGSVGElement, any, any, any>>()
   const [cwidth, setCwidth] = useState(width)

   const spkRef = useCallback(
      (node) => {
         if (node !== null) {
            const rect = node.getBoundingClientRect()
            setRect(rect)
            if (!inline) {
               setCwidth(rect.width)
            }
            setSvg(select(node).append('svg'))
         }
      },
      [inline]
   )

   useEffect(() => {
      if (
         svg == null ||
         cwidth === undefined ||
         error !== undefined ||
         isPending ||
         resolvedData === undefined
      ) {
         return
      }

      const paddingX = axis ? 50 : 0
      const paddingY = axis ? 20 : 0

      svg.style('position', 'relative')
         .style('top', 0)
         .style('left', 0)
         .attr('width', cwidth)
         .attr('height', height)
         .style('pointer-events', 'none')

      //TODO: correctly handle new data arrival by replacing old content
      const max = d3Max(resolvedData, (d) => +d.value)
      if (max === undefined) return
      const count = resolvedData.length

      const x = scaleLinear()
         .domain([0, count])
         .range([0 + paddingX, cwidth])

      const y = scaleLinear()
         .domain([0, max])
         .range([height - paddingY, 0 + paddingY])

      const line = d3Line<SparkDataPoint>()
         .x((d, i) => x(i))
         .y((d) => y(d.value))
         .curve(curveBasis)

      if (fill) {
         const area = d3Area<SparkDataPoint>()
            .x((d, i) => x(i))
            .y0(y(0))
            .y1((d) => y(d.value))
            .curve(curveBasis)

         svg.append('path')
            .datum(resolvedData)
            //.attr("class", "area")
            .attr('stroke', 'none')
            .attr('fill', getPlaceColor(category))
            .attr('fill-opacity', '0.4')
            .attr('d', area)
      }

      if (axis) {
         svg.append('g')
            .attr('transform', `translate(${paddingX},0)`)
            .call(axisLeft(y))
      }

      svg.append('path')
         .datum(resolvedData)
         .attr('class', 'line')
         .attr('stroke', getPlaceColor(category))
         .attr('fill', 'none')
         .attr('stroke-width', '1px')
         .attr('d', line)

      return () => {
         if (svg !== undefined) {
            svg.selectAll('g').remove()
            svg.selectAll('path').remove()
         }
      }
   }, [
      rect,
      spkRef,
      svg,
      cwidth,
      height,
      axis,
      category,
      resolvedData,
      fill,
      error,
      isPending,
   ])

   const styles = {
      height: height + 'px',
      width: inline ? width + 'px' : '100%',
      display: inline ? 'inline' : 'block',
   }

   return <div style={styles} ref={spkRef} className="sparkline" />
}

export default Sparkline
