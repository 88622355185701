import React, { useContext } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import makeStyles from '@material-ui/styles/makeStyles'
import AppBar from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import InputIcon from '@material-ui/icons/Input'
import { UserLogoutService } from '../../../../data/service/user/UserService'
import { AuthFuncContext } from '../../../../App'

const useStyles = makeStyles({
   root: {
      boxShadow: 'none',
   },
   flexGrow: {
      flexGrow: 1,
   },
   signOutButton: {
      //marginLeft: theme.spacing(1)
   },
})

const Topbar = (props: {
   [x: string]: any
   className: any
   onSidebarOpen: any
}): JSX.Element => {
   const { className, onSidebarOpen, ...rest } = props

   const history = useHistory()

   const classes = useStyles()

   //const [notifications] = useState([]);

   const authFuncContext = useContext(AuthFuncContext)

   const logoutClick = (logoutFunc: () => void) => {
      return () => {
         UserLogoutService().then(() => {
            logoutFunc()
            history.push('/login')
         })
      }
   }

   return (
      <AppBar {...rest} className={clsx(classes.root, className)}>
         <Toolbar>
            <RouterLink to="/">
               <img
                  alt="Logo"
                  width={1024 / 12}
                  height={768 / 12}
                  src={
                     process.env.PUBLIC_URL + '/assets/logo/katch_logo_rev.png'
                  }
               />
            </RouterLink>
            <div className={classes.flexGrow} />
            <Hidden mdDown>
               {/*
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon/>
            </Badge>
          </IconButton>
          */}
               <IconButton
                  className={classes.signOutButton}
                  color="inherit"
                  onClick={logoutClick(authFuncContext.logoutFunc)}
               >
                  <InputIcon />
               </IconButton>
            </Hidden>
            <Hidden lgUp>
               <IconButton color="inherit" onClick={onSidebarOpen}>
                  <MenuIcon />
               </IconButton>
            </Hidden>
         </Toolbar>
      </AppBar>
   )
}

export default Topbar
