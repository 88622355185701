import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles(() => ({
   root: {
      paddingTop: 64,
      height: '100%',
   },
   content: {
      height: '100%',
   },
}))

const Bare = ({ children }: { children: ReactNode }): JSX.Element => {
   const classes = useStyles()

   return (
      <div className={classes.root}>
         <main className={classes.content}>{children}</main>
      </div>
   )
}

Bare.propTypes = {
   children: PropTypes.node,
   className: PropTypes.string,
}

export default Bare
