import { DateTime } from 'luxon'

export type CountedReply<T> = {
   count: number
   list: T[]
}

export type TimePeriod = {
   start: DateTime
   end: DateTime
}

export const addPeriodToURL = (url: URL, period: TimePeriod): void => {
   url.searchParams.append('startDate', period.start.toISODate())
   url.searchParams.append('endDate', period.end.toISODate())
}
