import { useEffect, useState } from 'react'
import { POICategory } from '../../../../util/types/types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MultiFull from '../../charts/line/MultiFull'
import { InventoryPOIService } from '../../../../data/service/inventory/InventoryService'
import { getIntervalFromPeriod } from '../../../../components/Filter/Date/DateIntervalFilter'
import { DateTime } from 'luxon'

export interface PoiSummary {
   id: number
   name?: string
   cat: POICategory
}

interface Props {
   poi: PoiSummary
   onClose: () => void
   endDate: DateTime
   period: number
}

export const POIInventoryDialog = ({
   poi,
   onClose,
   endDate,
   period,
}: Props): JSX.Element => {
   const [dataAll, setDataAll] = useState<Promise<any>>()

   useEffect(() => {
      const interval = getIntervalFromPeriod({
         endDate: endDate,
         duration: period,
      })
      const data = InventoryPOIService({
         locationId: poi.id,
         interval: interval,
      })
      setDataAll(data)
   }, [endDate, period, poi.id])

   return (
      <Dialog
         open={poi !== undefined}
         fullWidth={true}
         maxWidth={'xl'}
         onClose={onClose}
      >
         <DialogTitle>{poi !== undefined ? poi.name : ''}</DialogTitle>
         <DialogContent>
            {dataAll && (
               <MultiFull height={300} category={poi.cat} data={dataAll} />
            )}
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose} color="primary">
               Close
            </Button>
         </DialogActions>
      </Dialog>
   )
}
