import Tooltip from '@material-ui/core/Tooltip'
import { DateTime } from 'luxon'
import { daysAgo } from '../../util/DateTimeUtils/DateTimeUtils'

interface Props {
   date: DateTime
   suffix?: boolean
}

export const ShortDate = ({ date, suffix }: Props): JSX.Element => {
   const days = daysAgo(date)
   const suffix_text = suffix === undefined || suffix ? ' ago' : ''
   //console.log("days", days, date)
   return (
      <div>
         <Tooltip title={date.toLocaleString(DateTime.DATETIME_SHORT)}>
            <span>
               {days} days{suffix_text}
            </span>
         </Tooltip>
      </div>
   )
}
