import React from 'react'
import {
   POIHistoricalLocation,
   POILocation,
} from '../../../data/service/kegs/KegsService'
import { LocationLink } from '../../../components'
import createStyles from '@material-ui/core/styles/createStyles'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles/createTheme'
import { DateTime } from 'luxon'
import { daysAgo } from '../../../util/DateTimeUtils/DateTimeUtils'

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      location: {
         fontSize: theme.typography.fontSize * 1.2,
      },
   })
)

interface Props {
   location?: POILocation
   historicalLocation?: POIHistoricalLocation
   lastLocationTime?: DateTime
}

export const LatestLocation = ({
   location,
   historicalLocation,
   lastLocationTime,
}: Props): JSX.Element => {
   const classes = useStyles()

   let result = undefined

   if (historicalLocation !== undefined) {
      if (historicalLocation.departure !== undefined) {
         result = (
            <>
               In transit from{' '}
               <LocationLink
                  id={historicalLocation.location.id}
                  category={historicalLocation.location.category}
                  name={historicalLocation.location.name}
               />{' '}
               for {daysAgo(historicalLocation.departure)} days
            </>
         )
      } else {
         result = (
            <>
               In{' '}
               <LocationLink
                  id={historicalLocation.location.id}
                  category={historicalLocation.location.category}
                  name={historicalLocation.location.name}
               />{' '}
               for {daysAgo(historicalLocation.arrival)} days
            </>
         )
      }
   }
   if (location !== undefined) {
      result = (
         <>
            {result} <p />
            Stopped at{' '}
            <LocationLink
               id={location.id}
               name={location.name}
               category={location.category}
            />{' '}
            for{' '}
            {lastLocationTime === undefined ? '-' : daysAgo(lastLocationTime)}{' '}
            days
         </>
      )
   }

   return <Typography className={classes.location}>{result}</Typography>
}
