import { DateTime } from 'luxon'
import { DateInterval } from '../../components/Filter/Date/DateIntervalFilter'

export function daysAgo(d: DateTime): number {
   return Math.floor(d.diffNow('days').as('days') * -1)
}

export interface PeriodFilter {
   startDate?: DateTime
   endDate?: DateTime
}

export function fillPeriodURL(u: URL, p: PeriodFilter): void {
   if (p.startDate !== undefined) {
      u.searchParams.append('startDate', p.startDate.toFormat('yyyy-MM-dd'))
   }
   if (p.endDate !== undefined) {
      u.searchParams.append('endDate', p.endDate.toFormat('yyyy-MM-dd'))
   }
}

export function periodFilterFromDateInterval(d: DateInterval): PeriodFilter {
   return {
      startDate: d.minDate,
      endDate: d.maxDate,
   }
}
