import createStyles from '@material-ui/core/styles/createStyles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slider from '@material-ui/core/Slider'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PauseIcon from '@material-ui/icons/Pause'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { DateInterval } from '../../../../components/Filter/Date/DateIntervalFilter'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles/createTheme'
import { interval, Subject, take, takeUntil } from 'rxjs'

function findDateInInterval(d: DateInterval, perc: number): DateTime {
   const diff = d.maxDate.diff(d.minDate).as('seconds')
   return d.minDate.plus({ second: diff * perc })
}

enum animationStatus {
   play,
   pause,
}

const sliderLabelStyles = makeStyles((theme: Theme) =>
   createStyles({
      label: {
         color: 'white',
         backgroundColor: theme.palette.text.secondary,
         padding: '4px',
         borderRadius: '4px',
      },
   })
)

interface Props {
   disabled: boolean
   dateInterval?: DateInterval
   handleProgressChange: (percentage: number) => void
}

const endPerc = 0.999
const pause = new Subject<boolean>()

export const TimeSlider = ({
   disabled,
   dateInterval,
   handleProgressChange,
}: Props): JSX.Element => {
   const [playing, setPlaying] = useState<animationStatus>(
      animationStatus.pause
   )
   const [percentage, setPercentage] = useState<number>(endPerc)
   const sliderClasses = sliderLabelStyles()

   const changePercentage = (v: number) => {
      setPercentage(v)
      handleProgressChange(v)
   }

   const playPause = () => {
      if (
         dateInterval === undefined ||
         dateInterval.maxDate.diff(dateInterval.minDate).as('seconds') === 0
      ) {
         return
      }
      if (playing === animationStatus.play) {
         setPlaying(animationStatus.pause)
         pause.next(false)
      } else {
         setPlaying(animationStatus.play)
         let mainPerc = percentage
         if (percentage >= endPerc) {
            mainPerc = 0
         }
         const step = 20

         /*const duration = Math.max(
           10000,
           getIntervalDaysDuration(dInterval) * 1000
         )*/
         const totalTime = 10000

         const remainingTime = (1 - mainPerc) * totalTime
         interval(step)
            .pipe(take(remainingTime / step + 1), takeUntil(pause))
            .subscribe((v) => {
               const curPerc = mainPerc + (v * step) / totalTime
               changePercentage(curPerc)
               if (curPerc > endPerc) {
                  setPlaying(animationStatus.pause)
               }
            })
      }
   }

   const sliderChange = (event: any, newValue: number | number[]) => {
      if (playing === animationStatus.play) {
         return
      }
      changePercentage((newValue as number) / 100)
   }

   return (
      <>
         <Grid container alignItems="center">
            <Grid item>
               <IconButton onClick={playPause} disabled={disabled}>
                  {playing === animationStatus.pause ? (
                     <PlayArrowIcon />
                  ) : (
                     <PauseIcon />
                  )}
               </IconButton>
            </Grid>
            <Grid item>
               <div>
                  Current date:{' '}
                  {dateInterval !== undefined &&
                     findDateInInterval(
                        dateInterval,
                        percentage
                     ).toLocaleString(DateTime.DATETIME_SHORT)}
               </div>
            </Grid>
         </Grid>
         <Slider
            min={0}
            max={100}
            step={0.1}
            value={percentage * 100}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            valueLabelFormat={(value) => (
               <div className={sliderClasses.label}>
                  {dateInterval !== undefined
                     ? findDateInInterval(
                          dateInterval,
                          value / 100
                       ).toLocaleString(DateTime.DATETIME_SHORT)
                     : '-'}
               </div>
            )}
            disabled={disabled}
            onChange={sliderChange}
         />
      </>
   )
}
