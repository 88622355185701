import { DateInterval } from '../../../components/Filter/Date/DateIntervalFilter'
import { TemperatureListItem } from '../kegs/KegsService'
import config from 'react-global-configuration'
import {
   fillPeriodURL,
   periodFilterFromDateInterval,
} from '../../../util/DateTimeUtils/DateTimeUtils'
import { csv } from 'd3-fetch'
import { DateTime } from 'luxon'

export interface TemperatureServiceParams {
   interval: DateInterval
   locationId: number
}

export const POITemperatureService = ({
   locationId,
   interval,
}: TemperatureServiceParams): Promise<TemperatureListItem[]> => {
   const api_url = new URL(config.get('api_url'))

   api_url.pathname += '/v1/temperature/poi/' + locationId + '/'

   if (interval !== undefined) {
      fillPeriodURL(api_url, periodFilterFromDateInterval(interval))
   }

   return (
      //@ts-ignore
      csv<TemperatureListItem[]>(api_url.href, { credentials: 'include' }).then(
         (d) =>
            d.map((i: any) => {
               let val = i.value
               if (val === undefined || val === null || val === 'NaN') {
                  val = NaN
               }
               return {
                  timestamp: DateTime.fromISO(i.date),
                  value: val,
               }
            })
      )
   )
}
