import { sankey, sankeyJustify, sankeyLinkHorizontal } from 'd3-sankey'
import { useCallback, useEffect, useState } from 'react'
import { getPlaceColor } from '../../../../util/Looks/colors'
import { select, Selection } from 'd3-selection'
import { useAsync } from 'react-async'

interface Props {
   data: Promise<any>
}

export const OnalyticsSankeyD3 = ({ data }: Props): JSX.Element => {
   const [rect, setRect] = useState(new DOMRect())
   const [svg, setSvg] = useState<Selection<SVGSVGElement, any, any, any>>()
   const { data: resolvedData, error, isPending } = useAsync({ promise: data })

   const sankeyRef = useCallback((node) => {
      if (node !== null) {
         const rect = node.getBoundingClientRect()
         setRect(rect)

         setSvg(select(node).append('svg'))
      }
   }, [])

   useEffect(() => {
      if (
         svg == null ||
         error !== undefined ||
         isPending ||
         resolvedData === undefined
      ) {
         return
      }

      const sankeyF = sankey()
         .nodeId((d: any) => d.id)
         .nodeAlign(sankeyJustify)
         .nodeWidth(10)
         .nodePadding(10)
         .extent([
            [0, 5],
            [rect.width, rect.height - 5],
         ])

      const { nodes, links } = sankeyF({
         nodes: resolvedData.nodes.map((d: any) => Object.assign({}, d)),
         links: resolvedData.links.map((d: any) => Object.assign({}, d)),
      })

      // @ts-ignore
      svg.attr('viewBox', [0, 0, rect.width, rect.height])

      //draw rectangles
      svg.append('g')
         .selectAll('rect')
         .data(nodes)
         .join('rect')
         .attr('x', (d: any) => d.x0)
         .attr('y', (d: any) => d.y0)
         .attr('height', (d: any) => d.y1 - d.y0)
         .attr('width', (d: any) => d.x1 - d.x0)
         .attr('stroke', (d: any) => getPlaceColor(d.category))
         .attr('fill', (d: any) => getPlaceColor(d.category))
         .attr('fill-opacity', 0.8)

      const link = svg
         .append('g')
         .attr('fill', 'none')
         .attr('stroke-opacity', 0.5)
         .selectAll('g')
         .data(links)
         .join('g')
         .attr('stroke', '#000')
         .style('mix-blend-mode', 'multiply')

      link
         .append('path')
         .attr('d', sankeyLinkHorizontal())
         .attr('stroke', (d: any) => {
            if (d.target.id !== 0) {
               return getPlaceColor(d.target.category, 0.4)
            }
            return getPlaceColor(d.source.category, 0.4)
         })
         .attr('stroke-width', (d: any) => Math.max(1, d.width))

      link.append('title').text((d: any) => {
         return `${d.source.name} → ${d.target.name}\n${d.value}`
      })

      svg.append('g')
         .style('font', '10px sans-serif')
         .selectAll('text')
         .data(nodes)
         .join('text')
         .attr('x', (d: any) => (d.x0 < rect.width / 2 ? d.x1 + 6 : d.x0 - 6))
         .attr('y', (d: any) => (d.y1 + d.y0) / 2)
         .attr('dy', '0.35em')
         .attr('text-anchor', (d: any) =>
            d.x0 < rect.width / 2 ? 'start' : 'end'
         )
         .text((d: any) => d.name)

      return () => {
         if (svg !== undefined) {
            svg.selectAll('g').remove()
            svg.selectAll('path').remove()
         }
      }
   }, [svg, rect, resolvedData, error, isPending])

   const styles = { height: '400px', width: '100%' }
   return <div style={styles} ref={sankeyRef} />
}
