import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles/createTheme'
import useTheme from '@material-ui/core/styles/useTheme'
import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import LastPageIcon from '@material-ui/icons/LastPage'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'

const useStyles1 = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         flexShrink: 0,
         marginLeft: theme.spacing(2.5),
      },
   })
)

interface TablePaginationActionsProps {
   count: number
   page: number
   rowsPerPage: number
   onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number
   ) => void
}

export function TablePaginationActions(
   props: TablePaginationActionsProps
): JSX.Element {
   const classes = useStyles1()
   const theme = useTheme()
   const { count, page, rowsPerPage, onPageChange } = props

   const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
   ) => {
      onPageChange(event, 0)
   }

   const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
   ) => {
      onPageChange(event, page - 1)
   }

   const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
   ) => {
      onPageChange(event, page + 1)
   }

   const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
   ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
   }

   return (
      <div className={classes.root}>
         <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
         >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
         </IconButton>
         <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
         >
            {theme.direction === 'rtl' ? (
               <KeyboardArrowRight />
            ) : (
               <KeyboardArrowLeft />
            )}
         </IconButton>
         <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
         >
            {theme.direction === 'rtl' ? (
               <KeyboardArrowLeft />
            ) : (
               <KeyboardArrowRight />
            )}
         </IconButton>
         <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
         >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
         </IconButton>
      </div>
   )
}
