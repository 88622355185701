import { MovementDirection, POICategory } from '../../../util/types/types'
import config from 'react-global-configuration'

import { csv, json } from 'd3-fetch'
import { DateInterval } from '../../../components/Filter/Date/DateIntervalFilter'
import {
   fillPeriodURL,
   periodFilterFromDateInterval,
} from '../../../util/DateTimeUtils/DateTimeUtils'
import { DateTime } from 'luxon'

export interface MovementBaseParams {
   endDate?: DateTime
   period?: number // number of days
}

const getDefault = ({ endDate, period }: MovementBaseParams) => {
   const myPeriod = period === undefined ? 100 : period
   const myEndDate = endDate === undefined ? DateTime.now() : endDate

   return { myEndDate, myPeriod }
}

export interface MovementParams {
   endDate?: DateTime
   period?: number // number of days
   category: POICategory
   direction: MovementDirection
}

const MovementService = ({
   category,
   direction,
   endDate,
   period,
}: MovementParams): Promise<{ value: number }[]> => {
   const { myEndDate, myPeriod } = getDefault({ endDate, period })

   const api_url = new URL(config.get('api_url'))
   api_url.pathname +=
      '/v1/movements/' + category.toString() + '/' + direction + '/'
   api_url.searchParams.append('endDate', myEndDate.toFormat('yyyy-MM-dd'))
   api_url.searchParams.append('duration', Math.floor(myPeriod).toString())

   return csv(api_url.href, { credentials: 'include' }).then((data) => {
      return data.map((d) => {
         return { value: d.value ? +d.value : 0 }
      })
   })
}

// TODO: implement return type
export const MovementMapService = ({
   endDate,
   period,
}: MovementBaseParams): Promise<any> => {
   const { myEndDate, myPeriod } = getDefault({ endDate, period })

   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/map/movements/'
   api_url.searchParams.append('endDate', myEndDate.toFormat('yyyy-MM-dd'))
   api_url.searchParams.append('duration', myPeriod.toString())

   return json(api_url.href, { credentials: 'include' })
}

export interface MovementSankeyParams {
   interval: DateInterval
   locationId: number
}

// TODO: implement return type
export const MovementSankeyService = ({
   locationId,
   interval,
}: MovementSankeyParams): Promise<any> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/sankey/movements/' + locationId + '/'

   if (interval !== undefined) {
      fillPeriodURL(api_url, periodFilterFromDateInterval(interval))
   }

   return json(api_url.href, { credentials: 'include' })
}

export default MovementService
