import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { FunctionComponent } from 'react'
import { getPOICategoryName, POICategory } from '../../../../util/types/types'

const useStyles = makeStyles({
   fullHeight: {
      height: '100%',
   },
   title: {
      fontWeight: 700,
   },
})

interface CardProps {
   title: string
   type: POICategory
}

const ShortTypeCard: FunctionComponent<CardProps> = ({
   title,
   type,
   children,
}) => {
   const classes = useStyles()

   const titleName = getPOICategoryName(type)

   return (
      <Card className={classes.fullHeight}>
         <CardContent>
            <Typography
               className={classes.title}
               color="textPrimary"
               variant="body1"
               align={'center'}
            >
               {titleName}
            </Typography>
            {children}
         </CardContent>
      </Card>
   )
}

export default ShortTypeCard
