import config from 'react-global-configuration'

import { json } from 'd3-fetch'
import axios, { AxiosResponse } from 'axios'

export interface UserListParams {
   page: number
   size: number
   nameFilter: string
}

export interface UserListItem {
   id: number
   email: string
   name: string
   admin: boolean
}

export interface UserListData {
   count: number
   users: UserListItem[]
}

export const UserListService = ({
   page,
   size,
   nameFilter,
}: UserListParams): Promise<UserListData | undefined> => {
   const api_url = new URL(config.get('api_url'))

   api_url.pathname += '/v1/user/'
   api_url.searchParams.append('page', page.toString())
   api_url.searchParams.append('size', size.toString())
   api_url.searchParams.append('name_filter', nameFilter)

   return json<UserListData>(api_url.href, { credentials: 'include' })
}

export interface UserCreateData {
   email: string
   name: string
   admin: boolean
   password: string
}

export const UserCreateService = (
   createData: UserCreateData
): Promise<AxiosResponse<boolean>> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/user/'
   return axios.post<boolean>(api_url.href, JSON.stringify(createData), {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
   })
}

export interface UserData {
   id: number
   email: string
   name: string
   admin: boolean
   timezone: string
   prefs: Record<string, string>
}

const setDefaultTimezone = (u: UserData | undefined) => {
   if (u !== undefined && (u.timezone === null || u.timezone === undefined)) {
      u.timezone = 'default'
   }
   return u
}

export const UserDetailService = (
   userId: number
): Promise<UserData | undefined> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/user/' + userId

   return json<UserData>(api_url.href, { credentials: 'include' }).then(
      setDefaultTimezone
   )
}

export const UserLoggedinService = (): Promise<UserData | undefined> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/loggedin'

   return json<UserData>(api_url.href, { credentials: 'include' }).then(
      setDefaultTimezone
   )
}

export interface LoginReply {
   is_admin: boolean
   prefs: Record<string, string>
}

export const UserLoginService = (
   email: string,
   password: string
): Promise<AxiosResponse<LoginReply>> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/login'

   return axios.post<LoginReply>(
      api_url.href,
      { email: email, password: password },
      { headers: { 'Content-Type': 'application/json' }, withCredentials: true }
   )
}

export const UserLogoutService = (): Promise<AxiosResponse<boolean>> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/logout'

   return axios.post<boolean>(
      api_url.href,
      { logout: true },
      { headers: { 'Content-Type': 'application/json' }, withCredentials: true }
   )
}

export interface UserEditData {
   id: number
   email: string
   name: string
   admin: boolean
   timezone: string
}

export const UserDataUpdateService = (
   user: UserEditData
): Promise<AxiosResponse<boolean>> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/user/' + user.id

   return axios.post<boolean>(api_url.href, user, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
   })
}

export interface PasswordChangeData {
   id: number
   current_password: string
   new_password: string
}

export const UserPasswordUpdateService = (
   userPass: PasswordChangeData
): Promise<AxiosResponse<boolean>> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/user/' + userPass.id

   return axios.post<boolean>(api_url.href, userPass, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
   })
}

export const UserDeleteService = (
   userId: number
): Promise<AxiosResponse<boolean>> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/user/' + userId

   return axios.delete<boolean>(api_url.href, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
   })
}
