import config from 'react-global-configuration'

import { json } from 'd3-fetch'
import {
   FillLevel,
   getCategoryFromString,
   getFillLevelFromString,
   Order,
   POICategory,
} from '../../../util/types/types'
import { addPaginationToURL, Pagination } from '../../../util/types/serviceUtil'
import axios, { AxiosResponse } from 'axios'
import { DateTime } from 'luxon'
import {
   fillPeriodURL,
   PeriodFilter,
} from '../../../util/DateTimeUtils/DateTimeUtils'
import { Feature, FeatureCollection, Point } from 'geojson'
import { distance } from '../../../util/geo/geo'

// TODO: implement return type
const KegListMapService = (): Promise<any> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/asset_list_map/'

   return json(api_url.href, { credentials: 'include' })
}

// TODO: implement return type
export const KegMapService = (kegId: number): Promise<any> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/asset/' + kegId + '/map/'

   return json(api_url.href, { credentials: 'include' })
}

export interface KegDetailFilter extends PeriodFilter {
   kegId: number
}

interface KegDetailPropsAPI {
   arrival: string
   departure: string
   id: number
   location_id: number | null
   location_name: string | null
   location_category: string | null
   value: number
}

export interface KegDetailProps {
   arrival: DateTime
   departure: DateTime
   id: number
   location_id: number | null
   location_name: string | null
   location_category: POICategory | null
   value: number
   percDistance: number
   percTimeStart: number
   percTimeEnd: number
}

export interface POIProps {
   id: number
   name: string
   category: POICategory
}

export interface MapDetailResult {
   course: FeatureCollection<Point, KegDetailProps>
   pois: FeatureCollection<Point, POIProps>
}

export const EmptyMapDetailResult = {
   course: {
      type: 'FeatureCollection',
      features: new Array<Feature<Point, KegDetailProps>>(),
   },
   pois: {
      type: 'FeatureCollection',
      features: new Array<Feature<Point, POIProps>>(),
   },
} as MapDetailResult

export const KegMapDetailService = (
   filter: KegDetailFilter
): Promise<MapDetailResult> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/asset/' + filter.kegId + '/map_detail/'

   fillPeriodURL(api_url, filter)

   return json<FeatureCollection<Point, KegDetailPropsAPI>>(api_url.href, {
      credentials: 'include',
   }).then((f) => {
      if (f === undefined) {
         return EmptyMapDetailResult
      }
      const newF = f.features
         .map((v) => {
            return {
               id: v.id,
               type: v.type,
               geometry: v.geometry,
               bbox: v.bbox,
               properties: {
                  arrival: DateTime.fromISO(v.properties.arrival),
                  departure: DateTime.fromISO(v.properties.departure),
                  id: v.properties.id,
                  location_id: v.properties.location_id,
                  location_name: v.properties.location_name,
                  location_category:
                     v.properties.location_category === null
                        ? null
                        : getCategoryFromString(v.properties.location_category),
                  value: v.properties.value,
                  percDistance: 0,
                  percTimeStart: 0,
                  percTimeEnd: 0,
               },
            }
         })
         .reverse()

      const totalTime = newF[newF.length - 1].properties.departure
         .diff(newF[0].properties.arrival)
         .as('seconds')

      const totalDistance = newF.reduce((tot, cv, i, a) => {
         if (i === 0) {
            return tot
         }
         return (
            tot +
            distance(a[i - 1].geometry.coordinates, cv.geometry.coordinates)
         )
      }, 0.0)

      newF.reduce(
         (summary, v, i, a) => {
            //handle time
            if (i !== 0) {
               summary.time += v.properties.arrival
                  .diff(a[i - 1].properties.departure)
                  .as('seconds')
            }
            const duration = v.properties.departure
               .diff(v.properties.arrival)
               .as('seconds')

            const percTimeStart = summary.time / totalTime
            summary.time += duration
            const percTimeEnd = summary.time / totalTime

            // handle distance
            if (i !== 0) {
               summary.distance += distance(
                  v.geometry.coordinates,
                  a[i - 1].geometry.coordinates
               )
            }
            const percDistance = summary.distance / totalDistance
            v.properties.percTimeStart = percTimeStart
            v.properties.percTimeEnd = percTimeEnd
            v.properties.percDistance = percDistance

            return summary
         },
         { time: 0.0, distance: 0.0 }
      )

      const pois = newF.reduce((pois, f) => {
         if (f.properties.location_id !== null) {
            pois.set(f.properties.location_id, {
               name: f.properties.location_name,
               category: f.properties.location_category,
               geometry: f.geometry,
            })
         }

         return pois
      }, new Map())

      const poisFeatures = new Array<Feature<Point, POIProps>>()
      for (const [id, val] of pois) {
         poisFeatures.push({
            type: 'Feature',
            geometry: val.geometry,
            properties: {
               id: id,
               category: val.category,
               name: val.name,
            },
         })
      }

      return {
         course: {
            type: f.type,
            bbox: f.bbox,
            features: newF,
         },
         pois: {
            type: f.type,
            bbox: f.bbox,
            features: poisFeatures,
         },
      }
   })
}

export interface TemperatureListItem {
   timestamp: DateTime
   value: number
}

export interface TemperatureList {
   count: number
   temperatures: TemperatureListItem[]
}

export const KegTemperaturesService = (
   filter: KegDetailFilter
): Promise<TemperatureList> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/asset/' + filter.kegId + '/temperature/'

   fillPeriodURL(api_url, filter)

   return json(api_url.href, { credentials: 'include' }).then((res: any) => {
      return {
         count: res.count,
         temperatures: res.temperatures.map((item: any) => {
            let val = item.value
            if (val === undefined || val === null) {
               val = NaN
            }
            return { timestamp: DateTime.fromISO(item.ts), value: val }
         }),
      }
   })
}

export interface KegListItem {
   assetId: number
   reference: string
   locationId?: number
   locationName?: string
   locationCategory?: POICategory
   locationCoordinates?: number[]
   arrivalDate?: DateTime
   departureDate?: DateTime
   temperature?: number
   temperatureTimestamp?: string
   battery?: number
   batteryTimestamp?: string
   lastCommunication?: DateTime
   fillLevel?: FillLevel
   firstLocationTimestamp?: DateTime
}

export interface KegList {
   count: number
   kegList: KegListItem[]
}

export interface KegListFilters {
   minTemperature?: number
   maxTemperature?: number
   minBattery?: number
   maxBattery?: number
   lastCommunicationDaysAgo?: number
   kegName?: string
}

export type KegListField =
   | 'name'
   | 'last_communication'
   | 'battery'
   | 'temperature'

export interface KegOrder {
   field: KegListField
   direction: NonNullable<Order>
}

const buildKegListItemFromAPI = (item: any): KegListItem => {
   return {
      assetId: item.asset_id,
      reference: item.reference,
      locationId: item.location_id,
      locationName: item.location_name,
      locationCategory: item.location_category,
      locationCoordinates: [item.x, item.y],
      arrivalDate:
         item.arrival === undefined || item.arrival === null
            ? undefined
            : DateTime.fromISO(item.arrival),
      departureDate:
         item.departure === undefined || item.departure === null
            ? undefined
            : DateTime.fromISO(item.departure),
      temperature: item.temperature === null ? undefined : item.temperature,
      temperatureTimestamp: item.temperature_ts,
      battery: item.battery === null ? undefined : item.battery,
      batteryTimestamp: item.battery_ts,
      lastCommunication:
         item.last_communication === undefined ||
         item.last_communication === null
            ? undefined
            : DateTime.fromISO(item.last_communication),
      fillLevel: getFillLevelFromString(item.status),
      firstLocationTimestamp:
         item.first_location_ts === undefined || item.first_location_ts === null
            ? undefined
            : DateTime.fromISO(item.first_location_ts),
   }
}

export const KegListService = (
   listFilter: KegListFilters,
   pag: Pagination,
   order?: KegOrder
): Promise<KegList> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/asset/'

   addPaginationToURL(api_url, pag)

   if (listFilter.minTemperature !== undefined)
      api_url.searchParams.append(
         'min_temperature',
         listFilter.minTemperature.toString()
      )
   if (listFilter.maxTemperature !== undefined)
      api_url.searchParams.append(
         'max_temperature',
         listFilter.maxTemperature.toString()
      )
   if (listFilter.minBattery !== undefined)
      api_url.searchParams.append(
         'min_battery',
         listFilter.minBattery.toString()
      )
   if (listFilter.maxBattery !== undefined)
      api_url.searchParams.append(
         'max_battery',
         listFilter.maxBattery.toString()
      )
   if (listFilter.lastCommunicationDaysAgo !== undefined)
      api_url.searchParams.append(
         'communication_days_ago',
         listFilter.lastCommunicationDaysAgo.toString()
      )
   if (listFilter.kegName !== undefined)
      api_url.searchParams.append('asset_name', listFilter.kegName)

   if (order !== undefined) {
      switch (order.field) {
         case 'name':
            api_url.searchParams.append(
               'sort_reference',
               order.direction.toString()
            )
            break
         case 'last_communication':
            api_url.searchParams.append(
               'sort_last_communication',
               order.direction.toString()
            )
            break
         case 'temperature':
            api_url.searchParams.append(
               'sort_temperature',
               order.direction.toString()
            )
            break
         case 'battery':
            api_url.searchParams.append(
               'sort_battery',
               order.direction.toString()
            )
      }
   }

   return json(api_url.href, { credentials: 'include' }).then((res: any) => {
      return {
         count: res.count,
         kegList: res.asset_list.map(buildKegListItemFromAPI),
      }
   })
}

export interface KegLocationHistoryItem {
   arrivalDate: DateTime
   departureDate?: DateTime
   locationId?: number
   locationName?: string
   locationCategory?: POICategory
   locationCoordinates: number[]
   fillLevel?: FillLevel
}

export interface KegLocationHistoryList {
   count: number
   kegLocationList: KegLocationHistoryItem[]
}

export interface KegLocationHistoryFilter extends PeriodFilter {
   kegId: number
}

// TODO: implement filter by date
export const KegLocationHistoryListService = (
   filter: KegLocationHistoryFilter,
   pag: Pagination
): Promise<KegList> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/asset/' + filter.kegId + '/location/'

   fillPeriodURL(api_url, filter)

   addPaginationToURL(api_url, pag)

   return json(api_url.href, { credentials: 'include' }).then((res: any) => {
      return {
         count: res.count,
         kegList: res.asset_location_list.map((item: any) => {
            return {
               arrivalDate:
                  item.arrival === undefined || item.arrival === null
                     ? undefined
                     : DateTime.fromISO(item.arrival),
               departureDate:
                  item.departure === undefined || item.departure === null
                     ? undefined
                     : DateTime.fromISO(item.departure),
               locationId: item.location_id,
               locationName: item.location_name,
               locationCategory: item.location_category,
               locationCoordinates: [item.x, item.y],
               fillLevel: getFillLevelFromString(item.status),
            }
         }),
      }
   })
}

export const KegDetailService = (kegId: number): Promise<KegListItem> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/asset/' + kegId + '/'

   return json(api_url.href, { credentials: 'include' }).then((item) =>
      buildKegListItemFromAPI(item)
   )
}

export interface POILocation {
   id: number
   name: string
   category: POICategory
}

export interface POIHistoricalLocation {
   location: POILocation
   arrival: DateTime
   departure?: DateTime
}

export interface KegLatestData {
   id: number
   status?: string
   temperature: number
   battery: number
   location?: POILocation
   historicalLocation?: POIHistoricalLocation
   lastLocationTime: DateTime
   lastCommunication: DateTime
}

export const KegLatestDataService = (kegId: number): Promise<KegLatestData> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/asset/' + kegId + '/latest/'

   return json(api_url.href, { credentials: 'include' }).then((v: any) => {
      let hLocation: POIHistoricalLocation | undefined = undefined
      if (v.historical_location !== null) {
         hLocation = {
            location: v.historical_location.location,
            arrival: DateTime.fromISO(v.historical_location.arrival),
            departure:
               v.historical_location.departure !== null
                  ? DateTime.fromISO(v.historical_location.departure)
                  : undefined,
         }
      }
      return {
         id: kegId,
         status: v.status,
         temperature: v.temperature,
         battery: v.battery,
         location: v.location === null ? undefined : v.location,
         historicalLocation: hLocation,
         lastLocationTime: DateTime.fromISO(v.last_location_time),
         lastCommunication: DateTime.fromISO(v.last_communication),
      }
   })
}

export const KegUpdateService = (
   kegId: number,
   reference: string
): Promise<AxiosResponse<boolean>> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/asset/' + kegId + '/'

   return axios.post<boolean>(
      api_url.href,
      { reference: reference },
      { headers: { 'Content-Type': 'application/json' }, withCredentials: true }
   )
}

export interface KegRotationSummary {
   count: number
   durationHours: number
   latestRotationStart?: DateTime
}

export const KegRotationSummaryService = (
   kegId: number
): Promise<KegRotationSummary> => {
   const api_url = new URL(config.get('api_url'))
   api_url.pathname += '/v1/asset/' + kegId + '/rotation_summary/'

   return json(api_url.href, { credentials: 'include' }).then((v: any) => {
      const rs =
         v.latest_rotation_start !== null
            ? DateTime.fromISO(v.latest_rotation_start)
            : undefined
      return {
         count: v.count,
         durationHours: v.duration_hours,
         latestRotationStart: rs,
      }
   })
}

export default KegListMapService
