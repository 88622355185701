import React from 'react'
import { ShortTypeCard } from '../../components/cards'
import { MovementDirection, POICategory } from '../../../util/types/types'
import { MovementService } from '../../../data/service'
import { ShortTypeContent } from '../../components/cards/ShortTypeCard/ShortTypeContent'
import { DateTime } from 'luxon'

interface Props {
   category: POICategory
   endDate: DateTime
   period: number
}

const MovementShortTypeCard = ({
   category,
   endDate,
   period,
}: Props): JSX.Element => {
   const dataIn = MovementService({
      category: category,
      direction: MovementDirection.In,
      endDate: endDate,
      period: period,
   })
   const dataOut = MovementService({
      category: category,
      direction: MovementDirection.Out,
      endDate: endDate,
      period: period,
   })

   return (
      <ShortTypeCard title={category} type={category}>
         <ShortTypeContent
            type={category}
            data={dataIn}
            endDate={endDate}
            period={period}
            unit={'Inbound'}
            height={20}
            hasDetail={false}
         />
         <ShortTypeContent
            type={category}
            data={dataOut}
            endDate={endDate}
            period={period}
            unit={'Outbound'}
            height={20}
            hasDetail={false}
         />
      </ShortTypeCard>
   )
}

export default MovementShortTypeCard
