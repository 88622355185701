import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { MovementDirection, POICategory } from '../../util/types/types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import { DatePicker } from '@material-ui/pickers/DatePicker'
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { MovementTypeCard } from './MovementTypeCard'
import { MovementMap } from './MovementMap'
import { DateTime } from 'luxon'
import LuxonUtils from '@date-io/luxon'

const useStyles = makeStyles((theme) => ({
   root: {
      padding: theme.spacing(4),
      //background: '#F4F6F8',
   },
   header: {
      textAlign: 'center',
   },
}))

const Logistics = (): JSX.Element => {
   const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now())
   const [period, setPeriod] = useState<number>(7)

   const classes = useStyles()

   const header = (
      <>
         <Grid item lg={3}>
            <Typography
               gutterBottom
               variant="h5"
               component="h5"
               className={classes.header}
            >
               Brewery
            </Typography>
         </Grid>
         <Grid item lg={3}>
            <Typography
               gutterBottom
               variant="h5"
               component="h5"
               className={classes.header}
            >
               DC
            </Typography>
         </Grid>
         <Grid item lg={3}>
            <Typography
               gutterBottom
               variant="h5"
               component="h5"
               className={classes.header}
            >
               Wholesaler
            </Typography>
         </Grid>
         <Grid item lg={3}>
            <Typography
               gutterBottom
               variant="h5"
               component="h5"
               className={classes.header}
            >
               POC
            </Typography>
         </Grid>
      </>
   )

   const rows = []

   for (const dir in MovementDirection) {
      //@ts-ignore
      const dirType = MovementDirection[dir]

      for (const pcat in POICategory) {
         //@ts-ignore
         const catName = POICategory[pcat]

         rows.push(
            <React.Fragment key={dir + catName}>
               <Grid item lg={3}>
                  <MovementTypeCard
                     category={catName}
                     direction={dirType}
                     endDate={selectedDate}
                     period={period}
                  />
               </Grid>
            </React.Fragment>
         )
      }
   }

   const handleDateChange = (date: MaterialUiPickersDate) => {
      if (date !== null) {
         setSelectedDate(date)
      }
   }

   const handlePeriodChange = (d: any) => {
      setPeriod(d.target.value)
   }

   return (
      <Card className={classes.root}>
         <Typography gutterBottom variant="h3" component="h3">
            Movements during a period of&nbsp;
            <Select
               id="demo-simple-select"
               value={period}
               onChange={handlePeriodChange}
            >
               <MenuItem value={7}>7 days</MenuItem>
               <MenuItem value={14}>14 days</MenuItem>
               <MenuItem value={30}>1 month</MenuItem>
               <MenuItem value={60}>2 months</MenuItem>
               <MenuItem value={90}>3 months</MenuItem>
            </Select>
            &nbsp;ending in&nbsp;
            <MuiPickersUtilsProvider utils={LuxonUtils}>
               <DatePicker
                  value={selectedDate}
                  onChange={handleDateChange}
                  format={'D'}
                  allowKeyboardControl={false}
                  maxDate={DateTime.now()}
               />
            </MuiPickersUtilsProvider>
         </Typography>
         <CardContent>
            <Grid container>
               {header}

               {rows}
            </Grid>
         </CardContent>
         <Typography gutterBottom variant="h2" component="h2">
            Movements by location
         </Typography>
         <MovementMap period={period} endDate={selectedDate} />
      </Card>
   )
}

export default Logistics
