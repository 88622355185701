import React, { useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import {
   PasswordChangeData,
   UserData,
   UserDataUpdateService,
   UserDetailService,
   UserEditData,
   UserLoggedinService,
   UserPasswordUpdateService,
} from '../../data/service/user/UserService'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { AuthFuncContext } from '../../App'

const editUserSchema = Yup.object().shape({
   email: Yup.string().email().required(),
   name: Yup.string().min(2).max(64).required(),
})

const changePasswordSchema = Yup.object().shape({
   current_password: Yup.string().min(5).max(128).required(),
   new_password: Yup.string().min(5).max(128).required(),
})

function Alert(props: AlertProps) {
   return <MuiAlert elevation={6} variant="filled" {...props} />
}

interface Props {
   userId?: number
   backFunc?: () => void
}

export const UserEdit = (props: Props): JSX.Element => {
   const [userData, setUserData] = useState<UserData>()
   const [open, setOpen] = useState<boolean>(false)
   const [alertMessage, setAlertMessage] = useState<string | null>(null)

   const authFuncContext = useContext(AuthFuncContext)

   const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
         return
      }

      setOpen(false)
   }

   useEffect(() => {
      if (props.userId === undefined) {
         UserLoggedinService().then((d) => setUserData(d))
         return
      }

      UserDetailService(props.userId).then((d) => setUserData(d))
   }, [props])

   return (
      <>
         <Box p={1}>
            <Grid
               container
               justify="flex-start"
               spacing={1}
               alignItems="center"
            >
               {props.userId !== undefined && props.backFunc !== undefined && (
                  <Grid item>
                     <IconButton onClick={props.backFunc}>
                        {' '}
                        <ArrowBackIcon />
                     </IconButton>
                  </Grid>
               )}
               <Grid item>
                  <Typography variant="h2">Edit User</Typography>
               </Grid>
            </Grid>
         </Box>
         <Divider />
         <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert onClose={handleClose} severity="success">
               {alertMessage}
            </Alert>
         </Snackbar>
         <Box p={2}>
            <Grid container justify="space-around">
               <Grid item xs={5}>
                  <Card>
                     <CardHeader title="User data" />
                     <CardContent>
                        {userData !== undefined && (
                           <Formik
                              initialValues={{
                                 id: userData.id,
                                 email: userData.email,
                                 name: userData.name,
                                 admin: userData.admin,
                                 timezone: userData.timezone,
                              }}
                              validationSchema={editUserSchema}
                              onSubmit={(
                                 values: UserEditData,
                                 { setSubmitting }
                              ) => {
                                 UserDataUpdateService(values).then(() => {
                                    setSubmitting(false)
                                    setAlertMessage('User data updated')
                                    setOpen(true)
                                    authFuncContext.prefChangeFunc({
                                       timeZone: values.timezone,
                                    })
                                 })
                              }}
                           >
                              {({ submitForm, isSubmitting }) => (
                                 <Form>
                                    <Grid
                                       container
                                       justify="space-around"
                                       direction="column"
                                       spacing={2}
                                    >
                                       <Grid item>
                                          <Field
                                             component={TextField}
                                             name="email"
                                             type="email"
                                             label="Email"
                                             required={true}
                                             variant="outlined"
                                          />
                                       </Grid>
                                       <Grid item>
                                          <Field
                                             component={Select}
                                             type="text"
                                             name="timezone"
                                             label="Timezone"
                                             required={true}
                                             onChange={(event: any) => {
                                                const u = { ...userData }
                                                u.timezone = event.target.value
                                                setUserData(u)
                                             }}
                                             value={userData.timezone}
                                          >
                                             <MenuItem value="default">
                                                Computer default (
                                                {
                                                   Intl.DateTimeFormat().resolvedOptions()
                                                      .timeZone
                                                }
                                                )
                                             </MenuItem>
                                             <MenuItem value="America/New_York">
                                                America/New_York
                                             </MenuItem>
                                          </Field>
                                       </Grid>
                                       {props.userId !== undefined && (
                                          <Grid item>
                                             <Field
                                                component={CheckboxWithLabel}
                                                name="admin"
                                                type="checkbox"
                                                Label={{ label: 'Admin' }}
                                                required={true}
                                             />
                                          </Grid>
                                       )}
                                       <Grid item>
                                          {isSubmitting && <LinearProgress />}
                                          <br />
                                          <Button
                                             variant="contained"
                                             color="primary"
                                             disabled={isSubmitting}
                                             onClick={submitForm}
                                          >
                                             Submit
                                          </Button>
                                       </Grid>
                                    </Grid>
                                 </Form>
                              )}
                           </Formik>
                        )}
                     </CardContent>
                  </Card>
               </Grid>
               <Grid item xs={5}>
                  <Card>
                     <CardHeader title="Password" />
                     <CardContent>
                        {userData !== undefined && (
                           <Formik
                              initialValues={{
                                 id: userData.id,
                                 current_password: '',
                                 new_password: '',
                              }}
                              validationSchema={changePasswordSchema}
                              onSubmit={(
                                 values: PasswordChangeData,
                                 { setSubmitting }
                              ) => {
                                 UserPasswordUpdateService(values).then(() => {
                                    setSubmitting(false)
                                    setAlertMessage(
                                       'Password changed successfully'
                                    )
                                    setOpen(true)
                                 })
                              }}
                           >
                              {({ submitForm, isSubmitting }) => (
                                 <Form>
                                    <Grid
                                       container
                                       justify="space-around"
                                       direction="column"
                                       spacing={2}
                                    >
                                       <Grid item>
                                          <Field
                                             component={TextField}
                                             type="password"
                                             label={
                                                props.userId === undefined
                                                   ? 'Current password'
                                                   : 'Your password'
                                             }
                                             name="current_password"
                                             required={true}
                                             variant="outlined"
                                          />
                                       </Grid>
                                       <Grid item>
                                          <Field
                                             component={TextField}
                                             type="password"
                                             label={
                                                props.userId === undefined
                                                   ? 'New Password'
                                                   : "User's new password"
                                             }
                                             name="new_password"
                                             required={true}
                                             variant="outlined"
                                          />
                                       </Grid>
                                       <Grid item>
                                          {isSubmitting && <LinearProgress />}
                                          <br />
                                          <Button
                                             variant="contained"
                                             color="primary"
                                             disabled={isSubmitting}
                                             onClick={submitForm}
                                          >
                                             Submit
                                          </Button>
                                       </Grid>
                                    </Grid>
                                 </Form>
                              )}
                           </Formik>
                        )}
                     </CardContent>
                  </Card>
               </Grid>
            </Grid>
         </Box>
      </>
   )
}
