import React, { useEffect, useState } from 'react'
import { OnalyticsMapD3 } from '../../components/charts/map/OnalyticsMapD3'
import KegListMapService from '../../../data/service/kegs'

const KegListMap = (): JSX.Element => {
   const [data, setData] = useState<Promise<unknown>>()

   useEffect(() => {
      setData(() => KegListMapService())
   }, [])

   /*
  const dialogGenerator = (poi: PoiSummary, handleClose: () => void) => {
    return <KegDialog poi={poi} onClose={handleClose}/>
  };
  */

   return <>{data !== undefined && <OnalyticsMapD3 data={data} />}</>
}

export default KegListMap
