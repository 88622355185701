import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/styles/makeStyles'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: 'fit-content',
   },
   avatar: {
      width: 60,
      height: 60,
   },
   name: {
      //@ts-ignore
      marginTop: theme.spacing(1),
   },
}))

const Profile = (props: { [x: string]: any }): JSX.Element => {
   const { className, ...rest } = props

   const classes = useStyles()

   const user = {
      name: 'John Doe',
      avatar: process.env.PUBLIC_URL + '/assets/avatars/avatar_1.jpg',
      bio: 'Strategy manager',
   }

   return (
      <div {...rest} className={clsx(classes.root, className)}>
         <Avatar
            alt="Person"
            className={classes.avatar}
            component={RouterLink}
            src={user.avatar}
            to="/settings"
         />
         <Typography className={classes.name} variant="h4">
            {user.name}
         </Typography>
         <Typography variant="body2">{user.bio}</Typography>
      </div>
   )
}

Profile.propTypes = {
   className: PropTypes.string,
}

export default Profile
