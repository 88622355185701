import { POICategory } from '../../util/types/types'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { getPlaceColor } from '../../util/Looks/colors'
import Tooltip from '@material-ui/core/Tooltip'
import { Theme } from '@material-ui/core/styles/createTheme'

export interface LocationLinkProps {
   id: number
   name: string
   category: POICategory
}

interface styleProps {
   color: string
}

const useStyles = makeStyles<Theme, styleProps>({
   link: (props) => ({
      color: props.color,
   }),
})

export const LocationLink = (props: LocationLinkProps): JSX.Element => {
   const getColor = (cat: POICategory) => {
      switch (cat) {
         case POICategory.Candidate:
            return getPlaceColor(POICategory.Candidate, 1, 0.5)
         default:
            return getPlaceColor(cat)
      }
   }
   const classes = useStyles({ color: getColor(props.category) })

   return (
      <Tooltip title={props.category}>
         <Link
            component={RouterLink}
            to={'/poi/' + props.id}
            className={classes.link}
         >
            {props.name}
         </Link>
      </Tooltip>
   )
}
