import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/styles/makeStyles'
import useTheme from '@material-ui/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Footer, Sidebar, Topbar } from './components'

const useStyles = makeStyles((theme) => ({
   root: {
      paddingTop: 56,
      height: '100%',
      //@ts-ignore
      [theme.breakpoints.up('sm')]: {
         paddingTop: 64,
      },
   },
   shiftContent: {
      paddingLeft: 200,
   },
   content: {
      height: '100%',
   },
}))

const Main = (props: { children: any }): JSX.Element => {
   const { children } = props

   const classes = useStyles()
   const theme = useTheme()
   //@ts-ignore
   const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
      defaultMatches: true,
   })

   const [openSidebar, setOpenSidebar] = useState(false)

   const handleSidebarOpen = () => {
      setOpenSidebar(true)
   }

   const handleSidebarClose = () => {
      setOpenSidebar(false)
   }

   const shouldOpenSidebar = isDesktop ? true : openSidebar

   return (
      <div
         className={clsx({
            [classes.root]: true,
            [classes.shiftContent]: isDesktop,
         })}
      >
         <Topbar onSidebarOpen={handleSidebarOpen} className={''} />
         <Sidebar
            onClose={handleSidebarClose}
            open={shouldOpenSidebar}
            variant={isDesktop ? 'persistent' : 'temporary'}
            className={''}
         />

         <main className={classes.content}>
            {children}
            <Footer className={classes.content} />
         </main>
      </div>
   )
}

Main.propTypes = {
   children: PropTypes.node,
}

export default Main
