import React from 'react'
import Grid from '@material-ui/core/Grid'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { Link as LinkRouter, useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import {
   UserCreateData,
   UserCreateService,
} from '../../data/service/user/UserService'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import * as Yup from 'yup'

const createUserSchema = Yup.object().shape({
   email: Yup.string().email().required(),
   name: Yup.string().min(2).max(64).required(),
   password: Yup.string().min(5).max(128).required(),
})

export const UserCreate = (): JSX.Element => {
   const history = useHistory()
   return (
      <>
         <Box p={1}>
            <Grid
               container
               justify="flex-start"
               spacing={1}
               alignItems="center"
            >
               <Grid item>
                  <IconButton component={LinkRouter} to="/users">
                     {' '}
                     <ArrowBackIcon />
                  </IconButton>
               </Grid>
               <Grid item>
                  <Typography variant="h2">Add User</Typography>
               </Grid>
            </Grid>
         </Box>
         <Divider />
         <Box p={2}>
            <Formik
               initialValues={{
                  email: '',
                  password: '',
                  name: '',
                  admin: false,
               }}
               validationSchema={createUserSchema}
               onSubmit={(values: UserCreateData, { setSubmitting }) => {
                  UserCreateService(values)
                     .then(() => {
                        setSubmitting(false)
                        history.push('/users')
                     })
                     .catch((reason) => {
                        // TODO bug #74: show error
                        setSubmitting(false)
                     })
               }}
            >
               {({ submitForm, isSubmitting }) => (
                  <Form autoComplete="off">
                     <Grid
                        container
                        justify="space-around"
                        direction="column"
                        spacing={2}
                     >
                        <Grid item>
                           <Field
                              component={TextField}
                              name="email"
                              type="email"
                              label="Email"
                              required={true}
                              variant="outlined"
                           />
                        </Grid>
                        <Grid item>
                           <Field
                              component={TextField}
                              name="name"
                              type="name"
                              label="Name"
                              required={true}
                              variant="outlined"
                           />
                        </Grid>
                        <Grid item>
                           <Field
                              component={CheckboxWithLabel}
                              name="admin"
                              type="admin"
                              Label={{ label: 'Admin' }}
                              required={true}
                           />
                        </Grid>
                        <Grid item>
                           <Field
                              component={TextField}
                              type="password"
                              label="Password"
                              name="password"
                              required={true}
                              variant="outlined"
                              autoComplete="new-password"
                           />
                        </Grid>
                        <Grid item>
                           {isSubmitting && <LinearProgress />}
                           <br />
                           <Button
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting}
                              onClick={submitForm}
                           >
                              Submit
                           </Button>
                        </Grid>
                     </Grid>
                  </Form>
               )}
            </Formik>
         </Box>
      </>
   )
}
