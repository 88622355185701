import Typography from '@material-ui/core/Typography'
import { DatePicker } from '@material-ui/pickers/DatePicker'
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider'
import LuxonUtils from '@date-io/luxon'
import { DateTime } from 'luxon'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

export type DateInterval = {
   minDate: DateTime
   maxDate: DateTime
}

export type DatePeriod = {
   endDate: DateTime
   duration: number
}

export function getPeriodFromInterval(interval: DateInterval): DatePeriod {
   return {
      endDate: interval.maxDate,
      duration: interval.maxDate.diff(interval.minDate, 'days').as('days') ?? 0,
   }
}

export function getIntervalFromPeriod(period: DatePeriod): DateInterval {
   return {
      minDate: period.endDate.plus({ days: -1 * period.duration }),
      maxDate: period.endDate,
   }
}

export interface DateIntervalFilterProps {
   validInterval: DateInterval
   interval: DateInterval
   intervalChangeHandler: (i: DateInterval) => void
   preText: string
   middleText: string
}

export const DateIntervalFilter = (
   props: DateIntervalFilterProps
): JSX.Element => {
   const fixInterval = (
      interval: DateInterval,
      validInterval: DateInterval
   ): [DateInterval, boolean] => {
      let changed = false
      if (interval.minDate < validInterval.minDate) {
         changed = true
         interval.minDate = validInterval.minDate
      }
      if (interval.maxDate > validInterval.maxDate) {
         changed = true
         interval.maxDate = validInterval.maxDate
      }
      return [interval, changed]
   }

   const [myInterval, changed] = fixInterval(
      props.interval,
      props.validInterval
   )

   if (changed) {
      props.intervalChangeHandler(myInterval)
   }

   const handleStartDateChange = (date: MaterialUiPickersDate) => {
      if (date === null) return

      props.intervalChangeHandler({
         minDate: date,
         maxDate: props.interval.maxDate,
      })
   }

   const handleEndDateChange = (date: MaterialUiPickersDate) => {
      if (date === null) return

      props.intervalChangeHandler({
         minDate: props.interval.minDate,
         maxDate: date,
      })
   }

   return (
      <Typography gutterBottom variant="h4" component="h4">
         {props.preText} &nbsp;
         <MuiPickersUtilsProvider utils={LuxonUtils}>
            <DatePicker
               value={props.interval.minDate}
               onChange={handleStartDateChange}
               format={'D'}
               allowKeyboardControl={false}
               minDate={props.validInterval.minDate}
               maxDate={props.interval.maxDate}
            />
         </MuiPickersUtilsProvider>{' '}
         &nbsp; {props.middleText} &nbsp;
         <MuiPickersUtilsProvider utils={LuxonUtils}>
            <DatePicker
               value={props.interval.maxDate}
               onChange={handleEndDateChange}
               format={'D'}
               allowKeyboardControl={false}
               minDate={props.interval.minDate}
               maxDate={props.validInterval.maxDate}
            />
         </MuiPickersUtilsProvider>
      </Typography>
   )
}
