import React, { useState } from 'react'
import { getPOICategoryName, POICategory } from '../../../util/types/types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

export interface POIEditData {
   name: string
   category: POICategory
}

interface Props {
   open: boolean
   poiData: POIEditData
   okHandler: (newValues: POIEditData) => boolean
   cancelHandler: () => void
}

export const POIEdit = ({
   open,
   poiData,
   okHandler,
   cancelHandler,
}: Props): JSX.Element => {
   const [curPOI, setCurPOI] = useState<POIEditData>(poiData)

   const handleCategoryChange = (e: React.ChangeEvent<{ value: unknown }>) => {
      setCurPOI({
         name: curPOI.name,
         category: e.target.value as POICategory,
      })
   }

   const handleNameChange = (e: React.ChangeEvent<{ value: unknown }>) => {
      setCurPOI({ name: e.target.value as string, category: curPOI.category })
   }

   const handleOk = () => {
      okHandler(curPOI)
   }

   return (
      <Dialog open={open}>
         <DialogTitle>Edit POI: {curPOI.name}</DialogTitle>
         <DialogContent>
            <Grid
               container
               justify="space-around"
               direction="column"
               spacing={2}
            >
               <Grid item>
                  <FormControl variant="outlined">
                     <TextField
                        label="name"
                        variant="outlined"
                        fullWidth
                        value={curPOI.name}
                        onChange={handleNameChange}
                     />
                  </FormControl>
               </Grid>
               <Grid item>
                  <FormControl variant="outlined">
                     <InputLabel id="label-for-category">Category</InputLabel>
                     <Select
                        labelId="label-for-category"
                        value={curPOI.category}
                        label="Category"
                        onChange={handleCategoryChange}
                        fullWidth
                     >
                        {Object.values(POICategory).map((c) => {
                           const cat = getPOICategoryName(c as POICategory)
                           return <MenuItem value={c}>{cat}</MenuItem>
                        })}
                     </Select>
                  </FormControl>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button onClick={cancelHandler} color="primary">
               Cancel
            </Button>
            <Button onClick={handleOk} color="primary">
               Ok
            </Button>
         </DialogActions>
      </Dialog>
   )
}
