import React, { useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import TableHead from '@material-ui/core/TableHead'
import Grid from '@material-ui/core/Grid'

import { Link as RouterLink, useHistory } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import {
   UserListData,
   UserListItem,
   UserListService,
} from '../../data/service/user/UserService'
import { TablePaginationActions } from '../components/table/Pagination'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import {
   Page,
   PageContent,
   Section,
   SectionContent,
   SectionTitle,
} from '../../components'
import { TextSearch } from '../../components/Filter/TextSearch'

const useStyles2 = makeStyles({
   table: {
      minWidth: 500,
   },
   aligned_link: {
      display: 'flex',
      alignItems: 'center',
   },
})

export const UserList = (): JSX.Element => {
   const classes = useStyles2()
   const [page, setPage] = React.useState(0)
   const [rowsPerPage, setRowsPerPage] = React.useState(10)
   const [rows, setRows] = React.useState([] as UserListItem[])
   const [count, setCount] = React.useState(0)
   const [searchText, setSearchText] = React.useState('')

   const history = useHistory()

   useEffect(() => {
      let cat
      const userServiceParams = {
         page: page,
         size: rowsPerPage,
         categories: cat,
         nameFilter: searchText,
      }
      UserListService(userServiceParams).then(
         (res: UserListData | undefined) => {
            if (res === undefined) return
            setCount(res.count)
            setRows(res.users)
         }
      )
   }, [rowsPerPage, page, searchText])

   const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
   ) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   ) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
   }

   const handleAddUser = () => {
      history.push('/user/new')
   }

   return (
      <Page>
         <PageContent>
            <Section paperless>
               <SectionTitle>User list</SectionTitle>
               <SectionContent>
                  <Grid container justify="space-between" alignItems="center">
                     <Grid item>
                        <TextSearch
                           placeholder={'Search a User'}
                           text={searchText}
                           changeHandler={setSearchText}
                        />
                     </Grid>
                     <Grid item>
                        <Button
                           color="primary"
                           variant="contained"
                           startIcon={<AddIcon />}
                           onClick={handleAddUser}
                        >
                           Add User
                        </Button>
                     </Grid>
                  </Grid>
               </SectionContent>
            </Section>
            <Section paperless>
               <SectionContent>
                  <TableContainer component={Paper}>
                     <Table
                        className={classes.table}
                        aria-label="custom pagination table"
                     >
                        <TableHead>
                           <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell align="right" />
                              <TableCell align="right">Email</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rows.map((row) => (
                              <TableRow key={row.id}>
                                 <TableCell component="th" scope="row">
                                    <Link
                                       component={RouterLink}
                                       to={'/user/' + row.id}
                                       className={classes.aligned_link}
                                    >
                                       {row.admin && (
                                          <SupervisorAccountIcon fontSize="default" />
                                       )}
                                       {row.name}
                                    </Link>
                                 </TableCell>
                                 <TableCell align="right"></TableCell>
                                 <TableCell align="right">
                                    {row.email}
                                 </TableCell>
                              </TableRow>
                           ))}
                           {/*emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )*/}
                        </TableBody>
                        <TableFooter>
                           <TableRow>
                              <TablePagination
                                 rowsPerPageOptions={[10, 50, 100]}
                                 colSpan={3}
                                 rowsPerPage={rowsPerPage}
                                 count={count}
                                 page={page}
                                 SelectProps={{
                                    inputProps: {
                                       'aria-label': 'rows per page',
                                    },
                                    native: true,
                                 }}
                                 onPageChange={handleChangePage}
                                 onChangeRowsPerPage={handleChangeRowsPerPage}
                                 ActionsComponent={TablePaginationActions}
                              />
                           </TableRow>
                        </TableFooter>
                     </Table>
                  </TableContainer>
               </SectionContent>
            </Section>
         </PageContent>
      </Page>
   )
}
